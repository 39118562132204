<div class="account-page">

  <div class="page-header justify-content-between">
    <h2>{{'my_account_title'|translate}}</h2>
    <button class="btn btn-text">{{'my_account_deactivate'|translate}}</button>
  </div>

  <div class="row">
    <div class="col-lg-12 user-details settings-big-card">
      <p-card class="card members settings-card-height">
        <div class="card-body card-user-details" *ngIf="showUserDetails">
          <form [formGroup]="userDetailsForm" (ngSubmit)="updateUserAccount()" autocomplete="off"
                id="userDetailsForm">
            <div class="row account-details">
              <div class="col-md-8">
                <div class="row">
                  <div class="user-details form-group col-xl-6 col-lg-6 col-md-12">
                    <label class="form-label">{{'register_firstname'|translate}}</label>
                    <input type="text" formControlName="firstname" class="form-control p-inputtext" pInputText
                           id="accountFirstName" placeholder="{{'register_firstname'|translate}}"/>
                  </div>
                  <div class="user-details form-group col-xl-6 col-lg-6 col-md-12">
                    <label class="form-label">{{'register_lastname'|translate}}</label>
                    <input type="text" formControlName="lastname" class="form-control p-inputtext" pInputText
                           id="accountLastName" placeholder="{{'register_lastname'|translate}}"/>
                  </div>
                  <div class="user-details form-group col-xl-6 col-lg-6 col-md-12">
                    <label class="form-label">{{'register_email'|translate}}</label>
                    <input type="email" [disabled]="true" formControlName="email" class="form-control "
                           placeholder="{{'register_email'|translate}}"/>
                  </div>
                  <div class="user-details form-group col-xl-6 col-lg-6 col-md-12">

                    <div class="vacation-mode">
                      <div style="padding: .6rem 0; width: -webkit-fill-available " class="user-details form-group">
                        <label class="form-label" style="margin:0!important">{{'my_account_change_language'|translate}}</label> <br>
                        <div style="display: flex; padding: 1rem;border-radius: 7px; border: 1px #E8E8E8 solid; background-color: #f0f3fa">
                          <ngx-flag-picker
                                  class="country"
                                  style="font-size: 2rem"
                                  [showFlags]=true
                                  [showLabels]=false
                                  [showArrow]=true
                                  [selectedCountryCode]="selectedCountryCode"
                                  [countryCodes]="countryCodes"
                                  (changedCountryCode)="changeLanguage($event, true)">
                          </ngx-flag-picker>
                          <p style="font-size: 1.6rem; padding: .2rem 1rem; margin: 0">{{languageDisplayed}}</p>
                        </div>
                      </div>
<!--                      <button class="btn green-btn"-->
<!--                              *ngIf="!savedSuccess.accountSavedSuccess && showUserDetails"-->
<!--                              (click)="updateUserAccount()">-->
<!--                      </button>-->
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="user-details form-group col-xl-6 col-lg-6 col-md-12">
                    <label class="form-label">{{'my_account_display_name'| translate}}</label>
                    <input type="text" formControlName="displayName" class="form-control p-inputtext" pInputText
                           id="accountDisplayName" placeholder="{{'my_account_display_name'| translate}}"/>
                  </div>
                  <div class="user-details form-group col-xl-6 col-lg-6 col-md-12">
                    <label class="form-label">{{'billing_tab2_phone'| translate}}</label>
                    <ngx-intl-tel-input pInputText
                      [ngStyle]="!phoneError ? {'border':'1px solid red'}:{}"
                      class="form-control ngx-tel-input"
                      style="border: 1px solid #E8E8E8; outline: none; padding-top: 0;"
                      placeholder="000000000"
                      [preferredCountries]="[CountryISO.Denmark]"
                      [selectedCountryISO]="CountryISO.Denmark"
                      [enableAutoCountrySelect]="true"
                      [enablePlaceholder]="true"
                      [searchCountryFlag]="true"
                      [searchCountryField]="[
                       SearchCountryField.Iso2,
                       SearchCountryField.Name
                       ]"
                      [selectFirstCountry]="false"
                      [maxLength]="15"
                      [phoneValidation]="true"
                      [numberFormat]="PhoneNumberFormat.International"
                      [separateDialCode]="true"
                      formControlName="phone"
                    ></ngx-intl-tel-input>
                    <div
                      *ngIf="!phoneError"
                      class="text-danger">
                      <div
                        *ngIf="!phoneError">
                        {{'register_phone_error'| translate}}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="user-details form-group col-xl-6 col-lg-6 col-md-12">
                    <label class="form-label">{{('member_info_start_in_workspace' | translate:{workspace:workspaceName})}}</label>
                    <div class="start-date-input-container" (click)="showStartDate($event)">
                      <span *ngIf="!sumbitedDate">{{'member_info_select_date' | translate}}</span>
                      <span *ngIf="sumbitedDate">{{this.userDetailsForm.value.userStartDate !== '' ? (this.userDetailsForm.value.userStartDate | date:'dd-MM-yyyy') : 
                        userConsultant ? ('member_info_consultant' | translate) : 
                        dontRemember ? ('member_info_dont_remember' | translate):''}}</span>
                      <img class="calendar-icon" src="../../../../../assets/svg/m_info_icon_calendar.svg">
                      <div class="calendar-container" [ngStyle]="showStartDateContainer ? {'display':'flex'}:{}">
                        <p-calendar formControlName="userStartDate" [inline]="true"></p-calendar>
                        <div class="calendar-input">
                          <input formControlName="userConsultant" 
                            [(ngModel)]="userConsultant"
                            (ngModelChange)="checkOption($event, 'consultant')" 
                            class="calendar-check" type="checkbox">
                          <span>{{'member_info_consultant' | translate}}</span>
                        </div>
                        <div class="calendar-input">
                          <input formControlName="dontRemember"
                          [(ngModel)]="dontRemember"
                          (ngModelChange)="checkOption($event, 'dontRemember')" 
                           class="calendar-check" type="checkbox">
                          <span>{{'member_info_dont_remember' | translate}}</span>
                        </div>
                        <div class="calendar-footer-container">
                          <span (click)="clearDateOptions()">Clear</span>
                          <span (click)="cancelDateOptions()" style="margin-left:auto">Cancel</span>
                          <span (click)="submitDateChanges()">Ok</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="user-details form-group nationality-container col-xl-6 col-lg-6 col-md-12">
                    <label class="form-label">{{'member_info_nationality' | translate}}</label>
                    <p-dropdown class="nationality-dropdown" 
                      [placeholder]="userDetailsForm.value.selectedNationality ? userDetailsForm.value.selectedNationality.title : ('member_info_nationality' | translate)" 
                      [options]="userNationalities"
                      formControlName="selectedNationality" 
                      optionLabel="title"
                      dropdownIcon="bi bi-caret-down-fill">
                    </p-dropdown>
                  </div>
                </div>
                <div class="row">
                  <div class="user-details form-group col-xl-6 col-lg-6 col-md-12">
                    <label class="form-label">{{'login_password'| translate}}</label>
                    <input type="password" formControlName="password" class="form-control p-inputtext" pInputText
                           name="password"
                           id="accountPassword" matTooltip="{{'reset_pass_sub_title_2'|translate}}"
                           placeholder="{{'new_password'| translate}}"
                           autocomplete="new-password"/>
                  </div>
                  <div class="user-details form-group col-xl-6 col-lg-6 col-md-12">
                    <label class="form-label">{{'register_confirm_pass'| translate}}</label>
                    <input type="password" formControlName="confirmPassword" class="form-control p-inputtext"
                           pInputText
                           id="accountConfirmPassword" placeholder="{{'my_account_confirm_password' | translate}}"/>
                  </div>
                </div>

              </div>
              <div class="col-md-4" style="text-align: center">
                <div class="avatar">
                  <input type="file" hidden
                         #file
                         name="file"
                         id="file"
                         (change)="uploadImage($event)"
                         accept="image/jpg,.jpeg,.png,.jpg"/>
                  <img class="user-picture-settings"
                       *ngIf="userDetails && (userDetails.avatar || userDetails.detail.displayName)"
                       src="{{userDetails.avatar ? baseUrl + '/uploads/user/'+ userDetails.uuid + '/' + userDetails.avatar.name : '../../../assets/svg/user-icon.svg' }}"
                       data-holder-rendered="true" alt="">

                  <a class="register" style="cursor: pointer" (click)="file.click();">
                    {{'my_account_change'|translate}}
                  </a>
                </div>
                <input type="checkbox" [checked]="userDetails.notificationSetting.emailNotification !== 'yes'"
                           id="switch"/>
                  <label (click)="userVacationMode()" tooltipPosition="bottom" class="language-flag-picker" id="switch-label"
                             pTooltip="{{'my_account_vacation_hover'| translate}}"></label>
                      <p>{{'my_account_vacation'| translate}}</p>
                <button pButton label="{{'my_account_button' | translate}}" class="save-account-details-button" style="width: inherit; margin-top: 4rem"
                [disabled]="!userDetailsChanged"></button>
              </div>
            </div>
          </form>
        </div>
      </p-card>
    </div>
  </div>

  <div class="row">
    <div class="col-xl-12 col-lg-12 settings-big-card">
      <p-card class="card members settings-card-height">
        <div>
          <h2>{{'my_account_my_teams'| translate}}
            <span *ngIf="showMyTeams" class="team-name-span">
              <i class='bi bi-person-fill nav__icon'></i>
            ({{workspaceTeams.length}})
          </span>
          </h2>
        </div>

        <div class="teams-list">
          <div class="card-body secondary-header">
            <h6>{{'my_account_my_teams_name'| translate}}</h6>
            <h6>{{'my_account_my_teams_role'| translate}}</h6>
          </div>
          <div class="card-body">
            <div class="members-block" [ngClass]="(index % 2) ?'team-list' :''"
                 *ngFor="let team of workspaceTeams; let index = index">
              <div class="user">
                <p routerLink="/team/{{team.team.uuid}}">{{team.team.name}}</p>
              </div>
              <div class="role-label">
                <p *ngIf="team.label !== null">{{team.label.name}}</p>
                <p>{{team.team.role}}</p>
              </div>
            </div>
          </div>
        </div>
      </p-card>
    </div>
  </div>
</div>
