import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { from, map } from 'rxjs';
import { ApiwrapperService } from 'src/app/services/apiwrapper.service';
import { DataManipulationService } from 'src/app/services/data-manipulation.service';

@Component({
  selector: 'app-reporting-container',
  templateUrl: './reporting-container.component.html',
  styleUrls: ['./reporting-container.component.scss']
})
export class ReportingContainerComponent implements OnInit {

  gotCategories: boolean = false;
  gotOnboardingReporting: boolean = false;
  isReporting: boolean = false;
  isActionPlan: boolean = false;
  actionPlanCompleted: boolean = false;
  isNonMember: boolean = false;
  
  reportingCategories: any[] = [];
  reportingCategoriesStatements: any[] = [];
  categoryActions: any[] = [];
  actionPlanActions: any[] = [];
  actionAssignees: any[] = [];
  usersForFilter: any[] = [];
  
  currentCustomPollUuid: any;
  currentCustomPoll: any;
  reportingDetails: any;
  reportingStatementsResponses: any;
  memberPrio: any;
  selectedUserTeam: any;
  selectedUserForOnboarding: any = null;
  selectedDateRangeForOnboarding: any = null;
  userTeams: any;
  userTeamsForOnboarding: any;

  constructor(public data: DataManipulationService, public api: ApiwrapperService, public router: Router,
              private activeRoute: ActivatedRoute, private translate: TranslateService) { }

  ngOnInit(): void {
    this.currentCustomPollUuid = this.activeRoute.snapshot.paramMap.get('pollUuid');
    this.getSelectedCustomPoll();
  }

  getSelectedCustomPoll() {
    if (this.data.selectedCustomPoll) {
      this.data.showLoading();
      this.currentCustomPoll = this.data.selectedCustomPoll;
      this.getCategoriesAndStatements();
    } else {
      this.data.showLoading();
      this.api.getWorkspaceCustomPoll().subscribe((res: any) => {
        this.currentCustomPoll = res._embedded["custom-polls"].filter((x: { uuid: any; }) => x.uuid === this.currentCustomPollUuid)[0];
        this.getReportingDetails();
      })
    }
  }

  getReportingDetails() {
    this.api.getCustomPollResponseRate(this.currentCustomPollUuid).subscribe((res: any) => {
      this.currentCustomPoll.reportingDetails = res;
      this.getCategoriesAndStatements();
    });
  }

  getCategoriesAndStatements() {
    if (this.currentCustomPoll.customPollTemplate.type === 'apv') {
      this.api.getReportingCategories(this.currentCustomPollUuid).subscribe((res: any) => {
        this.reportingCategories = res._embedded["custom-poll-categories-responses"];
        this.reportingDetails = {
          logo: '',
          type: this.currentCustomPoll.customPollTemplate.type, 
          startDate: this.currentCustomPoll.startDate.date, 
          endDate: this.currentCustomPoll.endDate.date,
          //@ts-ignore
          workspaceName: JSON.parse(localStorage.getItem('WORKSPACE')).name,
          reportingDetails: this.currentCustomPoll.reportingDetails,
        };
        this.getTeamsForSelector();
        this.setAssigneesForTasks();
        this.getCategoryScore();
      });
    } else {
      this.getCheckinsForReporting();
      this.reportingDetails = {
        logo: '',
        type: this.currentCustomPoll.customPollTemplate.type, 
        startDate: this.currentCustomPoll.startDate.date, 
        endDate: this.currentCustomPoll.endDate.date,
        //@ts-ignore
        workspaceName: JSON.parse(localStorage.getItem('WORKSPACE')).name,
        reportingDetails: this.currentCustomPoll.reportingDetails
      };
      this.getTeamsForOnboardingSelector();
    }
  }
  
  getCheckinsForReporting() {
    const teamUuidForXTeam = this.selectedUserTeam !== null && this.selectedUserTeam !== undefined ? this.selectedUserTeam.uuid : null;
    this.reportingCategories = [];
    this.api.getReportingCheckins(this.currentCustomPollUuid, teamUuidForXTeam, this.selectedUserForOnboarding, this.selectedDateRangeForOnboarding, this.isNonMember).subscribe((res:any) => {
      this.reportingCategories = res._embedded.collections;
      this.getCheckinsScore();
    });
  }

  setAssigneesForTasks() {
    this.api.getCustomPollTaskAssignees(this.currentCustomPollUuid).subscribe((res: any) => {
      this.actionAssignees = res.users;
    });
  }

  getCategoryScore() {
    this.reportingCategories.forEach((category: any) => {
      this.api.getCategoryScore(this.currentCustomPollUuid, category.category.uuid, this.selectedUserTeam !== null && this.selectedUserTeam !== undefined ? [this.selectedUserTeam.uuid] : []).subscribe((res: any) => {
        category.category.categoryScore = res.categoryScore;
      });
    });
    setTimeout(() => {
      this.getCategoryActions('', true);
      this.gotCategories = true;
      this.isReporting = true;
      this.data.hideLoading();
    }, 1700);
  }

  getCheckinsScore() {
    const teamUuidForXTeam = this.selectedUserTeam !== null && this.selectedUserTeam !== undefined ? this.selectedUserTeam.uuid : null;
    this.reportingCategories.forEach((checkin: any) => {
      this.api.getReportingCheckinsScore(this.currentCustomPollUuid, checkin.collection.uuid, teamUuidForXTeam ,this.selectedUserForOnboarding, this.selectedDateRangeForOnboarding, this.isNonMember).subscribe((res: any) => {
        checkin.collection.collectionScore = res.collectionScore;
      });
    });
    setTimeout(() => {
      this.gotOnboardingReporting = true;
      this.isReporting = true;
      this.data.hideLoading();
    }, 1700);
  }

  getCategoryStatements(category: any) {
    this.api.getReportingCategoryStatements(this.currentCustomPollUuid, category.categoryUuid, category.teamUuid).subscribe((res: any) => {
      this.getStatementsScore(res._embedded["custom-poll-category-questions"], category.teamUuid);
      this.getCategoryActions(category.categoryUuid, false);
    });
  }

  getCheckinStatements(checkin: any) {
    const teamUuidForXTeam = this.selectedUserTeam !== null && this.selectedUserTeam !== undefined ? this.selectedUserTeam.uuid : [];
    this.api.getReportingCheckinsStatements(checkin).subscribe((res: any) => {
      this.getStatementsScore(res._embedded["timed-collection-questions"], teamUuidForXTeam);
    });
  }

  getCategoryActions(categUuid: string, fromActionPlan: boolean) {
    const teamUuidForXTeam = this.selectedUserTeam !== null && this.selectedUserTeam !== undefined ? this.selectedUserTeam.uuid : this.userTeams.teams[1].uuid;
    this.api.getCustomPollTasks(this.currentCustomPollUuid, categUuid, teamUuidForXTeam).subscribe((res:any) => {
      if (categUuid !== '' && !fromActionPlan) {
        this.categoryActions = res._embedded["custom-poll-tasks"];
      } else {
        this.actionPlanActions = res._embedded["custom-poll-tasks"];
      }
    });
  }

  getTeamsForSelector() {
    this.api.getCustomPollTeamsForSelector(this.currentCustomPollUuid).subscribe((res:any) => {
      this.userTeams = this.setUserTeams(res.teams);
      this.getActionPlanStatus();
    });
  }

  getTeamsForOnboardingSelector() {
    this.api.getCustomPollOnboardingTeamsForSelector(this.currentCustomPollUuid).subscribe((res:any) => {
      this.userTeamsForOnboarding = this.setUserTeams(res._embedded["my-available-teams"]);
    });
  }

  getAvailableUsersForOnboarding() {
    const teamUuidForXTeam = this.selectedUserTeam !== null && this.selectedUserTeam !== undefined ? this.selectedUserTeam.uuid : null;
    this.api.getCustomPollAvailableUsers(this.currentCustomPollUuid, teamUuidForXTeam, this.isNonMember).subscribe((res: any) => {
      this.usersForFilter = res._embedded["my-available-users"];
    });
  }

  createCategoryAction(actionDetails: any) {
    const teamUuidForXTeam = this.selectedUserTeam !== null && this.selectedUserTeam !== undefined ? this.selectedUserTeam.uuid : this.userTeams.teams[1].uuid;
    actionDetails.customPollUuid = this.currentCustomPollUuid;
    if (this.selectedUserTeam !== null && this.selectedUserTeam !== undefined) {
      actionDetails.teamUuid = this.selectedUserTeam;
    }
    this.api.postCustomPollTask(actionDetails, teamUuidForXTeam).subscribe((res:any) => {
      this.categoryActions.unshift(res);
      this.actionPlanActions.unshift(res);
    });
  }

  getStatementsScore(categoryQuestions: any, teamsUuid: any) {
    const isOnboarding = this.currentCustomPoll.customPollTemplate.type === 'onboarding';
    categoryQuestions.forEach((statement: any) => {
      if (statement.type !== 'open') {
        this.api.getQuestionScore(this.currentCustomPollUuid, statement.uuid, teamsUuid, this.selectedUserForOnboarding, this.selectedDateRangeForOnboarding, this.isNonMember, isOnboarding).subscribe((res: any) => {
          statement.eligibleForScore = res.eligibleForScore;
          statement.score = res.questionScore;
        });
      }
    });
    setTimeout(() => {
      this.reportingCategoriesStatements = [...categoryQuestions];
    }, 1500);
  }

  getStatementResponses(statement: any) {
    if (statement.statementType === 'scaled') {
      this.api.getReportingScaledStatementResponses(this.currentCustomPollUuid, statement.statementUuid, statement.teamUuid, this.selectedUserForOnboarding, this.selectedDateRangeForOnboarding, this.isNonMember).subscribe((res:any) => {
        this.reportingStatementsResponses = {type: statement.statementType, responses: res};
      });
    }
    if (statement.statementType === 'open') {
      this.api.getReportingOpenStatementResponses(this.currentCustomPollUuid, statement.statementUuid, statement.teamUuid, statement.page, statement.limit, this.selectedUserForOnboarding, this.selectedDateRangeForOnboarding, this.isNonMember).subscribe((res:any) => {
        this.reportingStatementsResponses = {type: statement.statementType, responses: res};
      });
    }
  }

  setUserTeams(teamsToFilter: any) {
    this.memberPrio = {...this.data.userRole};
    let userTeams = [];
    if (this.currentCustomPoll.customPollTemplate.type === 'apv') {
      userTeams.push({name: this.translate.instant('custom_poll_main_reporting_all_teams'), uuid: '', nonMembers: false});
    }
    if (this.memberPrio.role.workspacePriority >= 30) {
      this.selectedUserTeam = null;
      teamsToFilter.forEach((team:any) => {
        userTeams.push({name:team.name, uuid:team.uuid});
      });
      if (this.currentCustomPoll.customPollTemplate.type === 'onboarding') {
        userTeams.push({name: this.translate.instant('custom_poll_main_reporting_people_outside_team'), uuid: '', nonMembers: true});
      }
    }
    if (this.memberPrio.role.workspacePriority < 30) {
      teamsToFilter.forEach((team: any, index:number) => {
        if (index === 0) {
          this.selectedUserTeam = {name: team.name, uuid: team.uuid, nonMembers: false};
        }
        userTeams.push({name: team.name, uuid: team.uuid, nonMembers: false});
      });
    }
    return {teams: userTeams, selectedTeam: this.selectedUserTeam};
  }

  teamChanged(event: any) {
    this.data.showLoading();
    this.selectedUserForOnboarding = null;
    this.isNonMember = event.isNonMembers;
    this.selectedUserTeam = event.changedTeamUuid !== '' ? {uuid: event.changedTeamUuid}:null;
    if (this.currentCustomPoll.customPollTemplate.type === 'apv') {
      this.getCategoryScore();
    } else {
      this.getAvailableUsersForOnboarding();
      this.getCheckinsForReporting();
    }
  }

  userChanged(event: any) {
    this.data.showLoading();
    this.selectedUserForOnboarding = event;
    this.getCheckinsForReporting();
  }

  dateRangeChanged(event: any) {
    this.data.showLoading();
    this.selectedDateRangeForOnboarding = event;
    this.getCheckinsForReporting();
  }

  changePages(isReporting: boolean, isActionPlan: boolean) {
    if (isReporting && !isActionPlan) {
      this.isReporting = true;
      this.isActionPlan = false;
    } else {
      this.isReporting = false;
      this.isActionPlan = true;
    }
  }

  filterActionPlanActionsByCategory(categoryUuid: string) {
    this.getCategoryActions(categoryUuid, true);
  }

  getActionPlanStatus() {
    const teamUuidForXTeam = (this.selectedUserTeam !== null && this.selectedUserTeam !== undefined) ? this.selectedUserTeam.uuid : this.userTeams.teams[1].uuid;
    this.api.getActionPlanStatus(this.currentCustomPollUuid, teamUuidForXTeam).subscribe((res: any) => {
      this.actionPlanCompleted = res.info ? false : true;
    });
  }

  setACtionPlanDone(event: any) {
    const teamUuidForXTeam = (this.selectedUserTeam !== null && this.selectedUserTeam !== undefined) ? this.selectedUserTeam.uuid : this.userTeams.teams[1].uuid;
    this.api.setActionPlanDone(this.currentCustomPollUuid, teamUuidForXTeam).subscribe((res: any) => {
      this.actionPlanCompleted = res.info ? false : true;
    });
  }

  resetOnboardingFilters() {
    this.isNonMember = false;
    this.selectedUserForOnboarding = null;
    this.selectedDateRangeForOnboarding = null;
    this.selectedUserTeam = null;
    this.reportingCategories = [];
    this.getCheckinsForReporting();
  }

}
