<!-- Feedback Questions Modal -->
<app-feedback-questions *ngIf="myTeamPollShow && gotImmediateLeaders"></app-feedback-questions>
<div style="background-color: #FFFFFF;
  border-radius: 35px 0 0 0;">

<div *ngIf="!data.showMyActions && !data.showTeamAnalyse" class="team-dashboard-header">
  <h3 class="team-dashboard-team-name" *ngIf="teamFound" (click)="clicking()">Team {{teamName}}</h3>

  <!--  <div class="team-dashboard-polling" *ngIf="myTeamPoll.length > 0 && this.myTeamRole === 10">-->
  <div class="team-dashboard-polling" *ngIf="myTeamPoll.length > 0 ">
    <div [ngStyle]="(myTeamRole === 10) ? {'margin-left':'0px'}:{}" class="team-polling-status-container">
      <button *ngIf="myTeamPoll.length>0" (click)="getTeamPoll()" [ngClass]="!gotImmediateLeaders ? 'feedback-load':'feedback-pulse'" class="give-feedback-button" pButton
              id="startCollector" type="button" label="{{'team_dashboard_give_feedback'| translate}}" [disabled]="!gotImmediateLeaders">
              <div *ngIf="!gotImmediateLeaders" class="loading-feedback">
                <div class="spinner-border text-primary spinner-colors" role="status" style="width: 3rem;height: 3rem;"></div>
              </div>
      </button>
      <span *ngIf="(myTeamRole === 10)"
            class="team-polling-time-remaining">{{getPollingTimeRemaining(myTeamPoll[0]['dueDate']['date']) < 0 ? nextPoll + ('team_days_left'|translate) : getPollingTimeRemaining(myTeamPoll[0]['dueDate']['date']) + ('team_days_left'|translate)}}</span>
    </div>
  </div>
  <div class="team-dashboard-polling" *ngIf="myTeamPoll.length === 0 && (this.myTeamRole === 10) && showTeamMemberPolingStatus">
    <span class="feedback-loop">{{'team_feedback_title'| translate}}</span>
    <div class="team-polling-status-container">
      <div *ngIf="teamMemberPolingDetails.type.show[0].ongoing || teamMemberPolingDetails.type.show[2].paused"
      [ngStyle]="teamMemberPolingDetails.type.show[0].ongoing ? {'background-color':'#48DF99'} : teamMemberPolingDetails.type.show[2].paused ? {'background-color':'#FF9800'} : {}"
        class="team-polling-status">
        <span style="font-size: 1.2rem; font-weight: 600;">{{teamMemberPolingDetails.type.name}}</span>
      </div>
      <span *ngIf="team?.teamActivePollingNextCronRun !== null" class="team-polling-time-remaining">{{getPollingTimeRemaining(team?.teamActivePollingNextCronRun['date']) + ('team_days_left_until_more_f' | translate)}}</span>
    </div>
  </div>

  <div class="team-dashboard-polling" *ngIf="showPolling && myTeamRole > 10">
    <span *ngIf="showPolling && myTeamRole > 10" class="feedback-loop">{{'team_feedback_title'| translate}}</span>
    <div [ngStyle]="myTeamRole === 10 ? {'margin-left':'0px'}:{}" class="team-polling-status-container">
      <div *ngIf="myTeamRole > 10"
           [ngStyle]="currentPolling.type?.show[0].ongoing ? {'background-color':'#48DF99'} : (currentPolling.type?.show[2].paused || currentPolling.type?.show[1].scheduled ? {'background-color':'#FF9800'} : {'background-color':'#D4D4D4'})"
           class="team-polling-status">
        <span
          style="font-size: 1.2rem; font-weight: 600;">{{currentPolling.type?.name}}</span>
      </div>
      <span *ngIf="currentPolling['status'] === 'active' && myTeamRole > 10"
            class="team-polling-time-remaining">{{getPollingTimeRemaining(currentPolling['cronNextRun']['date']) < 0 ? nextPoll + ('team_days_left'|translate) : getPollingTimeRemaining(currentPolling['cronNextRun']['date']) + ('team_days_left'|translate)}}</span>
      <span *ngIf="currentPolling['status'] === 'active' && (myTeamRole === 10)"
            class="team-polling-time-remaining">{{getPollingTimeRemaining(currentPolling['cronNextRun']['date']) < 0 ? nextPoll + ('team_days_left_until_more_f' | translate) : getPollingTimeRemaining(currentPolling['cronNextRun']['date']) + ('team_days_left_until_more_f' | translate)}}</span>
      <button *ngIf="currentPolling['status'] !== 'active' && myTeamRole > 10" class="setup-feedback" pButton
              type="button"
              label="{{currentPolling['status'] === 'inactive'? ('team_feedback_button'|translate):('team_feedback_resume_loop_button'|translate)}}"
              [ngStyle]="currentPolling['status'] === 'paused'  ? {'margin':'0'}:{}"
              icon="bi bi-chevron-right" iconPos="right"
              (click)="currentPolling['status'] !== 'paused'? showCollector(false): pauseCollector()"
              pTooltip="{{teamMembers.length - teamAdmins.length <4 ? ('team_need_atleast_4' | translate): ''}}"></button>
    </div>
    <div *ngIf="!(currentPolling['status'] === 'inactive') && myTeamRole > 10"
         [ngStyle]="currentPolling['status'] === 'paused' ? {'margin-left':'2rem'} : {}"
         class="statements-and-reposnse-rate">
      <div class="team-polling-statements" *ngIf="currentPolling.type?.show[0].ongoing"
           (click)="(!data.showDemo && currentPolling.type?.show[0].ongoing) ? openStatementsModal() : ''">
        <img style="margin: .8rem;" src="../../../assets/svg/statements-cloud.svg">
        <span class="team-polling-statements-text">{{'statements'|translate}}</span>
      </div>
      <div class="team-polling-response-rate-container">
        <h5 class="team-polling-response-rate-value">{{showReport ? team?.statisticsStatus?.percentage : '0'}}</h5>
        <span class="team-response-rate-value-percentage">%</span>
        <img class="response-rate-orange-cloud" src="../../../assets/svg/response-rate-orange.svg">
        <i
          *ngIf="myTeamRole > 10 && currentPolling['status'] === 'active' && 
            (currentPolling.type.name != 'SCHEDULED' || currentPolling.type.name != 'PLANLAGT') && (currentPolling.type.name != 'PAUSED' || currentPolling.type.name != 'PÅ PAUSE')" 
          class="bi bi-bell reminder-bell"
          style="cursor:pointer; width: 35px; height: 35px; font-size: 18px"
          [ngClass]="getBellPulse()?'reminder-pulse':''"
          (click)="notifyTeam()"
          pTooltip="{{'team_feedback_reminder'| translate}}"
          tooltipPosition="top" tooltipStyleClass="tooltip-style"></i>
      </div>
      <mat-icon [style]="{'color':'#2551BF', 'margin-right':'1rem'}" [matMenuTriggerFor]="menu">more_horiz</mat-icon>
      <mat-menu #menu="matMenu" xPosition="before">
        <button mat-menu-item [disabled]="data.showDemo" style="height: auto; margin: .5rem 0"
                *ngIf="currentPolling['status'] !== 'paused'">
          <span class="polling-menu-item" (click)="showCollector(true)">{{'team_edit_settings' | translate}}</span>
        </button>
        <button mat-menu-item [disabled]="data.showDemo" style=" margin: .5rem 0">
          <span class="polling-menu-item"
                (click)="pauseCollector()">{{currentPolling['status'] === 'paused' ? ('team_feedback_resume_loop_button'|translate) : ('team_feedback_pause_loop_button'|translate)}}</span>
        </button>
      </mat-menu>
    </div>
  </div>

</div>
</div>
<div [ngStyle]="data.showMyActions || data.showTeamAnalyse ? {'border-radius': '35px 0 0 0'}:{}" class="team-dashboard">
  <div *ngIf="teamFound && !data.showTeamActions && !data.showTeamAnalyse && !data.showMyActions"
       class="team-dashboard-indicators ease-in">
    <p-card>
      <div class="row">
        <div class="teams-indicators col-xl-6 col-lg-12">
          <h5 class="teams-indicators-title">{{'team_indicators'|translate}}</h5>
          <div class="teams-indicators-container"  style="height: 510px" id="teamColors" *ngIf="!gotTeamColors">
            <div class="spinner-border text-primary spinner-colors" role="status"></div>
          </div>
          <div class="teams-indicators-container" *ngIf="gotTeamColors">
            <div class="teams-indicators-succes-score"
                 pTooltip="{{'team_score_description'|translate}}"
                 tooltipPosition="bottom" tooltipStyleClass="tooltip-score" fitContent="true">
              <h5 class="succes-score-title">{{'team_score'|translate}}</h5>
              <div class="response-rate">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="20 7 100 110" version="1.1" width="180px"
                     height="180px">
                  <path class="grey" d="M40,90
                    A40,40 0 1,1 100,90"
                        style="fill:none;"/>
                  <path [attr.stroke]="scoreColor" [attr.stroke-dasharray]="setKnobProgressBar()" id="svgProgress"
                        class="progress" d="M40,90
                    A40,40 0 1,1 100,90"
                        style="fill:none;"/>
                  <path *ngIf="successScore != 0 && team?.pollsCount > 1"
                        [ngClass]="(team.teamReport.teamSuccessteamScore > team.previousTeamReport.teamSuccessteamScore) ? 'svgArrowUp' : (team.teamReport.teamSuccessteamScore < team.previousTeamReport.teamSuccessteamScore) ? 'svgArrowDown' : 'svgArrowEven'"
                        d="M15.358,9.708l.837-.837a.9.9,0,0,0,0-1.278L8.871.266a.9.9,0,0,0-1.278,0L.266,7.593a.9.9,0,0,0,0,1.278l.837.837A.906.906,0,0,0,2.4,9.693L6.723,5.151V15.984a.9.9,0,0,0,.9.9H8.833a.9.9,0,0,0,.9-.9V5.151l4.327,4.542A.9.9,0,0,0,15.358,9.708Z"></path>
                  <text class="svgTextValue"
                        [attr.x]="successScore === 0 ? 62 : (team.teamReport.teamSuccessteamScore === team.previousTeamReport.teamSuccessteamScore) ? 56 : 59"
                        y="70">{{successScore}}</text>
                  <text class="svgTextPercentage"
                        [attr.x]="successScore === 0 ? 74 : (team.teamReport.teamSuccessteamScore === team.previousTeamReport.teamSuccessteamScore) ? 78 : 80"
                        y="69">%
                  </text>
                </svg>
                <!-- <div class="outer-knob">
                  <div class="inner-knob"></div>
                </div> -->
                <!-- <p-knob [ngModel]="successScore" valueTemplate="{value}%" [size]="150" [valueColor]="scoreColor" [strokeWidth]="20" [readonly]="true"></p-knob> -->
              </div>
              <div class="succes-score-improvement" *ngIf="team.pollsCount > 1">
                <span *ngIf="(team.teamReport.teamSuccessteamScore > team.previousTeamReport.teamSuccessteamScore)">
                  <img style="margin-bottom: .4rem;"
                       src="../../../assets/svg/indicator-arrow-up.svg"><b>{{successScoreProgress + '% '}}</b>{{'team_score_change'|translate}}</span>
                <span *ngIf="(team.teamReport.teamSuccessteamScore < team.previousTeamReport.teamSuccessteamScore)">
                  <img style="margin-bottom: .4rem;"
                       src="../../../assets/svg/indicator-arrow-down.svg"><b>{{successScoreProgress + '% '}}</b>{{'team_score_change'|translate}}</span>
                <span *ngIf="(team.teamReport.teamSuccessteamScore === team.previousTeamReport.teamSuccessteamScore)"><i
                  [style]="{'color':'#5e5e5e'}" class="bi bi-dash-lg"></i> 0% {{'team_score_change'|translate}}</span>
              </div>
            </div>
            <div class="teams-indicators-percentages" #targetEl>
              <div class="row mt-2">
                <div
                  [ngStyle]="(indicator['performance'] == '../../assets/svg/performance-white.svg')? {'background-color' : '#FFFFFF', 'border': '1px solid #E4EDFE' } : {'background-color' : data.colors.white, 'border': '2px '+indicatorBackground['performance']+' solid' }"
                  class="team-indicators-values col-lg-6 col-md-12">
                  <img
                    [ngStyle]="(indicator['performance'] == '../../assets/svg/performance-white.svg')? {'background-image' : indicatorBackground['performance'] } : {'background-color' : data.colors.white, 'border': '2px '+indicatorBackground['performance']+' solid' }"
                    [src]="indicator['performance']" class="indicators-icon-large">
                  <i *ngIf="team.teamReport.PerformanceEligibility === false" class="bi bi-exclamation-circle-fill me-1 text-warning w-dl-questionmark indicator-warning"
                     pTooltip="{{'matrix_alert'|translate}}" tooltipPosition="bottom" tooltipStyleClass="tooltip-score"></i>
                  <span class="indicators-title">{{'team_performance'| translate}}</span>
                  <div
                    [ngStyle]="(indicator['performance'] == '../../assets/svg/performance-white.svg')? {'background-color' : '#F0F3FA' } : {'background-color' : data.colors.white}"
                    class="indicators-percentage-container col-lg-6 col-md-12">
                    <span class="percentage-value">{{team?.teamReport.Performance}}</span>
                    <span class="percentage">%</span>
                    <img class="indicator-arrow-img" *ngIf="team?.pollsCount > 1" [src]="team?.teamReport.Performance > team?.previousTeamReport.Performance ? '../../../assets/svg/indicator-arrow-up.svg':
                    team?.teamReport.Performance < team?.previousTeamReport.Performance ? '../../../assets/svg/indicator-arrow-down.svg':''">
                    <i *ngIf="team?.teamReport.Performance != 0"
                       [ngClass]="(team?.teamReport.Performance === team?.previousTeamReport.Performance) ? 'bi bi-dash-lg even':''"
                       class="indicator-status-arrow">
                    </i>
                  </div>
                </div>
                <div
                  [ngStyle]="(indicator['satisfaction'] == '../../assets/svg/satisfaction-white.svg')? {'background-color' : '#FFFFFF', 'border': '1px solid #E4EDFE' } : {'background-color' : data.colors.white, 'border': '2px '+indicatorBackground['satisfaction']+' solid' }"
                  class="team-indicators-values col-lg-6 col-md-12">
                  <img
                    [ngStyle]="(indicator['satisfaction'] == '../../assets/svg/satisfaction-white.svg')? {'background-image' : indicatorBackground['satisfaction'] } : {'background-color' : data.colors.white, 'border': '2px '+indicatorBackground['satisfaction']+' solid' }"
                    [src]="indicator['satisfaction']" class="indicators-icon-large">
                  <i *ngIf="team.teamReport.SatisfactionEligibility === false" class="bi bi-exclamation-circle-fill me-1 text-warning w-dl-questionmark indicator-warning"
                     pTooltip="{{'matrix_alert'|translate}}" tooltipPosition="bottom" tooltipStyleClass="tooltip-score"></i>
                  <span class="indicators-title">{{'team_satisfaction'| translate}}</span>
                  <div
                    [ngStyle]="(indicator['satisfaction'] == '../../assets/svg/satisfaction-white.svg')? {'background-color' : '#F0F3FA' } : {'background-color' : data.colors.white}"
                    class="indicators-percentage-container col-lg-6 col-md-12">
                    <span class="percentage-value">{{team.teamReport.Satisfaction}}</span>
                    <span class="percentage">%</span>
                    <img class="indicator-arrow-img" *ngIf="team?.pollsCount > 1" [src]="team.teamReport.Satisfaction > team.previousTeamReport.Satisfaction ? '../../../assets/svg/indicator-arrow-up.svg':
                    team.teamReport.Satisfaction < team.previousTeamReport.Satisfaction ? '../../../assets/svg/indicator-arrow-down.svg':''">
                    <i *ngIf="team.teamReport.Satisfaction != 0"
                       [ngClass]="(team.teamReport.Satisfaction === team.previousTeamReport.Satisfaction) ? 'bi bi-dash-lg even':''"
                       class="indicator-status-arrow">
                    </i>
                  </div>
                </div>
              </div>
              <div class="row mt-2">
                <div
                  [ngStyle]="(indicator['motivation'] == '../../assets/svg/motivation-white.svg')? {'background-color' : '#FFFFFF', 'border': '1px solid #E4EDFE' } : {'background-color' : data.colors.white, 'border': '2px '+indicatorBackground['motivation']+' solid' }"
                  class="team-indicators-values col-lg-6 col-md-12">
                  <img
                    [ngStyle]="(indicator['motivation'] == '../../assets/svg/motivation-white.svg')? {'background-image' : indicatorBackground['motivation'] } : {'background-color' : data.colors.white, 'border': '2px '+indicatorBackground['motivation']+' solid' }"
                    [src]="indicator['motivation']" class="indicators-icon-large">
                  <i *ngIf="team.teamReport.MotivationEligibility === false" class="bi bi-exclamation-circle-fill me-1 text-warning w-dl-questionmark indicator-warning"
                     pTooltip="{{'matrix_alert'|translate}}" tooltipPosition="bottom" tooltipStyleClass="tooltip-score"></i>
                  <span class="indicators-title">{{'team_motivation'| translate}}</span>
                  <div
                    [ngStyle]="(indicator['motivation'] == '../../assets/svg/motivation-white.svg')? {'background-color' : '#F0F3FA' } : {'background-color' : data.colors.white}"
                    class="indicators-percentage-container col-lg-6 col-md-12">
                    <span class="percentage-value">{{team.teamReport.Motivation}}</span>
                    <span class="percentage">%</span>
                    <img class="indicator-arrow-img" *ngIf="team?.pollsCount > 1" [src]="team.teamReport.Motivation > team.previousTeamReport.Motivation ? '../../../assets/svg/indicator-arrow-up.svg':
                    team.teamReport.Motivation < team.previousTeamReport.Motivation ? '../../../assets/svg/indicator-arrow-down.svg':''">
                    <i *ngIf="team.teamReport.Motivation != 0"
                       [ngClass]="(team.teamReport.Motivation === team.previousTeamReport.Motivation) ? 'bi bi-dash-lg even':''"
                       class="indicator-status-arrow">
                    </i>
                  </div>
                </div>
                <div
                  [ngStyle]="(indicator['stress'] == '../../assets/svg/stress-white.svg')? {'background-color' : '#FFFFFF', 'border': '1px solid #E4EDFE' } : {'background-color' : data.colors.white, 'border': '2px '+indicatorBackground['stress']+' solid' }"
                  class="team-indicators-values col-lg-6 col-md-12">
                  <img
                    [ngStyle]="(indicator['stress'] == '../../assets/svg/stress-white.svg')? {'background-image' : indicatorBackground['stress'] } : {'background-color' : data.colors.white, 'border': '2px '+indicatorBackground['stress']+' solid' }"
                    [src]="indicator['stress']" class="indicators-icon-large">
                  <i *ngIf="team.teamReport.StressEligibility === false" class="bi bi-exclamation-circle-fill me-1 text-warning w-dl-questionmark indicator-warning"
                     pTooltip="{{'matrix_alert'|translate}}" tooltipPosition="bottom" tooltipStyleClass="tooltip-score"></i>
                  <span class="indicators-title">{{'team_stress_free'| translate}}</span>
                  <div
                    [ngStyle]="(indicator['stress'] == '../../assets/svg/stress-white.svg')? {'background-color' : '#F0F3FA' } : {'background-color' : data.colors.white}"
                    class="indicators-percentage-container col-lg-6 col-md-12">
                    <span class="percentage-value">{{team.teamReport.Stress}}</span>
                    <span class="percentage">%</span>
                    <img class="indicator-arrow-img" *ngIf="team?.pollsCount > 1" [src]="team.teamReport.Stress > team.previousTeamReport.Stress ? '../../../assets/svg/indicator-arrow-up.svg':
                    team.teamReport.Stress < team.previousTeamReport.Stress ? '../../../assets/svg/indicator-arrow-down.svg':''">
                    <i *ngIf="team.teamReport.Stress != 0"
                       [ngClass]="(team.teamReport.Stress === team.previousTeamReport.Stress) ? 'bi bi-dash-lg even':''"
                       class="indicator-status-arrow">
                    </i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="teams-chart-side-container col-xl-6 col-lg-12" id="teamColors2">
          <div class="teams-chart-title-container">
            <h5 class="teams-indicators-title">{{'team_progress'|translate}}
              <img *ngIf="(workspace?.workspaceSubscription.workspacePlan.requirePayment === 'no' && myTeamRole > 10)"
                   src="../../../assets/images/crown.png" style="width: 24px"
                   pTooltip="Upgrade your plan to be able to use this feature" tooltipPosition="bottom" alt=""></h5>
            <div *ngIf="myTeamRole > 10" tooltipStyleClass="tooltip-style" tooltipPosition="bottom"
                 [pTooltip]="(team.pollsCount < 2)? ('team_export_hover'|translate): ''">
              <button (click)="(team.pollsCount > 1)? openDownloadReportModal() : ''" [disabled]="team.pollsCount < 2"
                      class="download-report-button p-button-rounded" pButton
                      type="button">
                <i class="bi bi-file-earmark-arrow-down"></i>
                <p style="margin: 0 0 0 .5rem; font-size: 1.4rem;">{{'team_export'|translate}}</p>
              </button>
            </div>
          </div>
          <div class="team-chart-main-container">
            <div class="team-chart-header">
              <!--              <button style="padding: 2.05rem 2.25rem;"-->
              <!--                      (click)="(myTeamRole > 10) ? changeChart( 'successteamScore', 'Success Score') : ''" pButton-->
              <!--                      type="button" label="All"-->
              <!--                      [ngClass]="chartType === 'successteamScore' ? 'indicator-button-active':''"-->
              <!--                      class="indicator-button"></button>-->
              <button (click)="(myTeamRole > 10) ? changeChart('successteamScore', 'Success Score') : ''"
                      [ngClass]="chartType === 'successteamScore' ? 'indicator-button-active':''"
                      pTooltip="{{'team_score'| translate}}" tooltipPosition="bottom" tooltipStyleClass="tooltip-style"
                      class="indicator-button">
                <img class="indicator-button-icon" alt="logo"
                     [src]="chartType === 'successteamScore' ? indicator['successteamScore'] : indicator['successteamScoreChart']"/>
              </button>

              <button (click)="changeChart('performance', 'Performance')" pTooltip="{{'team_performance'| translate}}"
                      tooltipPosition="bottom" tooltipStyleClass="tooltip-style"
                      [ngClass]="chartType === 'performance' ? 'indicator-button-active':''" class="indicator-button">
                <img class="indicator-button-icon" alt="logo"
                     [src]="chartType === 'performance' ? indicator['performance'] : indicator['performanceChart']"/>
              </button>
              <button (click)="changeChart('satisfaction', 'Satisfaction')" pTooltip="{{'team_satisfaction'| translate}}"
                      tooltipPosition="bottom" tooltipStyleClass="tooltip-style"
                      [ngClass]="chartType === 'satisfaction' ? 'indicator-button-active':''" class="indicator-button">
                <img class="indicator-button-icon" alt="logo"
                     [src]="chartType === 'satisfaction' ? indicator['satisfaction'] : indicator['satisfactionChart']"/>
              </button>
              <button (click)="changeChart('motivation', 'Motivation')" pTooltip="{{'team_motivation'| translate}}" tooltipPosition="bottom"
                      tooltipStyleClass="tooltip-style"
                      [ngClass]="chartType === 'motivation' ? 'indicator-button-active':''" class="indicator-button">
                <img class="indicator-button-icon" alt="logo"
                     [src]="chartType === 'motivation' ? indicator['motivation'] : indicator['motivationChart']"/>
              </button>
              <button (click)="changeChart('stress', 'Stress-free')" pTooltip="{{'team_stress_free'|translate}}" tooltipPosition="bottom"
                      tooltipStyleClass="tooltip-style"
                      [ngClass]="chartType === 'stress' ? 'indicator-button-active':''" class="indicator-button">
                <img class="indicator-button-icon" alt="logo"
                     [src]="chartType === 'stress' ? indicator['stress'] : indicator['stressChart']"/>
              </button>
              <div class="chart-response-rate">
                <!--                <img src="../../../assets/svg/response-rate.svg" style="width: 6rem" pTooltip="Response rate"-->
                <!--                     tooltipPosition="bottom" tooltipStyleClass="tooltip-style">-->
                <!--                <span style="font-size:1.6rem; margin-left: .95rem;"></span>-->
                <!--                <p-inputSwitch [(ngModel)]="chartResponseRateChecked"></p-inputSwitch>-->
                <button (click)="chartResponseRate()" style="padding: 0.8rem"
                        [ngClass]="chartResponseRateChecked ? 'indicator-button-active-score':''" class="indicator-button">
                  <img style="width: 4rem" pTooltip="{{'review_response'| translate}}" tooltipPosition="bottom"
                       tooltipStyleClass="tooltip-style" alt="logo"
                       [src]="chartResponseRateChecked ? '../../../assets/svg/response-rate-white.svg' : '../../../assets/svg/response-rate.svg'"/>
                </button>
              </div>
            </div>
            <div class="chart-box">
              <div *ngIf="!showChart" echarts [options]="chartOption" [merge]="chartOption" [loading]="true"
                   class="chart"></div>
              <div *ngIf="showChart" echarts [options]="chartOption" [merge]="chartOption" class="chart"></div>
            </div>
          </div>
        </div>
      </div>
      <div style="display: flex; flex-direction: row; align-items: center; justify-content: space-between;">
        <div style=" margin: 2rem 1rem 0 0" *ngIf="team.teamReport.startDate !== null">
          <h5 style="float: right">{{'team_period'|translate}} {{team?.teamReport?.startDate?.date | date}} - {{team?.teamReport?.endDate?.date | date}}</h5>
        </div>
        <div style="display: flex; flex-direction: row; align-items: center; margin-top: 2rem" *ngIf="myTeamRole > 10">
          <h5 style="scroll-padding-right: 2rem; margin-bottom: 1rem">{{'team_visible'| translate}}</h5>
          <input type="checkbox" [checked]="team.indicatorVisibility === 'yes'" id="switch"/><label id="switch-label"
            (click)="!data.showDemo? showMemberReport($event): ''"
            pTooltip="When this is ON (green), all your team members can see the Team Indicators and Progress Overview. If it’s OFF (grey), they will be hidden."></label>
        </div>
      </div>
      <ng-template pTemplate="footer">
        <div *ngIf="worstStatements.length === 0" class="actions-card-footer">
          <p-button (onClick)="(!this.data.showDemo?showTeamInsights():'')" label="{{'see_all_insights'|translate}}"
                    *ngIf="myTeamRole > 10"
                    icon="bi bi-arrow-right" iconPos="right"></p-button>
        </div>
      </ng-template>
    </p-card>
  </div>

  <div *ngIf="!hideProgressSpinner && teamFound" class="loading-container">
    <div class="spinner-border text-primary spinner-colors" role="status"></div>
  </div>

  <!--CRITICAL-->
  <div *ngIf="(worstStatements.length > 0 && myTeamRole > 10) && teamFound && hideProgressSpinner"
       class="critical-feedback-container" #feedbackToActOn>
    <p-card>
      <div class="critical-feedback-header">
        <h5 class="actions-card-header-title" [style]="{'margin':'0'}">{{'team_feedback_act_on' | translate}}</h5>
        <button pButton type="button" label="{{'team_feedback_latest_loop' | translate}}" (click)="getWorstStatements(worstStatemstLastPoll.uuid);this.selectedPreviousPoll = ''"
                [style]="worstLoop && selectedPreviousPoll === '' ?'background-color:#19AEFF; color: white; margin-left:50px':'margin-left:50px'"
                class="critical-feedback-button"></button>
        <button pButton type="button" label="{{'all_time' | translate}}" [style]="!worstLoop?'background-color:#19AEFF; color: white':''"
                (click)="getWorstStatements('');this.selectedPreviousPoll = ''" class="critical-feedback-button"></button>
        <div *ngIf="!data.showDemo" class="previous-loops-container" (click)="showPreviousDropdown($event)">
          <div class="previous-loops-dropdown" >
            <img src="../../../assets/svg/icon_calendar.svg">
            <span>{{selectedPreviousPoll ? (selectedPreviousPoll.startDate | date:'MMM d')+' - '+(selectedPreviousPoll.endDate | date:'MMM d') : ('previous_loop_dp' | translate)}}</span>
            <i class="bi bi-chevron-down"></i>
          </div>
          <div class="previous-loops-drop-items" [ngStyle]="showPreviousLoopDd ? {'display':'flex'}:{}" (click)="$event.stopPropagation();">
            <p-carousel [value]="previousLoopsDates" [showIndicators]="false" [numVisible]="1" [page]="currentLoopIndexPage" (onPage)="changePreviousLoopYearIndex($event)">
              <ng-template let-loop pTemplate="item">
                  <h3 style="text-align: center;">{{loop.year | date: 'yyyy'}}</h3>
              </ng-template>
            </p-carousel>
            <div class="eligible-polls-dates-container">
              <div class="eligible-polls-date" (click)="changePoll(date)" *ngFor="let date of previousLoopsDates[previousLoopIndexPage].dates">
                {{date.startDate | date:'MMM d'}} - {{date.endDate | date:'MMM d'}}
              </div>
              <div *ngIf="eligiblePolls.length === 0" class="eligible-polls-date no-date">-</div>
            </div>
          </div>
        </div>
      </div>
      <div class="critical-feedback-body" #actionsCarousel>
        <p-carousel [value]="worstStatements" [numVisible]="1" [numScroll]="1" [circular]="true"
                    [showIndicators]="false" [showNavigators]="worstStatements.length > 1" (onPage)="testing($event)">
          <ng-template let-statement pTemplate="item">
            <div [ngStyle]="{'background-color':statement.color.hex}" class="statement-item">
              <!-- <i style="margin-left: 1.6rem;color:white" class="bi bi-chat-fill statement-icon"></i> -->
              <img style="width: 4.68rem;" src="../../../assets/svg/opened-statement-{{statement?.color.color}}.svg">
              <h3 class="statement-question-title">{{statement.question.title}}</h3>
              <div *ngIf="worstStatements.length > 1" class="statement-counter">
                <h3>{{getStatementIndex(statement, 'for_counter') + ' / ' + worstStatements.length}}</h3>
              </div>
              <div class="carousel-arrows-container">
                
              </div>
            </div>
            <div class="critical-feedback-main-container">
              <app-response-distribution *ngIf="statement.question.responseDistribution.eligibleForResult || statement.question.responseDistribution.eligibleForShowing" [responseDistributionObs]="responseDistribution"></app-response-distribution>
              <h3 [style]="statement.question.responseDistribution.eligibleForResult || statement.question.responseDistribution.eligibleForShowing ? 
                            {'margin':'4.3rem 0 2.4rem 0'}:{'margin':'0 0 2.4rem 0'}">
                            {{(statement.showTasks.length === 0 ? 'insights_statement_no_actions' : 'insights_statement_actions')| translate}}</h3>
              <div class="statement-solutions">
                <div class="solutions-container">
                  <div style="flex: 25%;" *ngFor="let action of statement.showTasks; let i = index">
                    <div *ngIf="i <= 2" class="solution-task">
                      <span class="solution-title">
                        <img style="margin-right: .4rem;" [src]="'../../../assets/svg/action-' + (action.task.assignedTasks.length ? 'active.svg':'inactive.svg')">
                        {{action.task.title}}
                      </span>
                      <span class="solution-description" [innerHTML]="action.task.displayDescription"></span>
                      <div  class="solutions-actions">
                        <div *ngIf="data.teamLeaders.length === 1"
                             [style]="(action.task.assignedTasks.length > 0)? 'display: none': ''">
                          <button *ngFor="let member of data.teamLeaders" pButton type="button" label="{{'assign_to_me' | translate}}"
                                  (click)="!data.showDemo ? assignAction(action, statement, data.teamLeaders[0]):''"
                                  class="solution-assign-to">
                            <!-- <div tooltipPosition="bottom" tooltipStyleClass="tooltip-style"
                                 pTooltip="{{ member.user.detail.displayName | getDisplayName: member.user.detail.firstname: member.user.detail.lastname: member.user.email }}">
                              <img class="solution-assign-to-avatar"
                                   src="{{(member.user.avatar !== null && member.user.avatar.path !== null) ? this.data.baseUrl + member.user.avatar.path: '../../../assets/svg/user-icon.svg'}}"/>
                            </div> -->
                          </button>
                        </div>
                        <p-dropdown *ngIf="data.teamLeaders.length > 1 && action.task.assignedTasks.length === 0" [options]="data.teamLeaders" [(ngModel)]="selectedTeamLeaderForAction" class="team-type-dropdown" optionLabel="name" placeholder="{{'assign_to' | translate}}" dropdownIcon="bi bi-chevron-down" [appendTo]="actionsCarousel">
                          <ng-template let-selectedLeader pTemplate="selectedItem">
                              <div class="sort-item sort-item-value" *ngIf="selectedTeamLeaderForAction">
                                <span>{{ selectedLeader.user.detail.displayName | getDisplayName: selectedLeader.user.detail.firstname: selectedLeader.user.detail.lastname: selectedLeader.user.email }}</span>
                                <img class="action-assigned-user-icon"
                                  src="{{(selectedLeader.user.avatar !== null && selectedLeader.user.avatar.path !== null) ? this.data.baseUrl + selectedLeader.user.avatar.path: '../../../assets/svg/user-icon.svg'}}">
                                </div>
                          </ng-template>
                          <ng-template let-leader pTemplate="item">
                            <div (click)="!data.showDemo ? assignAction(action, statement, leader):''">
                              <span>{{ leader.user.detail.displayName | getDisplayName: leader.user.detail.firstname: leader.user.detail.lastname: leader.user.email }}</span>
                              <img class="action-assigned-user-icon"
                                src="{{(leader.user.avatar !== null && leader.user.avatar.path !== null) ? this.data.baseUrl + leader.user.avatar.path: '../../../assets/svg/user-icon.svg'}}">
                            </div>
                          </ng-template>
                        </p-dropdown>
                        <div *ngIf="action.task.assignedTasks.length && action.frequency > 0" class="assigned-container">
                          <img class="assigned-task-avatar" src="{{action.task.assignedTasks[0].assignee.avatar != null ? this.data.baseUrl + action.task.assignedTasks[0].assignee.avatar.path : '../../../assets/svg/user-icon.svg'}}">
                          <div class="d-flex flex-column" style="margin-left: .845rem;">
                            <span style="font-size: 1.2rem; color: #656565;">{{'assigned_to' | translate}}</span>
                            <span style="font-size: 1.2rem; color: #000000; font-weight: 500;">{{ action.task.assignedTasks[0].assignee.detail.displayName | getDisplayName: action.task.assignedTasks[0].assignee.detail.firstname: action.task.assignedTasks[0].assignee.detail.lastname: action.task.assignedTasks[0].assignee.email }}</span>
                          </div>
                        </div>
                        <button (click)="showAction(action, statement)" type="button"
                                class="btn see-more-btn">{{'team_see_more_button' | translate}}
                        </button>
                        <!--                      <img (click)="removeStatementTask(action.teamTask, getStatementIndex(statement, 'for_task'), i)"-->
                        <!--                           src="../../../assets/svg/ionic-thumbs-down.svg" class="solution-not-relevant">-->
                      </div>
                    </div>
                  </div>
                  <div class="solution-task own-action" style="cursor: pointer; margin-right: 0;flex: 25%" (click)="openOwnActionModal(statement)">
                    <form [formGroup]="challengesService.createActionForm">
                      <div class="own-task-form">
                        <div style="display: flex;">
                          <img src="../../../assets/svg/action-inactive.svg" style="margin: 0 .6rem 0 0; height: 2rem;">
                          <span style="font-size: 1.58rem;cursor:pointer; color: #2551BF;opacity: 0.42;">{{'insights_statement_make_own'|translate}}</span>
                          <!-- <input class="task-title-input" formControlName="actionTitle"
                                 style="font-size: 1.8rem;cursor:pointer; color: #2551BF" placeholder="{{'insights_statement_make_own'|translate}}"> -->
                        </div>
                        <textarea class="action-description-input" style="cursor:pointer;height: 9.21rem;"
                                  formControlName="actionDescription"
                                  placeholder="{{'team_solution_input' | translate}}" type="text"></textarea>
                      </div>
                    </form>
                    <div class="add-own-task">
                      <i
                        [style]="{'font-size':'2.4rem', 'color':'#2551BF'}" class="bi bi-plus"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </p-carousel>
        <button class="team-insights-button" (click)="(!this.data.showDemo?showTeamInsights():'')" pButton label="{{'see_all_insights'|translate}}"
                    *ngIf="myTeamRole > 10"
                    icon="bi bi-arrow-right" iconPos="right"></button>
      </div>
    </p-card>
  </div>


  <!--ACTIONS-->
  <div *ngIf="(myTeamRole > 10) && team && hideProgressSpinner "
       class="actions-card">
    <p-card>
      <div class="actions-card-header">
        <h5 class="actions-card-header-title">{{'team_actions'|translate}}</h5>
        <div class="actions-buttons-container">
          <button pButton type="button" label="{{'all'|translate}}" (click)="changeActions('all')"
                  [ngClass]=" challengesService.sort === 'all'? 'indicator-button-active' : ''">
            <img class="indicator-button-icon" alt="logo" style="margin-right: -1.3rem;"
                 [src]="challengesService.sort === 'all' ? '../../../assets/svg/overall-white.svg': '../../../assets/svg/overall.svg'"/>
          </button>
          <button pButton type="button" label="{{'team_performance'| translate }}" (click)="changeActions('Performance')"
                  [ngClass]=" challengesService.sort === 'Performance'? 'indicator-button-active' : ''">
            <img class="indicator-button-icon" alt="logo" style="margin-right: .8rem;"
                 [src]="challengesService.sort === 'Performance' ? '../../../assets/svg/performance-white.svg': '../../../assets/svg/action-performance.svg'"/>
          </button>
          <button pButton type="button" label="{{'team_satisfaction'| translate }}" (click)="changeActions('Satisfaction')"
                  [ngClass]=" challengesService.sort === 'Satisfaction'? 'indicator-button-active' : ''">
            <img class="indicator-button-icon" alt="logo" style="margin-right: .8rem;"
                 [src]="challengesService.sort === 'Satisfaction' ? '../../../assets/svg/satisfaction-white.svg': '../../../assets/svg/action-satisfaction.svg'"/>
          </button>
          <button pButton type="button" label="{{'team_motivation'|translate}}" (click)="changeActions('Motivation')"
                  [ngClass]=" challengesService.sort === 'Motivation'? 'indicator-button-active' : ''">
            <img class="indicator-button-icon" alt="logo"
                 [src]="challengesService.sort === 'Motivation' ? '../../../assets/svg/motivation-white.svg': '../../../assets/svg/action-motivation.svg'"/>
          </button>
          <button pButton type="button" label="{{'team_stress_free'| translate }}" (click)="changeActions('Stress')"
                  [ngClass]=" challengesService.sort === 'Stress'? 'indicator-button-active' : ''">
            <img class="indicator-button-icon" alt="logo"
                 [src]="challengesService.sort === 'Stress' ? '../../../assets/svg/stress-white.svg': '../../../assets/svg/action-stress.svg'"/>
          </button>
          <button *ngIf="challengesService.myAssignActions.length > 0" style="margin: 0 0 0 auto;" pButton type="button" label="{{!showArchiveActions ? ('see_archive' | translate) : ('hide_archive' | translate)}}" (click)="showArchiveActions = !showArchiveActions">
          </button>
        </div>
      </div>
      <div class="actions-card-body" *ngIf="challengesService.myAssignActions.length > 0">
        <div
          cdkDropList
          id="todolist"
          #todoList="cdkDropList"
          [cdkDropListData]="challengesService.myProblemsTasks"
          [cdkDropListConnectedTo]="[progressList, doneList, archiveList]"
          class="actions-tasks-container"
          (cdkDropListDropped)="drop($event)">
          <div class="d-flex flex-row align-items-center">
            <div class="actions-tasks-todo">
              <span>{{'team_actions_to_do' | translate}}</span>
            </div>
            <span
              style="font-size: 1.2rem; margin: .5rem 0 0 1rem;">({{(challengesService.myProblemsTasks.length > actionsNumber) ? actionsNumber : challengesService.myProblemsTasks.length}})</span>
          </div>
          <div *ngFor="let item of challengesService.myProblemsTasks, let t = index" cdkDrag
               (click)="showActionWithStatements(item['teamTask'])" style="cursor: pointer;">
            <div
              *ngIf="t < actionsNumber"
              class="action-task-box">
              <div>
                <span class="action-title">
                  <img style="margin-right: .4rem;" src="../../../assets/svg/action-active.svg">
                  {{item['teamTask']['title']}}</span>
              </div>
              <div class="action-author-container">
                <img
                  *ngIf="item['teamTask']['author']['avatar'] !== null && item['teamTask']['author']['avatar']['path'] !== null"
                  class="action-user-avatar"
                  src="{{baseUrl + item['teamTask']['author']['avatar']['path']}}">
                <img style="width: 50px" *ngIf="item['teamTask']['author']['avatar'] === null"
                     src="../../../assets/svg/user-icon.svg" alt="">
                <div class="action-author-name">
                  <span [style]="{'font-size' : '11px'}">{{'team_action_assigned_by'|translate}}</span>
                  <!--                  <span>{{item['teamTask']['author']['detail']['displayName']}}</span>-->
                  <span
                    class="author-name">{{ item['teamTask']['author']['detail']['displayName'] | getDisplayName: item['teamTask']['author']['detail']['firstname'] : item['teamTask']['author']['detail']['lastname']: item['teamTask']['author']['email']}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          cdkDropList
          id="inProgressList"
          #progressList="cdkDropList"
          [cdkDropListData]="challengesService.myInProgressTasks"
          [cdkDropListConnectedTo]="[todoList, doneList, archiveList]"
          class="actions-tasks-container"
          (cdkDropListDropped)="drop($event)">
          <div class="d-flex flex-row align-items-center">
            <div class="actions-tasks-progress">
              <span>{{'team_actions_in_progress' | translate}}</span>
            </div>
            <span
            style="font-size: 1.2rem; margin: .5rem 0 0 1rem;">({{(challengesService.myInProgressTasks.length > actionsNumber) ? actionsNumber : challengesService.myInProgressTasks.length}})</span>
          </div>
          <div *ngFor="let item of challengesService.myInProgressTasks, let p = index" cdkDrag
               (click)="showActionWithStatements(item['teamTask'])" style="cursor: pointer;">
            <div
              *ngIf="p < actionsNumber"
              class="action-task-box">
              <div>
                <span class="action-title">
                  <img style="margin-right: .4rem;" src="../../../assets/svg/action-active.svg">
                  {{item['teamTask']['title']}}</span>
              </div>
              <div class="action-author-container">
                <img
                  *ngIf="item['teamTask']['author']['avatar'] !== null && item['teamTask']['author']['avatar']['path'] !== null"
                  class="action-user-avatar"
                  src="{{baseUrl + item['teamTask']['author']['avatar']['path']}}">
                <img style="width: 50px" *ngIf="item['teamTask']['author']['avatar'] === null"
                     src="../../../assets/svg/user-icon.svg" alt="">
                <div class="action-author-name">
                  <span [style]="{'font-size' : '11px'}">{{'team_action_assigned_by'|translate}}</span>
                  <span
                    class="author-name">{{ item['teamTask']['author']['detail']['displayName'] | getDisplayName: item['teamTask']['author']['detail']['firstname'] : item['teamTask']['author']['detail']['lastname']: item['teamTask']['author']['email']}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          cdkDropList
          id="doneList"
          #doneList="cdkDropList"
          [cdkDropListData]="challengesService.myCompletedTasks"
          [cdkDropListConnectedTo]="[todoList, progressList, archiveList]"
          class="actions-tasks-container"
          (cdkDropListDropped)="drop($event)">
          <div class="d-flex flex-row align-items-center">
            <div class="actions-tasks-done">
              <span>{{'team_actions_done' | translate}}</span>
            </div>
            <span
            style="font-size: 1.2rem; margin: .5rem 0 0 1rem;">({{(challengesService.myCompletedTasks.length > actionsNumber) ? actionsNumber : challengesService.myCompletedTasks.length}})</span>
          </div>
          <div *ngFor="let item of challengesService.myCompletedTasks, let p = index" cdkDrag
               (click)="showActionWithStatements(item['teamTask'])" style="cursor: pointer;">
            <div
              *ngIf="p < actionsNumber"
              class="action-task-box">
              <div>
                <span class="action-title">
                  <img style="margin-right: .4rem;" src="../../../assets/svg/action-active.svg">
                  {{item['teamTask']['title']}}</span>
              </div>
              <div class="action-author-container">
                <img
                  *ngIf="item['teamTask']['author']['avatar'] !== null && item['teamTask']['author']['avatar']['path'] !== null"
                  class="action-user-avatar"
                  src="{{baseUrl + item['teamTask']['author']['avatar']['path']}}" alt="">
                <img style="width: 50px" *ngIf="item['teamTask']['author']['avatar'] === null"
                     src="../../../assets/svg/user-icon.svg" alt="">

                <div class="action-author-name">
                  <span [style]="{'font-size' : '11px'}">{{'team_action_assigned_by'|translate}}</span>
                  <span
                    class="author-name">{{ item['teamTask']['author']['detail']['displayName'] | getDisplayName: item['teamTask']['author']['detail']['firstname'] : item['teamTask']['author']['detail']['lastname']: item['teamTask']['author']['email']}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          [ngStyle]="showArchiveActions ? {'display':'inline-block'}:{'display':'none'}"
          cdkDropList
          id="archiveList"
          #archiveList="cdkDropList"
          [cdkDropListData]="challengesService.myArchivedTasks"
          [cdkDropListConnectedTo]="[todoList, progressList, doneList]"
          class="actions-tasks-container"
          (cdkDropListDropped)="drop($event)">
          <div class="d-flex flex-row align-items-center">
            <div class="actions-tasks-archived">
              <span>{{'team_actions_archived' | translate}}</span>
            </div>
            <span
            style="font-size: 1.2rem; margin: .5rem 0 0 1rem;">({{(challengesService.myArchivedTasks.length > actionsNumber) ? actionsNumber : challengesService.myArchivedTasks.length}})</span>
          </div>
          <div *ngFor="let item of challengesService.myArchivedTasks, let p = index" cdkDrag style="cursor: pointer;">
            <div *ngIf="p < actionsNumber" class="action-task-box">
              <div class="archive-action">
                <span class="action-title">
                  <img style="margin-right: .4rem;" src="../../../assets/svg/action-archive.svg">
                  {{item['teamTask']['title']}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="empty-actions-card-body"
           *ngIf="challengesService.myAssignActions.length === 0">
        <div class="empty-actions-tasks">
          <div class="empty-tasks">
            <span>To do</span>
          </div>
          <div class="action-task-box" *ngFor="let i of [].constructor(2)">
            <div class="d-flex flex-row">
              <i class="bi bi-lightning-charge-fill" style="font-size:1.2rem; color: #C1CBE9; margin-right: .8rem;"></i>
              <p-skeleton width="16rem" height="1.6rem" borderRadius="8px" animation="none"
                          [style]="{'background-color':'#ECEFF8'}"></p-skeleton>
            </div>
            <div class="empty-action-author">
              <p-skeleton shape="circle" width="5rem" height="5rem" animation="none"
                          [style]="{'margin-right':'.8rem', 'background-color':'#ECEFF8'}"></p-skeleton>
              <div class="d-flex flex-column">
                <p-skeleton width="8.9rem" height="1.2rem" borderRadius="8px" animation="none"
                            [style]="{'margin-bottom':'.6rem', 'background-color':'#ECEFF8'}"></p-skeleton>
                <p-skeleton width="7rem" height="1.6rem" borderRadius="8px" animation="none"
                            [style]="{'background-color':'#ECEFF8'}"></p-skeleton>
              </div>
            </div>
          </div>
        </div>
        <div class="empty-actions-tasks">
          <div class="empty-tasks">
            <span>In Progress</span>
          </div>
          <div class="action-task-box" *ngFor="let i of [].constructor(3)">
            <div class="d-flex flex-row">
              <i class="bi bi-lightning-charge-fill" style="font-size:1.2rem; color: #C1CBE9; margin-right: .8rem;"></i>
              <p-skeleton width="16rem" height="1.6rem" borderRadius="8px" animation="none"
                          [style]="{'background-color':'#ECEFF8'}"></p-skeleton>
            </div>
            <div class="empty-action-author">
              <p-skeleton shape="circle" width="5rem" height="5rem" animation="none"
                          [style]="{'margin-right':'.8rem', 'background-color':'#ECEFF8'}"></p-skeleton>
              <div class="d-flex flex-column">
                <p-skeleton width="8.9rem" height="1.2rem" borderRadius="8px" animation="none"
                            [style]="{'margin-bottom':'.6rem', 'background-color':'#ECEFF8'}"></p-skeleton>
                <p-skeleton width="7rem" height="1.6rem" borderRadius="8px" animation="none"
                            [style]="{'background-color':'#ECEFF8'}"></p-skeleton>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ng-template pTemplate="footer">
        <div class="actions-card-footer" *ngIf="challengesService.myAssignActions.length > 0">
          <p-button (click)="goToTeamActions()" label="{{'team_action_see_all'|translate}}" icon="bi bi-arrow-right"
                    iconPos="right"></p-button>
        </div>
      </ng-template>
    </p-card>
  </div>

  <div *ngIf="teamFound && !data.showTeamActions && !data.showTeamAnalyse && !data.showMyActions && hideProgressSpinner"
       class="team-members-container">
    <p-card>
      <div class="team-members-container-header">
        <h3 [style]="{'font-weight' : '500', 'font-size':'2.2rem'}">{{'team_overview'|translate}} {{sortByImmLeaders ? '': '('+teamMembersNumber+')'}}</h3>

        <div *ngIf="!showTeamMembers" class="spinner-border text-light" style="padding: 1rem; margin-left: 1rem;"
             role="status"></div>
        <span class="team-members-response-rate-title">{{'review_response'|translate}}</span>
        <div class="team-members-response-rate">
          <h5 class="team-response-rate-value">{{showReport ? team?.statisticsStatus?.percentage : '0'}}</h5>
          <!--          <h5 class="team-response-rate-value">{{teamResponseRate}}</h5>-->
          <span [style]="{'color':'white', 'font-size':'11px', 'height':'15px', 'margin-bottom':'.6rem'}">%</span>
        </div>
      </div>
      <div *ngIf="!isOnlyOneImmLeader" class="members-sorting-btn-container" style="display: block">
        <h3 [style]="{'font-size':'1.4rem'}">{{'team_sort_by' | translate}}</h3>
        <div>
          <button pButton type="button" label="{{'all'|translate}}" (click)="sortMembersByImmediateLeader(false)"
                    [ngClass]="!sortByImmLeaders ? 'indicator-button-active' : ''">
          </button>
          <button pButton type="button" label="{{'team_sort_by_leader'|translate}}" (click)="sortMembersByImmediateLeader(true)"
                    [ngClass]="sortByImmLeaders ? 'indicator-button-active' : ''">
          </button>
        </div>
      </div>
      <div *ngIf="!sortByImmLeaders" class="team-members-container-body">
<!--        <div [ngClass]="member.activityType === ('team_pending'|translate) ? 'over-team-card': ''" class="team-member-card"-->
        <div [ngClass]="(myTeamRole > 10 ? (member.activityType === ('team_pending'|translate) ? 'team-member-card-leader-long over-team-card-leader': 'team-member-card-leader-long') :
                                             (member.activityType === ('team_pending'|translate) ? 'team-member-card over-team-card-leader': 'team-member-card'))"  [ngStyle]="(myTeamRole > 10 ) ? {'height':'30rem'} : {}"
             *ngFor="let member of teamMembers, let i = index, last as isLast">
          <div
            [ngStyle]="member.teamMember.user.teamRole.teamPriority > 10 ? {'background-color':'#FFEFBF'} : {'background-color':'#E3E9F5'}"
            [ngClass]="(myTeamRole > 10 ) ? 'team-member-card-header-leaders-short' : 'team-member-card-header-leaders'">
            <div class="team-member-card-menu">
              <mat-icon [style]="{'color':'#2551BF'}" [matMenuTriggerFor]="menu" *ngIf="myTeamRole > 10">more_horiz
              </mat-icon>
              <div *ngIf="(myTeamRole === 10)" style="height: 2.5rem !important;"></div>
              <mat-menu #menu="matMenu">
                <button mat-menu-item [disabled]="member.teamMember.user.teamPriority > 10 && teamAdmins.length < 2"
                        (click)="deleteOrChangeMemberOpenModal(member.teamMember, 'change', false)">
                  <span
                    [style]="{'margin':'4px', 'cursor': 'pointer'}">{{(member.teamMember.user.teamRole.teamPriority === 10) ? ('team_appoint_as_leader' | translate) : ('team_remove_as_leader' | translate)}}</span>
                </button>
                <button mat-menu-item (click)="deleteOrChangeMemberOpenModal(member.teamMember, 'delete', false)">
                  <span [style]="{'margin':'4px', 'cursor': 'pointer'}">{{'remove_member'|translate}}</span>
                </button>
              </mat-menu>
            </div>
            <img *ngIf="member.teamMember.user.avatar !== null && member.teamMember.user.avatar.path !== null"
                 class="team-member-avatar"
                 src="{{this.api.baseUrl + member.teamMember.user.avatar.path}}" alt="">
            <img *ngIf="member.teamMember.user.avatar === null" class="team-member-avatar"
                 src="../../../assets/svg/user-icon.svg" alt="">
            <div class="immediate-leader-mini-container-members" *ngIf="(member.teamMember.user.teamRole.teamPriority === 10) && member.immediateLeader != null"
              pTooltip="{{(member.immediateLeader?.detail.displayName | getDisplayName: member.immediateLeader?.detail.firstname: member.immediateLeader?.detail.lastname: member.immediateLeader?.email) + ('immediate_leader' | translate)}}"
              tooltipStyleClass="member-email-tooltip" tooltipPosition="top">
              <img class="immediate-leader-mini-icon" *ngIf="member.immediateLeader != null && member.immediateLeader?.avatar != null" [src]="member.immediateLeader != null ? api.baseUrl + member.immediateLeader.avatar.path : '../../../assets/svg/user-icon.svg'">
              <img class="immediate-leader-mini-icon" *ngIf="member.immediateLeader === null || member.immediateLeader?.avatar == null" [src]="'../../../assets/svg/user-icon.svg'">
              <img style="margin: -1.8rem 0 0 -.2rem; width: 1.2rem;" src="../../../assets/svg/flag-leader.svg">
            </div>
            <div class="reminder-container">
              <span
                class="team-member-avatar-name" pTooltip="{{member.teamMember.user.email}}"
                tooltipStyleClass="member-email-tooltip" tooltipPosition="top">
                {{member.teamMember.user.detail.displayName | getDisplayName: member.teamMember.user.detail.firstname: member.teamMember.user.detail.lastname: member.teamMember.user.email}}</span>
              <i pTooltip="Send an email reminder" tooltipPosition="bottom" tooltipStyleClass="tooltip-style"
                 *ngIf="(member.responsesCount < member.responsesNeeded) && (member.teamMember.user.teamRole.teamPriority === 10) && myTeamRole > 10"
                 class="bi bi-bell reminder-bell"
                 id="{{'bell-button-' + i}}"
                 pTooltip="Send an email reminder"
                 tooltipStyleClass="member-email-tooltip" tooltipPosition="right" (mousedown)="bellAnimation($event, i)"
                 [matMenuTriggerFor]="reminder"></i>
              <mat-menu #reminder="matMenu">
                <button mat-menu-item>
                  <span (click)="sendReminderMember(member.teamMember.uuid)"
                        [style]="{'margin':'4px', 'cursor': 'pointer'}">{{'reminder'|translate}}</span>
                </button>
              </mat-menu>
            </div>
            <span class="team-member-avatar-role">{{member.teamMember.user.teamRole.label}}</span>
          </div>
          <div class="team-member-card-body" *ngIf="myTeamRole > 10">
            <div class="leader-flag-circle" *ngIf="member.teamMember.user.teamRole.teamPriority > 10 && (member.activityType !== ('team_dashboard_member_activity_pending' | translate) && member.activityType !== ('team_dashboard_member_activity_holiday' | translate))">
              <img [ngClass]="(member.activityType === ' ') ? 'team-leader-flag-icon' : ''"
                 src="../../../assets/svg/flag-leader.svg" alt="">
            </div>
            <div class="team-member-activity-container"
                 *ngIf="myTeamRole > 10 && member.responsesNeeded !== 0">
              <span *ngIf="member.teamMember.user.teamRole.teamPriority === 10"
                    [style]="{'font-size':'14px' ,'font-weight':'400', 'margin-top':'10px'}">{{member.responsesCount}}
                / {{member.responsesNeeded}} {{'team_member_responses' | translate}}</span>

            </div>
            <div *ngIf="myTeamRole > 10 && member.activityType != null" [ngStyle]="setMemberActivityColor(member.activityType)"
                 class="team-member-activity">
                <span
                  pTooltip="{{'team_activity_hover'|translate}}"
                  tooltipPosition="bottom" tooltipStyleClass="member-activity-tooltip">
                      {{member.activityType}}
                </span>
            </div>
          </div>
        </div>
        <div (click)="!data.showDemo ? addNewMember() : ''" class="team-add-new-member" [style]="'min-height: 300px'"
             *ngIf="myTeamRole > 10">
          <h5 [style]="{'font-size': '4rem', 'font-weight': '300', 'color':'#BCCFFF'}">+</h5>
        </div>
      </div>
      <div *ngIf="sortByImmLeaders" class="team-members-container-by-leader">
        <div *ngIf="membersWithoutLeader.length" class="team-members-container-body">
          <div [ngClass]="(myTeamRole > 10 ? (member.activityType === ('team_pending'|translate) ? 'team-member-card-leader-long over-team-card-leader': 'team-member-card-leader-long') :
                                             (member.activityType === ('team_pending'|translate) ? 'team-member-card-leader over-team-card-leader': 'team-member-card-leader'))"  [ngStyle]="(myTeamRole > 10 ) ? {'height':'30rem'} : {}"
               *ngFor="let member of membersWithoutLeader, let i = index, last as isLast">
<!--            <div [ngStyle]="false ? {'background-color':'#FFEFBF'} : {'background-color':'#E3E9F5'} && (myTeamRole > 10 ) ? {'padding-bottom':'2rem'} : {}"-->
            <div [ngStyle]="false ? {'background-color':'#FFEFBF'} : {'background-color':'#E3E9F5'}"
                 [ngClass]="(myTeamRole > 10 ) ? 'team-member-card-header-leaders-short' : 'team-member-card-header-leaders'">
              <div class="team-member-card-menu"  style="cursor: default">
                <mat-icon [style]="{'color':'#2551BF', 'visibility': 'hidden', 'cursor': 'default'}" [matMenuTriggerFor]="menu" *ngIf="myTeamRole > 10">more_horiz
                </mat-icon>
                <div *ngIf="(myTeamRole === 10)" style="height: 2.5rem !important;"></div>
                <mat-menu #menu="matMenu">
                  <button mat-menu-item [disabled]="member.member.teamRole?.teamPriority > 10 && teamAdmins.length < 2"
                          (click)="deleteOrChangeMemberOpenModal(member.member, 'change', true)">
                          <span
                            [style]="{'margin':'4px', 'cursor': 'pointer'}">{{(member.member.teamRole?.teamPriority === 10) ? ('team_appoint_as_leader' | translate) : ('team_remove_as_leader' | translate)}}</span>
                  </button>
                  <button mat-menu-item (click)="deleteOrChangeMemberOpenModal(member.member, 'delete', true)">
                    <span [style]="{'margin':'4px', 'cursor': 'pointer'}">{{'remove_member'|translate}}</span>
                  </button>
                </mat-menu>
              </div>
              <div class="iml-icon-container">
                <img class="team-member-avatar" [src]="member.member.avatar != null ? api.baseUrl + member.member.avatar.path : '../../../assets/svg/user-icon.svg'">
                <div class="without-iml-mini-container">
                  <i class="bi bi-question-lg"></i>
                </div>
              </div>
              <div class="reminder-container">
                      <span
                        class="team-member-avatar-name" pTooltip="{{member.member.email}}"
                        tooltipStyleClass="member-email-tooltip" tooltipPosition="top">
                        {{member.member.detail.displayName | getDisplayName: member.member.detail.firstname: member.member.detail.lastname: member.member.email}}</span>
                <i pTooltip="Send an email reminder" tooltipPosition="bottom" tooltipStyleClass="tooltip-style"
                   *ngIf="(member.pollResponses < member.totalPollResponses) && (member.member.teamRole?.teamPriority === 10) && myTeamRole > 10"
                   class="bi bi-bell reminder-bell"
                   id="{{'bell-button-' + i}}"
                   pTooltip="Send an email reminder"
                   tooltipStyleClass="member-email-tooltip" tooltipPosition="right" (mousedown)="bellAnimation($event, i)"
                   [matMenuTriggerFor]="reminder"></i>
                <mat-menu #reminder="matMenu">
                  <button mat-menu-item>
                          <span (click)="sendReminderMember(member.member.uuid)"
                                [style]="{'margin':'4px', 'cursor': 'pointer'}">{{'reminder'|translate}}</span>
                  </button>
                </mat-menu>
              </div>
              <span class="team-member-avatar-role">{{member.member.teamRole.teamPriority > 10 ? ('immediate_leader_team_leader' | translate):('immediate_leader_team_member' | translate)}}</span>
            </div>
            <div class="team-member-card-body" *ngIf="myTeamRole > 10">
              <div class="leader-flag-circle" *ngIf="member.teamMember?.user.teamRole.teamPriority > 10 && (member.activityType !== ('team_dashboard_member_activity_pending' | translate) && member.activityType !== ('team_dashboard_member_activity_holiday' | translate))">
                <img [ngClass]="(member.member.activityType === ' ') ? 'team-leader-flag-icon' : ''"
                   src="../../../assets/svg/flag-leader.svg" alt="">
              </div>
              <div class="team-member-activity-container"
                   *ngIf="myTeamRole > 10 && member.totalPollResponses !== 0">
                      <span *ngIf="member.member.teamRole?.teamPriority === 10"
                            [style]="{'font-size':'14px' ,'font-weight':'400', 'margin-top':'10px'}">{{member.pollResponses}}
                        / {{member.totalPollResponses}} {{'team_member_responses' | translate}}</span>

              </div>
              <div *ngIf="myTeamRole > 10 && member.activityType != null" [ngStyle]="setMemberActivityColor(member.activityType)"
                   class="team-member-activity">
                        <span
                          pTooltip="{{'team_activity_hover'|translate}}"
                          tooltipPosition="bottom" tooltipStyleClass="member-activity-tooltip">
                              {{member.activityType}}
                        </span>
              </div>
            </div>
          </div>
          <div (click)="!data.showDemo ? addNewMember() : ''" class="team-add-new-member" [style]="'height: 20rem'" [ngStyle]="(myTeamRole > 10 ) ? {'height':'30rem'} : {}"
               *ngIf="myTeamRole > 10">
            <h5 [style]="{'font-size': '4rem', 'font-weight': '300', 'color':'#BCCFFF'}">+</h5>
          </div>
        </div>

        <div *ngFor="let immediate of immediateMembers; let x = index; let first = first" class="immediate-leader-accordion" [ngClass]="immediate.immediateLeader.teamRole?.teamPriority > 10 ? 'immediate-leader-team-header':'immediate-leader-member-header'">
          <p-accordion *ngIf="immediate.immediateLeader" collapseIcon="bi bi-chevron-up" expandIcon="bi bi-chevron-down">
            <p-accordionTab [selected]="first">
              <ng-template pTemplate="header">
                <div class="immediate-leader-tab-detail">
                  <div class="immediate-leader-name-container">
                    <span>{{immediate.immediateLeader.detail.displayName ? immediate.immediateLeader.detail.displayName : immediate.immediateLeader.detail.firstname ? immediate.immediateLeader.detail.firstname +' '+ immediate.immediateLeader.detail.lastname : immediate.immediateLeader.email}}</span>
                    <img *ngIf="immediate.immediateLeader.teamRole?.teamPriority > 10" style="padding-left: 6px; margin-bottom: .4rem; width: 2.2rem;" src="../../../assets/svg/flag-leader.svg">
                  </div>
                  <span style="font-size: 1.2rem; font-weight: 400;">{{(immediate.immediateLeader.teamRole !== null) ? immediate.immediateLeader.teamRole?.label : ('team_leader_outside' |translate)}}</span>
                </div>
                <img class="immediate-leader-tab-icon" [src]="immediate.immediateLeader.avatar != null ? api.baseUrl + immediate.immediateLeader.avatar.path : '../../../assets/svg/user-icon.svg'">
              </ng-template>
              <ng-template pTemplate="content">
                <div [ngClass]="(myTeamRole > 10 ? (member.activityType === ('team_pending'|translate) ? 'team-member-card-leader-long over-team-card-leader': 'team-member-card-leader-long') :
                  (member.activityType === ('team_pending'|translate) ? 'team-member-card-leader over-team-card-leader': 'team-member-card-leader'))"  [ngStyle]="(myTeamRole > 10 ) ? {'height':'30rem'} : {}"                  
                  *ngFor="let member of immediate.members, let i = index, last as isLast">
                  <div [ngStyle]="{'background-color':'#E3E9F5'}"
                       [ngClass]="(myTeamRole > 10 ) ? 'team-member-card-header-leaders-short' : 'team-member-card-header-leaders'">
                    <div class="team-member-card-menu" style="cursor: default">
                      <div *ngIf="myTeamRole === 10" style="height: 2.5rem !important;"></div>
                      <mat-icon [style]="{'color':'#2551BF', 'visibility': 'hidden', 'cursor': 'default'}" [matMenuTriggerFor]="menu" *ngIf="myTeamRole > 10">more_horiz
                      </mat-icon>
                      <mat-menu #menu="matMenu">
                        <button mat-menu-item [disabled]="member.member.teamRole?.teamPriority > 10 && teamAdmins.length < 2"
                          (click)="deleteOrChangeMemberOpenModal(member.member, 'change', true)">
                          <span
                            [style]="{'margin':'4px', 'cursor': 'pointer'}">{{(member.member.teamRole?.teamPriority === 10) ? ('team_appoint_as_leader' | translate) : ('team_remove_as_leader' | translate)}}</span>
                        </button>
                        <button mat-menu-item (click)="deleteOrChangeMemberOpenModal(member.member, 'delete', true)">
                          <span [style]="{'margin':'4px', 'cursor': 'pointer'}">{{'remove_member'|translate}}</span>
                        </button>
                      </mat-menu>
                    </div>
                    <div class="iml-icon-container">
                      <img class="team-member-avatar" [src]="member.member.avatar != null ? api.baseUrl + member.member.avatar.path : '../../../assets/svg/user-icon.svg'">
                      <div class="immediate-leader-mini-container" pTooltip="{{(member.immediateLeader?.detail.displayName | getDisplayName: member.immediateLeader?.detail.firstname: member.immediateLeader?.detail.lastname: member.immediateLeader?.email) + ('immediate_leader' | translate)}}"
                        tooltipStyleClass="member-email-tooltip" tooltipPosition="top">
                        <img class="immediate-leader-mini-icon" [src]="immediate.immediateLeader.avatar != null ? api.baseUrl + immediate.immediateLeader.avatar.path : '../../../assets/svg/user-icon.svg'">
                        <img style="margin: -1.8rem 0 0 -.2rem; width: 1.2rem;" src="../../../assets/svg/flag-leader.svg">
                      </div>
                    </div>
                    <div class="reminder-container">
                      <span
                        class="team-member-avatar-name" pTooltip="{{member.member.email}}"
                        tooltipStyleClass="member-email-tooltip" tooltipPosition="top">
                        {{member.member.detail.displayName | getDisplayName: member.member.detail.firstname: member.member.detail.lastname: member.member.email}}</span>
                      <i pTooltip="Send an email reminder" tooltipPosition="bottom" tooltipStyleClass="tooltip-style"
                        *ngIf="(member.pollResponses < member.totalPollResponses) && (member.member.teamRole?.teamPriority === 10) && myTeamRole > 10"
                        class="bi bi-bell reminder-bell"
                        id="{{'bell-button-' + i}}"
                        pTooltip="Send an email reminder"
                        tooltipStyleClass="member-email-tooltip" tooltipPosition="right" (mousedown)="bellAnimation($event, i)"
                        [matMenuTriggerFor]="reminder"></i>
                      <mat-menu #reminder="matMenu">
                        <button mat-menu-item>
                          <span (click)="sendReminderMember(member.member.uuid)"
                                [style]="{'margin':'4px', 'cursor': 'pointer'}">{{'reminder'|translate}}</span>
                        </button>
                      </mat-menu>
                    </div>
                    <span class="team-member-avatar-role">{{member.member.teamRole.teamPriority > 10 ? ('immediate_leader_team_leader' | translate):('immediate_leader_team_member' | translate)}}</span>
                  </div>
                  <div class="team-member-card-body" *ngIf="myTeamRole > 10">
                    <div class="leader-flag-circle" *ngIf="member.teamRole?.teamPriority > 10 && (member.activityType !== ('team_dashboard_member_activity_pending' | translate) && member.activityType !== ('team_dashboard_member_activity_holiday' | translate))">
                      <img [ngClass]="(member.member.activityType === ' ') ? 'team-leader-flag-icon' : ''"
                         src="../../../assets/svg/flag-leader.svg" alt="">
                    </div>
                    <div class="team-member-activity-container"
                        *ngIf="myTeamRole > 10 && member.totalPollResponses !== 0">
                      <span *ngIf="member.member.teamRole?.teamPriority === 10"
                            [style]="{'font-size':'14px' ,'font-weight':'400', 'margin-top':'10px'}">{{member.pollResponses}}
                        / {{member.totalPollResponses}} {{'team_member_responses' | translate}}</span>

                    </div>
                    <div *ngIf="myTeamRole > 10 && member.activityType != null" [ngStyle]="setMemberActivityColor(member.activityType)"
                        class="team-member-activity">
                        <span
                          pTooltip="{{'team_activity_hover'|translate}}"
                          tooltipPosition="bottom" tooltipStyleClass="member-activity-tooltip">
                              {{member.activityType}}
                        </span>
                    </div>
                  </div>
                </div>
              </ng-template>
            </p-accordionTab>
          </p-accordion>
        </div>
      </div>
    </p-card>
  </div>
</div>

<!-- Show action modal -->

<button type="button" id="actionModalButton" class="btn btn-primary" data-bs-toggle="modal" hidden
        data-bs-target="#actionModal">
</button>

<div class="modal fade" id="actionModal" data-bs-keyboard="false" tabindex="-1"
     aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div [ngClass]="showModalWithStaments ? 'modal-lg':''" class="modal-dialog modal-lg modal-dialog-centered">
    <div class="modal-content action-box">
      <div class="modal-header action-header" *ngIf="showModal">
        <div class="action-header-title">
          <h3 class="modal-title" id="staticBackdropLabel">
            <img style="margin-right: .4rem;" [src]="'../../../assets/svg/action-' + (!showModalWithStaments ? actionModal?.frequency > 0 ? 'active.svg':'inactive.svg' : 'active.svg')">
            {{!showModalWithStaments ? actionModal?.task['title'] : actionModal['title']}}</h3>
          <img src="assets/svg/close-button.svg" alt="X" data-bs-dismiss="modal" aria-label="Close"
               id="closeActioneModal" class="action-header-close-btn">
        </div>
        <p style="white-space: pre-wrap;" id="description"
           [innerHTML]="!showModalWithStaments ? actionModal.task['displayDescription'] : actionModal['displayDescription']"></p>
      </div>
      <div *ngIf="showModalWithStaments" class="action-author-container" style="margin: 1rem 3rem 1rem 3.7rem;">
        <img
          *ngIf="actionModal['author']['avatar'] !== null && actionModal['author']['avatar']['path'] !== null"
          class="action-user-avatar"
          src="{{baseUrl + actionModal['author']['avatar']['path']}}">
        <img style="width: 50px" *ngIf="actionModal['author']['avatar'] === null"
             src="../../../assets/svg/user-icon.svg" alt="">
        <div class="action-author-name">
          <span [style]="{'font-size' : '11px'}">{{'team_action_assigned_by'|translate}}</span>
          <!--            <span>{{actionModal['author']['detail']['displayName']}}</span>-->
          <span>{{ actionModal['author']['detail']['displayName'] | getDisplayName: actionModal['author']['detail']['firstname'] : actionModal['author']['detail']['lastname']: actionModal['author']['email']}}</span>
        </div>
        <div class="action-status">
          <span style="font-size: 1.2rem;margin-right: .6rem;">
                {{actionModal.status === 'to_do' ? ('insights_action_picked' | translate):
            actionModal.status === 'in_progress' ? ('insights_action_started' | translate):
              actionModal.status === 'done' ? ('insights_action_completed' | translate):
                actionModal.status === 'archived' ? ('insights_action_archived' | translate): ''}}
          </span>
          <span
            style="font-size: 1.2rem; font-weight: bold;">{{(actionModal.lastTaskHistory === null ? actionModal.created.date : actionModal.lastTaskHistory.created.date) | date: 'dd.MM.yyyy'}}</span>
            <div style="margin:0 0 0 1.4rem" class="action-status-dropdown" [ngClass]="selectedActionStatus?.type === 'to_do' ? 'dropdown-todo' :
              selectedActionStatus?.type === 'in_progress' ? 'dropdown-progress':
              selectedActionStatus?.type === 'done' ? 'dropdown-done' :
              selectedActionStatus?.type === 'archived' ? 'dropdown-archived':''">
              <p-dropdown [styleClass]="selectedActionStatus === 'in_progress' ? 'status-in-progress':''" [options]="actionsStatus" [(ngModel)]="selectedActionStatus" optionLabel="name" placeholder="{{('team_actions_'+ selectedActionStatus?.type | translate)}}" dropdownIcon="bi bi-chevron-down">
                <!-- <ng-template pTemplate="selectedItem">
                    <div *ngIf="selectedActionStatus">

                    </div>
                </ng-template> -->
                <ng-template let-status pTemplate="item">
                  <div class="status-container" (click)="changeAction(status, actionModal)">
                    <span>{{status.title}}</span>
                    <div [style]="{'background-color':status.color}"class="status-circle-color"></div>
                  </div>
                </ng-template>
            </p-dropdown>
          </div>
        </div>
      </div>
      <div [ngClass]="showModalWithStaments ? 'action-with-statements-body': 'action-body'" class="modal-body">
        <div *ngIf="!showModalWithStaments" class="solutions-actions">
          <div class="solutions-actions-footer">
            <button *ngIf="data.teamLeaders.length === 1" pButton type="button" label="{{'assign_to_me'|translate}}"
                    (click)="!data.showDemo ? assignToMe(actionModal, statementModal, data.teamLeaders[0]):''"
                    class="assign-to-button">
            </button>
            <p-dropdown *ngIf="data.teamLeaders.length > 1" [options]="data.teamLeaders" [(ngModel)]="selectedTLFromActionModal" class="team-type-dropdown" optionLabel="name" placeholder="Assign to" dropdownIcon="bi bi-chevron-down">
              <ng-template let-selectedLeader pTemplate="selectedItem">
                  <div class="sort-item sort-item-value" *ngIf="selectedTLFromActionModal">
                    <span>{{ selectedLeader.user.detail.displayName | getDisplayName: selectedLeader.user.detail.firstname: selectedLeader.user.detail.lastname: selectedLeader.user.email }}</span>
                    <img class="action-assigned-user-icon"
                      src="{{(selectedLeader.user.avatar !== null && selectedLeader.user.avatar.path !== null) ? this.data.baseUrl + selectedLeader.user.avatar.path: '../../../assets/svg/user-icon.svg'}}">
                    </div>
              </ng-template>
              <ng-template let-leader pTemplate="item">
                <div (click)="!data.showDemo ? assignAction(actionModal, statementModal, leader):''">
                  <span>{{ leader.user.detail.displayName | getDisplayName: leader.user.detail.firstname: leader.user.detail.lastname: leader.user.email }}</span>
                  <img class="action-assigned-user-icon"
                    src="{{(leader.user.avatar !== null && leader.user.avatar.path !== null) ? this.data.baseUrl + leader.user.avatar.path: '../../../assets/svg/user-icon.svg'}}">
                </div>
              </ng-template>
            </p-dropdown>
            <span (click)="notRelevant(actionModal.task, statementModal.question)" style="margin-left: auto; color: #2551BF;"
              class="solution-not-relevant">
              {{'not_relevant'|translate}}</span>
          </div>
        </div>
        <div *ngIf="showModalWithStaments">
          <span
            class="modal-body-title">{{taskStatements.length > 0 ? ('insights_action_linked_to' | translate) : ('insights_action_no_statements' | translate)}}</span>
          <div class="modal-statements" *ngFor="let task of taskStatements">
            <img class="modal-statements-icon" src="../../../assets/svg/statement-icon-red.svg">
            <span class="statement-title">{{task.title}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Make own action modal -->
<button type="button" id="ownActionButton" class="btn btn-primary" data-bs-toggle="modal" hidden
        data-bs-target="#ownActionModal">
</button>

<div class="modal fade" id="ownActionModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
     aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div style="background-color: #F0F3FA;" class="modal-content action-box">
      <div class="modal-header action-header">
        <div class="own-action-header-title">
          <h3 class="own-action-modal-title">{{'create_own_action' | translate}}</h3>
          <img src="assets/svg/close-button.svg" alt="X" data-bs-dismiss="modal" aria-label="Close"
               (click)="closeCreateAction()"
               id="closeActionModal" style="margin: 0 0 3rem 0; cursor: pointer">
        </div>
        <div [ngStyle]="{'background-color': ownActionStatement?.color.hex}" class="own-statement-item">
          <i style="margin-left: 1.6rem;color:white" class="bi bi-chat-fill statement-icon"></i>
          <h3 class="own-statement-question-title">{{ownActionStatement?.question.title}}</h3>
        </div>
        <div class="own-action-form-container">
          <form [formGroup]="challengesService.createActionForm">
            <div class="own-task-form">
              <div
                style="display: flex; border-radius: 1.4rem; background-color: white">
                <img style="margin: 0 .2rem .4rem 2rem;" src="../../../assets/svg/action-active.svg">
                <!-- <i style="color:#2551BF; font-size:1.8rem; margin: .8rem 0 0 2rem; padding-top: .8rem"
                   class="bi bi-lightning-charge-fill"></i> -->
                <input class="task-title-input" formControlName="actionTitle" id="actionTitleModal"
                       style="font-size: 1.8rem; color: #2551BF; margin-top: .8rem; width: -webkit-fill-available;"
                       placeholder="{{'easy_title' | translate}}">
              </div>
              <textarea style="font-size: 1.2rem; color: #2551BF;margin:0 0 0 2rem; border: none"
                        class="task-description-input" formControlName="actionDescription"
                        placeholder="{{'describe_solution' | translate}}" type="text"></textarea>
            </div>
          </form>
        </div>
      </div>
      <div class="modal-body action-body">
        <div style="width: 50%; margin: 0 0 1rem 2rem" class="solutions-actions justify-content-start">
          <!-- <button pButton type="button"
                  (click)="addNewAction(ownActionStatement, null)"
                  label="{{'create_button' | translate}}"
                  class="own-action-create-button"></button> -->
          <div *ngIf="data.teamLeaders.length === 1">
            <button *ngFor="let member of data.teamLeaders" pButton type="button"
                    (click)="addNewAction(ownActionStatement, member)"
                    class="assign-to-modal-button" label="{{'assign_to_me' | translate}}">
              <!-- <div tooltipPosition="bottom" tooltipStyleClass="tooltip-style"
                   pTooltip="{{ member.user.detail.displayName | getDisplayName: member.user.detail.firstname: member.user.detail.lastname: member.user.email }}">
                <img class="solution-assign-to-avatar"
                     src="{{(member.user.avatar !== null && member.user.avatar.path !== null) ? this.data.baseUrl + member.user.avatar.path: '../../../assets/svg/user-icon.svg'}}"
                     alt=""/>
              </div> -->
            </button>
          </div>
          <p-dropdown *ngIf="data.teamLeaders.length > 1" [options]="data.teamLeaders" [(ngModel)]="selectedTeamLeaderForAction" class="team-type-dropdown" optionLabel="name" placeholder="Assign to" dropdownIcon="bi bi-chevron-down">
            <ng-template let-selectedLeader pTemplate="selectedItem">
                <div class="sort-item sort-item-value" *ngIf="selectedTeamLeaderForAction">
                  <span>{{ selectedLeader.user.detail.displayName | getDisplayName: selectedLeader.user.detail.firstname: selectedLeader.user.detail.lastname: selectedLeader.user.email }}</span>
                  <img class="action-assigned-user-icon"
                    src="{{(selectedLeader.user.avatar !== null && selectedLeader.user.avatar.path !== null) ? this.data.baseUrl + selectedLeader.user.avatar.path: '../../../assets/svg/user-icon.svg'}}">
                  </div>
            </ng-template>
            <ng-template let-leader pTemplate="item">
              <div (click)="!data.showDemo ? addNewAction(ownActionStatement, leader):''">
                <span>{{ leader.user.detail.displayName | getDisplayName: leader.user.detail.firstname: leader.user.detail.lastname: leader.user.email }}</span>
                <img class="action-assigned-user-icon"
                  src="{{(leader.user.avatar !== null && leader.user.avatar.path !== null) ? this.data.baseUrl + leader.user.avatar.path: '../../../assets/svg/user-icon.svg'}}">
              </div>
            </ng-template>
          </p-dropdown>
          <!-- <button *ngIf="data.teamLeaders.length > 1" pButton type="button" [matMenuTriggerFor]="teamsMenu"
                  label="{{'assign_others' | translate}}"
                  class="assign-to-modal-button"></button>
          <mat-menu #teamsMenu xPosition="before">
            <button mat-menu-item *ngFor="let member of data.teamLeaders">
              <img class="user-avatar"
                   src="{{(member.user.avatar !== null && member.user.avatar.path !== null) ? this.data.baseUrl + member.user.avatar.path: '../../../assets/svg/user-icon.svg'}}"
                   alt="">

              <span
                class="teams-menu-item">{{ member.user.detail.displayName | getDisplayName: member.user.detail.firstname: member.user.detail.lastname: member.user.email }}</span>
            </button>
          </mat-menu> -->
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Team dashboard statements modal -->

<button type="button" id="statementsModal" class="btn btn-primary" data-bs-toggle="modal" hidden
        data-bs-target="#statementsBackdrop">
</button>

<div class="modal fade" id="statementsBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
     aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="statements-modal-title" id="staticBackdropLabel">{{'team_statements_modal_title'|translate}}</h3>
        <img src="assets/svg/close-button.svg" alt="X" data-bs-dismiss="modal" aria-label="Close"
             style="margin: 0 0 3rem 0; cursor: pointer">
      </div>
      <div class="statements-modal-body">
        <div class="loading-bubbles" *ngIf="currentStatements.length===0">
          <span class="spinner-grow text-primary" role="status"></span>
          <span class="spinner-grow text-primary" role="status"></span>
          <span class="spinner-grow text-primary" role="status"></span>
        </div>
        <div class="statement-cell" *ngFor="let statement of currentStatements">
          <div class="statement-cell-icon"><i style="color: white" class="bi bi-chat-fill statement-icon"></i></div>
          <span style="font-size: 1.6rem">{{statement.question.title}}</span>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Team dashboard change and delete member modal -->


<button type="button" id="deleteOrChangeMemberButton1" class="btn btn-primary" data-bs-toggle="modal" hidden
        data-bs-target="#deleteOrChangeMember1">
</button>
<div class="modal fade" id="deleteOrChangeMember1" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content member-changes-box">
      <div class="modal-header member-changes-header">
        <div class="member-changes-title">
          <h3 class="modal-title" id="staticBackdropLabel">{{'team_confirmation_needed' | translate}}</h3>
          <img src="assets/svg/close-button.svg" alt="X" data-bs-dismiss="modal" aria-label="Close"
               id="closeDeleteOrChangeMemberModal1" style="margin: 0 0 2rem auto; cursor: pointer">
        </div>
        <p id="description">
          {{teamMemberChanges.action === 'delete' ? ('team_remove_member' | translate) + (teamMemberChanges.fromIml ? teamMemberChanges.details.detail.displayName : teamMemberChanges.details.user.detail.displayName) + ' from team ' + teamName + ' ?' :
          teamMemberChanges.action === 'change' && (teamMemberChanges.fromIml ? teamMemberChanges.details.teamRole.name : teamMemberChanges.details.user.teamRole.name) === 'team-member' ?
          ('team_leader_role' | translate) + (teamMemberChanges.fromIml ? teamMemberChanges.details.detail.displayName : teamMemberChanges.details.user.detail.displayName) + '?' :
            teamMemberChanges.action === 'change' && (teamMemberChanges.fromIml ? teamMemberChanges.details.teamRole.name : teamMemberChanges.details.user.teamRole.name) === 'team-admin' ? ('team_member_role' | translate) + (teamMemberChanges.fromIml ? teamMemberChanges.details.detail.displayName : teamMemberChanges.details.user.detail.displayName) + '?' : ''}}
        </p>
      </div>
      <div class="modal-body member-changes-body">
        <div class="member-changes-actions">
          <button
            (click)="teamMemberChanges.action === 'delete' ? deleteMember(teamMemberChanges.details) : changeMemberRoles(teamMemberChanges.details)"
            label="{{teamMemberChanges.action === 'delete' ? 'Yes' : 'Yes'}}"
            pButton type="button"
            [ngClass]="teamMemberChanges.action === 'delete' ? 'member-workspace-delete-button' : 'member-changes-appoint-button'"></button>
          <button *ngIf="teamMemberChanges.action === 'delete'"
                  (click)="teamMemberChanges.action === 'delete' ? deleteWorspaceMember(teamMemberChanges.details) : changeMemberRoles(teamMemberChanges.details)"
                  label="{{teamMemberChanges.action === 'delete' ? 'Remove them from workspace' : 'Yes'}}"
                  pButton type="button"
                  [ngClass]="teamMemberChanges.action === 'delete' ? 'member-changes-delete-button' : 'member-changes-appoint-button'"></button>

          <button (click)="deleteOrChangeMemberCloseModal()"
                  label="Cancel"
                  pButton type="button"
                  [ngClass]="teamMemberChanges.action === 'delete' ? 'member-changes-cancel-button' : 'member-changes-cancel-button appoint'"></button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- DOWNLOAD CHART REPORT -->
<button type="button" id="downloadReport" class="btn btn-primary" data-bs-toggle="modal" hidden
        data-bs-target="#downloadReportModal">
</button>

<div class="modal fade" id="downloadReportModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
     aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h3 style="margin-left: 2rem">Export your team scores for a selected period</h3>
        <img src="assets/svg/close-button.svg" alt="X" data-bs-dismiss="modal" aria-label="Close"
             id="closeDownloadReportModal" style="margin: 0 0 2rem auto; cursor: pointer">
      </div>
      <a [href]="reportURL" target="_blank" id="exportButtonDownload"></a>
      <div class="modal-body download-report-body">
        <p style="padding: 3rem 8rem; font-size: 1.6rem">Select the period you want to see your team scores for and
          download the data in an Excel report.</p>
        <!--        <p-calendar [(ngModel)]="dates" selectionMode="range" [inline]="true" [numberOfMonths]="2"-->
        <!--                    [readonlyInput]="true" inputId="multiple"></p-calendar>-->
        <div style="padding: 3rem; width: 100%; display: flex; justify-content: space-evenly;">
          <div>
            <p>Start Date</p>
            <p-calendar [(ngModel)]="startDate" [showOnFocus]="true" id="startDatePiker" dateFormat="dd.mm.yy"
                        [showIcon]="true" inputId="icon" icon="bi bi-calendar-plus"></p-calendar>
          </div>
          <div>
            <p>End Date</p>
            <p-calendar [(ngModel)]="endDate" [showIcon]="true" dateFormat="dd.mm.yy" inputId="icon"
                        icon="bi bi-calendar-plus"></p-calendar>
          </div>
        </div>

        <div style="padding: 3rem; width: 100%;">
          <div style="float: right">
            <button
              (click)="(workspace?.workspaceSubscription.workspacePlan.requirePayment === 'yes' && myTeamRole > 10)? getModalDate(): ''"
              [pTooltip]="workspace?.workspaceSubscription.workspacePlan.requirePayment !== 'yes'?'Upgrade your plan to be able to use this feature':''"
              tooltipPosition="bottom" tooltipStyleClass="tooltip-style"
              label="Confirm period and download"
              pButton type="button"
              class="btn-blue add-date-button"></button>
            <button class="btn-hallow" data-bs-dismiss="modal" style="font-size: 1.2rem" aria-label="Close">Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
