import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {DataManipulationService} from "../../services/data-manipulation.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Observable, Subject, Subscription} from "rxjs";
import {ApiwrapperService} from "../../services/apiwrapper.service";
import {ChallengesService} from "../../services/challenges.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {LabelType, Options} from "@angular-slider/ngx-slider";
import {environment} from "../../../environments/environment";
import {formatDate} from "@angular/common";
import {EChartsOption} from "echarts";
import {moveItemInArray, transferArrayItem} from "@angular/cdk/drag-drop";
import {ModalsComponent} from "../../components/modals/modals.component";
import demo from "../../../assets/data/demo.json"
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-teams',
  templateUrl: './teams.component.html',
  styleUrls: ['./teams.component.scss']
})
export class TeamsComponent implements OnInit, OnDestroy {
  pollingExist = false;
  showPolling = false;
  private teamTasksType: any;
  showError = false;
  polling: any;
  collectorState = '';
  user: any;

  public categories = [];
  public toDoActions = [];
  public inProgressActions = [];
  public doneActions = [];
  showCalendar: boolean = false;

  visible: boolean = false;

  public team: {
    uuid: string | undefined;
    statisticsStatus: any;
    indicatorVisibility: string;
    pollLeaderAssign: string;
  } | any;
  showTeam = false;
  myTeamPoll = [];
  myTeamPollShow = false;
  teamPoll = [];
  teamPollShow = true;
  private questionNumber = 0;
  public teamResponseRate = 0;
  questionAnswer: any;
  questionForm = new FormGroup({
    answer: new FormControl(' ', [
      Validators.required,
      Validators.minLength(1)]),
  });
  //@ts-ignore
  answers;
  options: Options = {
    showTicksValues: false,
    showTicks: true,
    stepsArray: [],
    //@ts-ignore
    translate: (value: number, label: LabelType): string => {
      const lastElement: number = this.answers ? this.answers.length - 1 : 0;

      if (this.answers) {
        switch (label) {
          case LabelType.Floor:
            return this.answers[0].title;
          case LabelType.Ceil:
            return this.answers[lastElement].title;
          default:
            return this.answers[value].title;
        }
      }
    }
  };
  public teamMembers: any = [];
  public teamAdmins = [];
  public teamTasks = [];
  public teamReport = [];
  public showReport = false;
  public showTeamMembers = false;
  public teamName: any;
  private teamSubscription: any;
  private routerSubscription: any;

  showTeamTasks = false;
  test = false;
  //@ts-ignore
  private refreshPolls: boolean;
  //@ts-ignore
  private refreshPollsSubscription: Subscription;
  //@ts-ignore
  private refreshTeamPolls: boolean;
  //@ts-ignore
  private refreshTeamPollsSubscription: Subscription;
  private menuTeamPoll: any;
  private teamPollDone: any;
  currentTeamUuid: any = '';
  teamFound = false;
  private showTeamTaskLoading = false;
  //@ts-ignore
  private refreshTeeamMembersSubscription: Subscription;
  private actionsAdded: Subscription | undefined;
  private addedMember: Subscription | undefined;
  private refreshTeamMembers: any;
  //@ts-ignore
  private getUserSubscription: Subscription;
  //@ts-ignore
  private prioritySubscription: Subscription;
  private analyzeToChallenges: Subscription | undefined;
  private collectorChanges: Subscription | undefined;
  private addedNewAction: Subscription | undefined;
  //@ts-ignore
  private userPriorities;
   ses = [];
  teamRoles = [];
  myTeamRole: any = 0;
  labels = [];
  baseUrl = environment.baseUrl;
  indicator = {
    successteamScore: '../../assets/svg/overall-white.svg',
    successteamScoreChart: '../../assets/svg/overall-lightblue.svg',
    performance: '../../assets/svg/performance-white.svg',
    performanceChart: '../../assets/svg/performance-icon-lightblue.svg',
    motivation: '../../assets/svg/motivation-white.svg',
    motivationChart: '../../assets/svg/motivation-icon-lightblue.svg',
    satisfaction: '../../assets/svg/satisfaction-white.svg',
    satisfactionChart: '../../assets/svg/satisfaction-icon-lightblue.svg',
    stress: '../../assets/svg/stress-white.svg',
    stressChart: '../../assets/svg/stress-icon-lightblue.svg'
  };

  worstColors = {
    performance: '../../assets/svg/performance-white.svg',
    motivation: '../../assets/svg/motivation-white.svg',
    satisfaction: '../../assets/svg/satisfaction-white.svg',
    stress: '../../assets/svg/stress-white.svg'
  };

  indicatorBackground = {
    performance: this.data.colors.grey,
    motivation: this.data.colors.grey,
    satisfaction: this.data.colors.grey,
    stress: this.data.colors.grey,
  };

  indicatorPercentage = {
    performance: 0,
    motivation: 0,
    satisfaction: 0,
    stress: 0,
  };

  //@ts-ignore
  private sevenDays: { next: string; today: string };
  memberTimeSelector = 'last 7 days';
  statementTimeSelector = 'last 7 days';

  //@ts-ignore
  frequency: string;
  showEditCollector = false;
  pollButtonSpinner = false;
  teamTasksPages = 1;
  successScore = 0;
  knobProgress = 0;
  actionsNumber = 3;
  showScore = false;
  scoreColorArrow = this.data.colors.grey;
  colorOfArrow = this.data.colors.grey;
  scoreColor = 'success';
  scoreColorInterval = {
    red: {min: 0, max: 0},
    orange: {min: 0, max: 0},
    yellow: {min: 0, max: 0},
    green: {min: 0, max: 0},
  };
  gotNewChallenges = false;
  disableSetup = false;

  public chartData = {
    successteamScore: [],
    motivation: [],
    performance: [],
    satisfaction: [],
    stress: [],
    responseRate: [],
  };
  chartOption: EChartsOption = {
    xAxis: {
      type: 'category',
      axisLabel: {
        color: '#000000',
        fontWeight: 400,
      },
      data: [],
    },
    yAxis: {
      type: 'value',
      max: 100,
      min: 0,
      axisLabel: {
        color: '#000000',
        fontWeight: 400,
      },
    },
    tooltip: {
      trigger: 'axis'
    },
    series: [
      {
        name: 'Success Score',
        label: {
          // @ts-ignore
          normal: {
            show: false,
            position: 'top'
          },
        },
        data: [],
        type: 'line',
        symbolSize: 10,
        smooth: true,
        color: '#19AEFF',
        // emphasis: {
        //   focus: 'series'
        // },
        tooltip: {
          trigger: 'item',
        },
      },
      {
        name: 'Success Score',
        label: {
          // @ts-ignore
          normal: {
            show: false,
            position: 'top'
          },
        },
        data: [],
        type: 'line',
        smooth: true,
        tooltip: {
          trigger: 'item',
        },
        color: '#19AEFF',
        lineStyle: {
          width: 2,
          type: 'dashed'
        },
        // emphasis: {
        //   focus: 'series'
        // },
      },
      {
        //@ts-ignore
        name: JSON.parse(localStorage.getItem('userDetails')).detail.preferredLanguage.tag === 'en_US' ? 'Response Rate':'Svarprocent',
        label: {
          // @ts-ignore
          normal: {
            show: false,
            position: 'top'
          },
        },
        data: [],
        tooltip: {
          trigger: 'axis',
        },
        type: 'line',
        symbolSize: 0,
        lineStyle: {
          color: '#FF764C',
          width: 2,
        },
        smooth: true,
        color: '#FF764C',
        // emphasis: {
        //   focus: 'series'
        // },
      },
    ],
  };

  chartResponseRateChecked: boolean = true;
  chartType: string = '';
  teamWorkspaceOptions = [];
  selectedOption: string = '';
  showChart = true;
  chartTitle: string = '';
  arrowPointing = 'middle';
  currentPlan: any;
  worstStatemstLastPoll: any;
  worstStatements: any[] = [];
  eligiblePollSelected: any;
  eligiblePolls = [];
  private currentQuestion: any;
  teamMembersNumber = 0;
  actionModal: any = {};
  statementModal: any = {};
  showModal: boolean = false;
  showModalWithStaments: boolean = false;
  taskStatements: any = [];
  teamMemberChanges: { details: any, action: string, fromIml: any } = {details: null, action: '', fromIml: null};
  currentPolling: any = {};
  nextPoll = 0;
  successScoreProgress: any;
  worstLoop = true;
  currentStatements: any[] = [];
  private chartResponseRateData: any;
  private chartResponseRateDataLine: any;
  public workspace: any;
  startDate: Date = new Date();
  endDate: Date = new Date();
  public downloadReport: boolean = false;
  public reportURL: string = '';
  showDummyIndicators: boolean = false;
  ownActionStatement: any;
  teamMemberPolingDetails : any;
  showTeamMemberPolingStatus: boolean = false;
  immediateMembers: any = {};
  language = '';
  sortByImmLeaders: boolean = false;
  membersWithoutLeader: any;
  gotTeamColors: boolean = false;
  selectedTeamLeaderForAction: any;
  selectedTLFromActionModal: any;
  selectedActionStatus: any;
  showArchiveActions: boolean = false;
  actionsStatus : {title: string, type: string, color: string}[] = [
    {title: 'To Do', type: 'to_do', color: '#F7F9FF'},
    {title: 'In Progress', type :'in_progress', color: '#E6D7F5'},
    {title: 'Done', type :'done', color : '#B9E8D9'},
    {title: 'Archive', type :'archived', color: '#E2E2E2'},
  ];
  previousLoopsDates: any;
  selectedPreviousPoll: any;
  showPreviousLoopDd: boolean = false;
  previousLoopIndexPage: number = 0;
  currentLoopIndexPage: number = 0;
  refreshActions = true;
  private memberPrio: any;
  responseDistribution: any;
  hideProgressSpinner: boolean = false;
  gotImmediateLeaders : boolean = false;
  isOnlyOneImmLeader: boolean = false;
  worstStatementsCarouselPage: number = 0;
  pollUuidForResponseDistribution: any;
  pollDatesForResponseDistribution: any;

  @HostListener('document:click', ['$event']) onDocumentClick() {
    this.showPreviousLoopDd = false;
  }

  constructor(private activeRoute: ActivatedRoute, public data: DataManipulationService, public router: Router,
              public challengesService: ChallengesService, private cdRef: ChangeDetectorRef, public modal: ModalsComponent,
              public api: ApiwrapperService, public translate: TranslateService) {
    if (this.activeRoute.snapshot.paramMap.get('team')) {
      this.currentTeamUuid = this.activeRoute.snapshot.paramMap.get('team');
    }
    // @ts-ignore
    this.language = JSON.parse(localStorage.getItem('userDetails')).detail.preferredLanguage.tag;
    // @ts-ignore
    this.workspace = JSON.parse(localStorage.getItem('WORKSPACE'));
    this.teamFound = false;
    this.data.getTeamRoles();
    // @ts-ignore
    localStorage.setItem('headerText', this.currentTeamUuid);
    //DEMO DEMO ***
    if (data.showDemo) {
      // team part
      this.team = demo.team.team;
      this.teamName = demo.team.team.name;
      this.scoreColorInterval = {
        "red": {"min": 0, "max": 39},
        "orange": {"min": 40, "max": 59},
        "yellow": {"min": 60, "max": 79},
        "green": {"min": 80, "max": 100}
      };
      this.nextPoll = 6;
      this.myTeamRole = 40;
      this.teamFound = true;
      this.teamPollShow = true;
      this.myTeamPollShow = false;
      this.showTeamMembers = false;
      this.showTeamTasks = false;
      this.hideProgressSpinner = true;
      // pooling
      this.processTeamPolling(demo.pooling);
      //indicators
      this.processReport(demo.statistics);
      //chart
      this.processGraph(demo.chart);
      //critical statements
      this.processWorstStatements(demo.critical_question);
      //user actions
      this.challengesService.getMyActions(demo.actions, demo.team.team.uuid);
      //members
      this.processMembers(demo.members);
      this.processImmediateMembersForDemo(demo.immediate_leaders);

    } else {
      this.getTeam(this.currentTeamUuid);
      this.data.getMyTeamRole(this.currentTeamUuid).then(
        (res) => {
          this.memberPrio = {...this.data.userRole};
          if (this.memberPrio.role.workspacePriority > 11 && this.memberPrio.role.workspacePriority !== 20){
            this.myTeamRole = this.memberPrio.role.workspacePriority;
          }else {
            this.myTeamRole = res;
          }
          this.getTeamRoles(this.myTeamRole);
          this.getPolling(false);
        }
      );
      this.menuTeamPoll = this.data.menuShowPoll.subscribe((val) => {
        if (val) {
          this.menuButtonPressed();
        }
      });

      this.teamPollDone = this.data.teamPollDoneObs.subscribe((val) => {
        if (val) {
          // collector done get team statistics and stuff
          this.getTeam(this.currentTeamUuid);
          this.getTeamMembers(this.currentTeamUuid);
          this.getPolling(false);
        }
      });

      this.routerSubscription = this.activeRoute.params.subscribe(params => {
        if (this.currentTeamUuid !== params['team']) {
          this.data.showTeamActions = false;
          this.data.showMyActions = false;
          this.data.showTeamAnalyse = false;
          this.showDummyIndicators = true;
          this.hideProgressSpinner = false;
          this.data.teamProblemsNumber = {
            performance: 0,
            motivation: 0,
            satisfaction: 0,
            stress: 0
          };
          localStorage.removeItem('currentTeam');
          this.currentTeamUuid = params['team'];
          // this.getTeamTimeGraph(7);
          this.getTeam(params['team']);
          this.data.getMyTeamRole(this.currentTeamUuid).then(
            (res) => {
              this.memberPrio = {...this.data.userRole};
              if (this.memberPrio.role.workspacePriority > 11 && this.memberPrio.role.workspacePriority !== 20){
                this.myTeamRole = this.memberPrio.role.workspacePriority;
              }else {
                this.myTeamRole = res;
              }
              this.getTeamRoles(this.myTeamRole);
              this.getPolling(false);
            }
          );
          this.data.setMenuPoll(false);
          // this.ngOnInit();
        }
      });

      this.actionsAdded = this.data.findSolutionsChanged.subscribe(res => {
        if (res) {
          //@ts-ignore
          this.getTeamTasks(this.team.uuid, this.teamTasksType, 1, 'all');
        }
      });

      this.addedMember = this.data.addedMemberNowAddToTeam.subscribe(res => {
        if (res) {
          this.data.showLoading();
          setTimeout(() => {
            this.addNewMember();
          }, 2000);
        }
      });


      this.analyzeToChallenges = this.data.fromAnalyzeToActionObs.subscribe(res => {
        if (res) {
          this.data.showTeamAnalyse = !this.data.showTeamAnalyse;
          // this.analyzeToChallenges = 22;
          this.goToChallengesFromAnalyze();
        }
      });

      this.collectorChanges = this.data.collectorModSubs.subscribe(res => {
        if (res) {
          this.getTeam(this.currentTeamUuid);
          setTimeout(
            () => {
              this.getPolling(true);
            }, 1000
          );
        }
      });

      this.addedNewAction = this.challengesService.newActionSub.subscribe(res => {
        if (res) {
          this.worstStatements.forEach(el => {
            // @ts-ignore
            if (el.question.indicators[0].uuid === this.challengesService.newActionIndicatorUuid) {
              // @ts-ignore
              el.tasks.unshift(this.challengesService.newActionCreated);
              this.challengesService.newActionCreated = [];
            }
          });
        }
      });

      setTimeout(() => {
        this.data.showLoading();
      }, 100);
    }


  }

  ngOnInit() {
    // this.graph.newGraph();
    this.sevenDays = this.data.getLastDays(7);
    // this.data.getTeamsUsers(true);
    this.currentPlan = this.data.getWorkspacePlan();

    this.prioritySubscription = this.data.userPriorities
      .subscribe(value => {
        this.userPriorities = value;
      });

    this.getWorkspaceUsers();
    //@ts-ignore
    this.refreshPollsSubscription = this.data.refreshPolls.subscribe(refreshPolls => {
      this.refreshPolls = refreshPolls;
      this.currentTeamUuid = this.activeRoute.snapshot.paramMap.get('team');
      if (refreshPolls) {
        this.getTeamMembers(this.currentTeamUuid);
        this.getMyAssignmentPolls(this.currentTeamUuid);
      }
    });
    //@ts-ignore
    this.refreshTeamPollsSubscription = this.data.refreshTeamPolls.subscribe(refreshTeamPolls => {
      this.refreshTeamPolls = refreshTeamPolls;
      this.currentTeamUuid = this.activeRoute.snapshot.paramMap.get('team');
      if (refreshTeamPolls) {
        this.getTeamPolls(this.currentTeamUuid);
        this.getTeamMembers(this.currentTeamUuid);
      }
    });

    this.refreshTeeamMembersSubscription = this.data.refreshTeamMembers.subscribe(refreshTeamMembers => {
      this.refreshTeamMembers = refreshTeamMembers;
      this.currentTeamUuid = this.activeRoute.snapshot.paramMap.get('team');
      if (refreshTeamMembers) {
        // get members ember
        this.showTeamMembers = false;
        setTimeout(() => {
          this.getTeamMembers(this.currentTeamUuid);
        }, 5000)
        this.getPolling(false);
        this.getWorkspaceUsers();
      }
    });
    this.getUserRoles();
  }

  getWorkspaceUsers() {
    this.getUserSubscription = this.api.getWorkspacesUser().subscribe(res => {
      this.data.setWorkspaceMembers(res);
      return true;
    }, err => {
      return false;
    });
  }


  getTeam(team: string | null) {
    this.myTeamPollShow = false;
    this.data.showLoading();
    this.chartType = 'successteamScore';
    this.chartTitle = 'Success Score';
    // @ts-ignore
    this.chartOption.series[0].data = [];
    // @ts-ignore
    this.chartOption.series[1].data = [];
    // @ts-ignore
    this.chartOption.series[2].data = [];
    // @ts-ignore
    this.chartOption.xAxis.data = [];
    this.showChart = !this.showChart;    // this.getTeamPermission(team);
    if (this.workspace.workspaceSubscription.workspacePlan.requirePayment === 'yes') {
      this.getGraphicChart();
    }
    this.gotTeamColors = false;
    this.teamSubscription = this.api.getTeamsFromWorkspace()
      .subscribe((res: any) => {
        let i = 0;
        if (res != undefined) {
          for (const el of res) {
            if (team === el.uuid) {
              // HERE STARTS
              this.data.currentTeam = el;
              i++;
              this.team = el;
              window.scroll(0,0);
              this.getSuccessScoreProgress(el.previousTeamReport.teamSuccessteamScore, el.teamReport.teamSuccessteamScore)
              this.teamPollShow = true;
              this.myTeamPollShow = false;
              this.showTeamMembers = false;
              this.showTeamTasks = false;

              // @ts-ignore
              this.teamSubscription = this.api.getTeamStatistics(this.currentTeamUuid)
                .subscribe((response: any) => {
                  // @ts-ignore
                  this.team.statisticsStatus = response;
                  // this.pieChartData = [[0, 0]];
                  // this.pieChartData[0][0] = response.percentage;
                  // this.pieChartData[0][1] = 100 - response.percentage;
                  this.showReport = true;

                  // todo loadingurile drp
                  if (response.percentage === 100) {
                    // this.pieChartColours[0].borderColor = this.borderBlue;
                  } else {
                    // this.pieChartColours[0].borderColor = this.borderNormal;
                  }
                });

              // this.modal.isContentLocked(false);
              //HERE ***
              this.data.setIndicatorTeam(el.uuid);
              this.data.getTeamMembers();
              //@ts-ignore
              this.data.indicatorActionTask.team = el.uuid;
              this.data.addMemberTeam = el.uuid;
              this.teamName = el.name;
              this.getMyAssignmentPolls(el.uuid);
              this.getTeamMembers(el.uuid);
              this.getTeamTasks(el.uuid, '', 1, 'all');
              this.getTeamPolls(el.uuid);
              this.showTeam = true;
              this.getIndicatorPercentage();
            }
          }
        }

        this.teamFound = i !== 0;

        if (!this.teamFound) {
          this.showError = true;
        }
        this.data.hideLoading();
      }, err => {
        this.data.hideLoading();
        this.showError = true;
      });

    this.api.getIndicatorInterval().subscribe(
      res => {
        //@ts-ignore
        res.forEach(el => {
          // @ts-ignore
          this.scoreColorInterval[el.indicatorColor.color].min = el.min;
          // @ts-ignore
          this.scoreColorInterval[el.indicatorColor.color].max = el.max;
        });
      }
    );
  }

  //@ts-ignore
  showMemberReport(event) {
    this.data.showLoading();
    //@ts-ignore
    let visibility;
    //@ts-ignore
    let message;
    // @ts-ignore
    if (this.team.indicatorVisibility === 'yes') {
      visibility = 'no';
      message = 'The team members will no longer see the team indicators on their team dashboard.';
    } else {
      visibility = 'yes';
      message = 'The team members will see the 4 team indicators on their team dashboard.';
    }
    // @ts-ignore
    this.api.patchTeam({indicatorVisibility: visibility}, this.currentTeamUuid).subscribe(
      res => {
        // @ts-ignore
        this.team.indicatorVisibility = visibility;
        this.data.hideLoading();
        //@ts-ignore
        this.data.showAlert(message);
      }, errors => {
        this.data.hideLoading();
        this.data.showAlertError('There was an error hiding the team indicators. Try later or contact support.');
      }
    );
  }

  //@ts-ignore
  getMyAssignmentPolls(uuid) {
    this.myTeamPollShow = false;
    // @ts-ignore
    this.api.getMyAssignmentPolls(uuid, this.currentTeamUuid).subscribe(
      res => {
        this.myTeamPoll = res;
        // this.getPollingTimeRemaining(currentPolling['cronNextRun']['date']) > 0 ?
        if (res.length > 0) {
          this.getPollingTimeRemaining(res[0].dueDate?.date);
          // this.getPollingTimeRemaining(res[0].dueDate?.date);

          if (this.data.autoOpenMemberFeedback) {
            // hide member dashboard
            this.data.autoOpenMemberFeedback = false;
            this.getUserImmediateLeader();
            this.getTeamPoll();
          }
          localStorage.setItem('currentTeam', uuid);
        } else {
          localStorage.removeItem('currentTeam');
        }
        this.myTeamPollShow = true;
      },
      err => {

      }
    );
  }

  getImmediateMembers(currentTeamUuid: any) {
    this.immediateMembers= {};
    this.api.getTeamLeadersImmediateMembers(currentTeamUuid).subscribe(
      res => {
        res.members.forEach((el:any, index:number) => {
          if (el.immediateLeader !== null &&el.immediateLeader.teamRole !== null){
            if (el.immediateLeader.teamRole?.teamPriority > 10){
            res.members.splice(index, 1);
            res.members.unshift(el);
            }
          }
        })

        this.immediateMembers = res.members.filter((member: any) => member.immediateLeader != null);
        this.membersWithoutLeader = [];
        res.members.forEach((member : any) => {
          if(member.immediateLeader === null) {
            member.members.forEach((el:any) => {
              if(el.member.teamRole.teamPriority <= 10) {
                this.membersWithoutLeader.push(el);
              }
            });
          }
        });
      }
    );
  }

  processImmediateMembersForDemo(immLeaders: any) {
    this.immediateMembers = immLeaders[0]['members_with_leader'];
    this.membersWithoutLeader = immLeaders[0]['members_without_leader'];
    
  }

  //@ts-ignore
  getTeamMembers(uuid) {
    this.showTeamMembers = false;
    this.teamMembers = [];
    this.teamAdmins = [];
    this.teamMembersNumber = 0
    this.api.getMemberActivity(uuid).subscribe(
      res => {
        this.processMembers(res);
      }, err => {
        console.log(err);
      }
    );
    this.getImmediateMembers(this.currentTeamUuid);
  }

  processMembers(res: any) {
    this.showTeamMembers = false;
    this.teamMembers = [];
    this.teamAdmins = [];
    this.teamMembers = res.teamLeadersReport.concat(res.teamMembersReport);
    this.data.setTeamLeaders(res.teamLeadersReport);
    //@ts-ignore
    this.teamMembers.forEach((el) => {
      if (el.teamMember.user.teamRole) {
        if (el.teamMember.user.teamRole.name === 'team-admin') {
          el.teamMember.checked = false;
          // @ts-ignore
          this.teamAdmins.push(el.teamMember);
        }
      }
    });
    
    this.teamMembersNumber = this.teamMembers.length;
    this.showTeamMembers = true;
    // @ts-ignore
    if (this.team.pollLeaderAssign === 'yes') {
      this.disableSetup = this.teamMembers.length >= 4;
    } else {
      this.disableSetup = res.teamMembersReport.length >= 4;
    }
    this.data.getTeamMembersToAdd(this.teamMembers);
    this.getUserCurrentImmediateLeader();
  }

  //@ts-ignore
  getTeamTasks(teamUuid, type, page, indicator) {
    this.challengesService.sort = indicator;
    this.teamTasksType = type;
    this.showTeamTasks = false;
    // this.showTeamTasks = false;
    this.showTeamTaskLoading = true;
    if (page === 1) {
      this.teamTasks = [];
      this.teamTasksPages = 1;
    }

    this.api.getTeamTasks(teamUuid, type, page, indicator).subscribe(
      // tslint:disable-next-line:no-shadowed-variable
      res => {
        res._embedded['my-tasks'].forEach((el: { checked: boolean; status: string; }) => {
          el.checked = el.status === 'done';
          // el.taskId = el.uuid +
        });
        this.teamTasks = this.teamTasks.concat(res._embedded['my-tasks']);
        this.cdRef.detectChanges();
        // @ts-ignore
        this.challengesService.getMyActions(this.teamTasks, this.team.uuid);
        if (res._page_count > 1 && this.teamTasksPages < res._page_count) {
          this.teamTasksPages++;
          this.getTeamTasks(teamUuid, type, this.teamTasksPages, 'all');
        } else if (this.teamTasksPages === res._page_count || res._page_count === 0) {
          this.showTeamTasks = true;
          this.showTeamTaskLoading = false;
        }
      },
      err => {

      }
    );

    for (let action of this.teamTasks) {
      if (action['status'] === 'to_do' && this.toDoActions.length <= 3) {
        this.toDoActions.push(action);
      } else if (action['status'] === 'done' && this.doneActions.length <= 3) {
        this.doneActions.push(action);
      } else if (action['status'] === 'in_progress' && this.inProgressActions.length <= 3) {
        this.inProgressActions.push(action);
      }
    }
    this.toDoActions = [...new Set(this.toDoActions)];
    this.inProgressActions = [...new Set(this.inProgressActions)];
    this.doneActions = [...new Set(this.doneActions)];

    this.gotNewChallenges = false;

    // TODO only if user is over 10 role
    // if (this.myTeamRole > 10) {
    //   this.api.getNewChallenges(teamUuid).subscribe(
    //     res => {
    //       const newChallenges = res._embedded['team-challenges'];
    //       if (newChallenges.length > 0) {
    //         this.challengesService.setChallenges(res._embedded['team-challenges'], this.team);
    //         this.gotNewChallenges = true;
    //       }
    //     }, err => {
    //       console.log(err);
    //     }
    //   );
    // }
  }

  processReport(res: any) {
    // this.sortColors(res);
    this.data.teamAnalyseReport = res;
    this.teamReport = res;
    // getting categories
    this.categories = res.categories;
    // get the other things
    if (!this.data.showDemo) {
      // @ts-ignore
      this.getTeamPolls(this.data.indicatorActionTask.team);
    }
    this.getIndicatorPercentage();
    this.showReport = true;
  }

  getUserRoles() {
    // @ts-ignore
    this.api.getUserDetails(localStorage.getItem('WORKSPACE_UUID')).subscribe(
      res => {
        this.user = res;
      }
    );
  }

  // @ts-ignore
  getTeamPolls(uuid) {
    this.teamPollShow = true;
    let foundPoll = false;
    this.eligiblePolls = [];
    this.api.getTeamPolls(uuid).subscribe(
      res => {
        // get the last poll
        this.getCurrentStatements(res);
        
        // this.getWorstStatements(this.team.uuid);
        this.worstStatements = [];
        // get the statements of the last poll
        if (res.length > 0) {
          // @ts-ignore
          res.forEach((el) => {
            const year = new Date(el.created.date).getFullYear().toString();
            if (el.eligibleForResult === 'yes' && el.status === 'inactive') {
              el.nameSelect = '' + this.data.getDateNoYear(el.created) + ' - ' + this.data.getDateNoYear(el.expireDate);
              el.pollYear = year;
              // @ts-ignore
              this.eligiblePolls.push(el);
              
            }
            if (el.eligibleForResult === 'yes' && el.status === 'inactive' && !foundPoll) {
              foundPoll = true;
              this.worstStatemstLastPoll = el;
              // get the statements if the user iz admin
              if (this.myTeamRole > 10) {
                // this.responseDistribution.next(null);
                this.getWorstStatements(el);
              }
            }
          });
          this.previousLoopsDates = [];
          this.selectedPreviousPoll = null;
          this.getPreviousLoopsYears();
          // @ts-ignore
          this.eligiblePollSelected = this.eligiblePolls[0]?.nameSelect;
          // todo
          if (this.myTeamRole > 10 && !this.data.wizardOn && (this.eligiblePolls.length === 1)) {
            this.showWizard();
          }
          
        }
        this.teamPollShow = false;
        this.teamPoll = res;
        setTimeout(() => {
          this.hideProgressSpinner = true;
        }, 1500)
      }
    );
  }

  getPreviousLoopsYears() {
    const years = this.eligiblePolls.map(({ pollYear }) => pollYear);
    const removeDups = (years: string[]): string[] => {
      return years.filter((item, index) => years.indexOf(item) === index);
    }
    var yearsFiltered = removeDups(years);
    yearsFiltered.forEach(year => {
      this.previousLoopsDates.push({year: year, dates: []});
    });
    this.previousLoopsDates = this.previousLoopsDates.reverse();
    if(this.eligiblePolls.length > 0) {
      this.getPreviousLoopsDates();
    }
  }

  getPreviousLoopsDates() {
    const currentYear = new Date().getFullYear().toString();
    this.previousLoopsDates.forEach((loop: any, index: number) => {
      if (currentYear === loop.year) {
        this.currentLoopIndexPage = index;
        this.previousLoopIndexPage = index;
      }
      this.eligiblePolls.forEach((poll: any, index: number) => {
        const year = new Date(poll.created.date).getFullYear().toString();
        if(loop.year === year && index >= 0) {
          loop.dates.push({startDate: poll.created.date, endDate: poll.expireDate.date, uuid: poll.uuid});
        }
      });
    });
  }

  //@ts-ignore
  getSuccessScoreProgress(prevSuccessScore, currentSuccsessScore) {
    if (this.team.previousTeamReport.teamSuccessteamScore === 0) {
      return this.successScoreProgress = Math.round(currentSuccsessScore);
    }
    if (this.team.teamReport.teamSuccessteamScore > this.team.previousTeamReport.teamSuccessteamScore) {
      const progress = currentSuccsessScore - prevSuccessScore;
      this.successScoreProgress = Math.round((progress * prevSuccessScore) / 100);
    }
    if (this.team.teamReport.teamSuccessteamScore < this.team.previousTeamReport.teamSuccessteamScore) {
      const progress = prevSuccessScore - currentSuccsessScore;
      this.successScoreProgress = Math.round((progress * prevSuccessScore) / 100);
    }
  }

  //@ts-ignore
  getWorstStatements(poll: any) {
    const url = 'task/questions-suggested-tasks'
    let newUrl = '';
    if (poll.uuid !== '') {
      newUrl = url + '/pollUuid/' + poll.uuid
      this.worstLoop = true;
    } else {
      newUrl = url;
      this.worstLoop = false;
    }
    this.pollUuidForResponseDistribution = poll.uuid;
    this.pollDatesForResponseDistribution = {
      startDate: poll?.created?.date ? poll.created.date : poll.startDate, 
      endDate: poll?.expireDate?.date ? poll.expireDate.date : poll.endDate
    };
    // @ts-ignore
    this.api.getTeamWorstStatements(this.currentTeamUuid, newUrl).subscribe(
      result => {
        this.processWorstStatements(result);
        this.hideProgressSpinner = this.worstStatements.length > 0;
      }, err => {
        console.log(err);
      }
    );
  }

  getStatementResponseDistribution(index: number): Observable<any> {
    return this.api.getStatementResponseDistributionByPollUuid(this.currentTeamUuid, this.worstStatements[index].question.uuid, this.pollUuidForResponseDistribution, {next: '', today: ''});
  }

  processWorstStatements(result: any) {
    this.worstStatements = [];
    // @ts-ignore
    result.forEach(x => {
      const showTasks = [...x.question.tasks];
      if (showTasks.length >= 3) {
        x.showTasks = showTasks.splice(0, 3);
      } else {
        x.showTasks = showTasks;
      }
    });
    // @ts-ignore
    this.worstStatements = [...result];
    this.getStatementResponseDistribution(this.worstStatementsCarouselPage).subscribe((res:any) => {
      if (res) {
        this.getResponseDistributionValues(res);
      }
    });
  }

  getResponseDistributionValues(rDistribution: any) {
    rDistribution.dates = this.pollDatesForResponseDistribution;
    this.responseDistribution = new Observable(subcriber => {
      subcriber.next(rDistribution);
    });
  }

  //@ts-ignore
  private getPolling(update) {
    //@ts-ignore
    // if (this.myTeamRole > 10) {
      this.data.activPoll = {};
      this.showEditCollector = false;
      this.showPolling = false;
      if (!update) {
        this.pollingExist = false;
      }
      this.api.getTeamActivePolling(this.currentTeamUuid).subscribe(
        res => {
          this.data.nextCollector = res.cronNextRun?.date;
          this.polling = res;
          this.teamMemberPolingDetails = res;
          this.pollingExist = !res.info;
          if (this.pollingExist) {
            this.data.activPollExists = true;
            this.data.activPoll = res;
            if((this.data.calculateDays(this.teamMemberPolingDetails.startDate.date) > 0) && this.teamMemberPolingDetails.status === 'active') {
              this.teamMemberPolingDetails.type =  {name: this.language.toLowerCase() === 'en_us' ? 'SCHEDULED':'PLANLAGT'};
              this.teamMemberPolingDetails.type.show =  [{ongoing: false}, {scheduled: true}, {paused: false}];
            } else if (this.teamMemberPolingDetails.status === 'active') {
              // this.showTeamMemberPolingStatus = true;
              this.teamMemberPolingDetails.type =  {name: this.language.toLowerCase() === 'en_us' ? 'ONGOING':'IGANGVÆRENDE'};
              this.teamMemberPolingDetails.type.show =  [{ongoing: true}, {scheduled: false}, {paused: false}];
            }
          } else {
            // this.showTeamMemberPolingStatus = false;
            this.data.activPollExists = false;
            this.data.activPoll = {};
            this.polling.status = 'inactive';
          }
          // this.pollingExist = true;
          this.collectorState = res.info ? 'Inactive!' : 'Active!';
          this.showPolling = true;
          this.showTeamMemberPolingStatus = !res.info;

        }, err => {
          console.log(err);
        });

      this.api.getTeamPolling(this.currentTeamUuid).subscribe(
        res => {
          this.processTeamPolling(res);
        }, err => {
          console.log(err);
        });
    }
  // }

  processTeamPolling(res: any) {
    //@ts-ignore
    this.currentPolling = {};
    if (res.length !== 0) {
      this.teamMemberPolingDetails = res[res.length - 1];
      this.currentPolling = res[res.length - 1];
      this.currentPolling.type = '';
      this.showTeamMemberPolingStatus = false;
      // if next start date > now  the pooling iz scheduled
      if ((this.data.calculateDays(this.currentPolling.startDate.date) > 0) && this.currentPolling.status === 'active') {
        this.currentPolling.type = {name: this.language.toLowerCase() === 'en_us' ? 'SCHEDULED':'PLANLAGT'};
        this.currentPolling.type.show =  [{ongoing: false}, {scheduled: true}, {paused: false}, {inactive: false}];
      } else if (this.currentPolling.status === 'active') {
        this.data.pauseCollector = false;
        // TODO HEREEEEE
        this.currentPolling.type = {name: this.language.toLowerCase() === 'en_us' ? 'ONGOING':'IGANGVÆRENDE'};
        this.currentPolling.type.show =  [{ongoing: true}, {scheduled: false}, {paused: false}, {inactive: false}];
        this.showTeamMemberPolingStatus = true;
      } else if (this.currentPolling.status === 'paused') {
        this.currentPolling.type =  {name: this.language.toLowerCase() === 'en_us' ?  'PAUSED':'PÅ PAUSE'};
        this.currentPolling.type.show =  [{ongoing: false}, {scheduled: false}, {paused: true}, {inactive: false}];
        this.teamMemberPolingDetails.type = {name: this.language.toLowerCase() === 'en_us' ?  'PAUSED':'PÅ PAUSE'};
        this.teamMemberPolingDetails.type.show =  [{ongoing: false}, {scheduled: false}, {paused: true}];
        this.showTeamMemberPolingStatus = true;
      } else {
        this.currentPolling.type = {name: this.language.toLowerCase() === 'en_us' ?  'INACTIVE':'INAKTIV'};
        this.currentPolling.type.show =  [{ongoing: false}, {scheduled: false}, {paused: false}, {inactive: true}];
      }
    } else {
      this.currentPolling.status = 'inactive';

      this.currentPolling.type = {name: this.language.toLowerCase() === 'en_us' ?  'INACTIVE':'INAKTIV'};
      this.currentPolling.type.show =  [{ongoing: false}, {scheduled: false}, {paused: false}, {inactive: true}];
    }
    this.pollingExist = res.length !== 0;
    this.showPolling = true;
  }

  private menuButtonPressed() {
    // GET POLE
    if (this.myTeamPoll[0]) {
      this.data.showLoading();
      // @ts-ignore
      this.data.teamCollector = {
        teamUuid: this.currentTeamUuid,
        teamCollector: this.myTeamPoll[this.myTeamPoll.length - 1]
      }
      this.data.setTeamCollectorSubs(true);
      // this.spinner.hide();
    } else {
      // this.spinner.hide();
    }
  }

  //@ts-ignore
  getTeamRoles(myPriority) {
    this.api.getAllRoles('team').subscribe(
      res => {
        this.teamRoles = [];
        //@ts-ignore
        res.forEach((el) => {
          if (el.teamPriority <= myPriority) {
            //@ts-ignore
            this.teamRoles.push(el);
          }
        });
      }, err => {
        console.log(err);
      }
    );
  }

  //@ts-ignore
  changeMemberRoles(member) {
    const roles = this.data.getTeamRoles();
    //@ts-ignore
    let memberRole;
    if (member.user.teamRole.name === 'team-member') {
      memberRole = 'team-admin';
    } else {
      memberRole = 'team-member';
    }
    roles.forEach((el) => {
      //@ts-ignore
      if (el.name === memberRole) {
        this.changeMemberRole(member, el);
      }
    });
  }

  deleteOrChangeMemberOpenModal(member: any, action: string, fromIml: boolean) {
    this.teamMemberChanges.fromIml = fromIml;
    this.teamMemberChanges.details = member;
    this.teamMemberChanges.action = action;
    document.getElementById('deleteOrChangeMemberButton1')?.click();
  }

  deleteOrChangeMemberCloseModal() {
    const closeModal = document.getElementById('closeDeleteOrChangeMemberModal1');
    closeModal?.click();
  }

  deleteMember(member: any) {
    this.data.showLoading();
    this.api.deleteTeamMember(member.uuid, this.currentTeamUuid).subscribe(res => {
      this.getTeamMembers(this.currentTeamUuid);
      this.deleteOrChangeMemberCloseModal();
      this.data.hideLoading();
      this.data.showAlert('Member deleted.');
    }, err => {
      this.data.hideLoading();
      this.data.showAlertError(err.error.error.messages[0]);
    });
  }

  deleteWorspaceMember(member: any) {
    this.data.showLoading();
    this.api.deleteWorkspaceUser(member.user.uuid).subscribe(res => {
      this.data.hideLoading();
      this.getTeamMembers(this.currentTeamUuid);
      this.deleteOrChangeMemberCloseModal();
      this.data.showAlert('Member removed.');
    }, err => {
      this.data.hideLoading();
      this.data.showAlertError(err.error.error.messages[0]);
    });
  }

  //@ts-ignore
  changeMemberRole(member, role) {
    // if user select same option don't do anything
    this.data.showLoading();
    if (member.user.teamRole.label !== role.label) {
      this.api.postMemberRole(member.user.uuid, role.uuid, this.currentTeamUuid).subscribe(
        res => {
          this.deleteOrChangeMemberCloseModal();
          this.data.hideLoading();
          this.getTeamMembers(this.currentTeamUuid);
          // this.data.showAlertSuccess('Member permission changed!', '');
        }, err => {
          this.data.hideLoading();
          this.getTeamMembers(this.currentTeamUuid);
          this.data.showAlertError(err.error.error.messages[0]);
        });
    }
  }

  addNewMember() {
    //reset every time
    // team invite  member directly in team

    // organize team
    // @ts-ignore
    this.data.organizeTeams = [this.team];
    this.data.openFromTeam = true;
    this.data.setOrganizeTeams(true);

  }

  private getIndicatorPercentage() {
    this.indicatorPercentage = {
      performance: this.team.teamReport["Performance"],
      motivation: this.team.teamReport["Motivation"],
      satisfaction: this.team.teamReport["Satisfaction"],
      stress: this.team.teamReport["Stress"],
    };
    this.successScore = this.team.teamReport["teamSuccessteamScore"];
    this.teamFound = true;
    this.getScoreStatus();
    this.getIndicatorColor();
  }

  setKnobProgressBar() {
    if (document.getElementById("svgProgress")) {
      this.knobProgress = Math.round((184 - (184 * this.successScore) / 100));
      //@ts-ignore
      document.getElementById("svgProgress").style.strokeDashoffset = this.knobProgress.toString();
    } else {
      setTimeout(() => {
        this.setKnobProgressBar()
      }, 200);
    }
  }

  getScoreStatus() {
    this.showScore = false;
    Object.keys(this.scoreColorInterval).forEach(key => {
      // @ts-ignore
      const value = this.scoreColorInterval[key];
      // this.successScore;
      if (this.successScore > 0) {
        if (this.successScore >= value.min && this.successScore <= value.max) {
          switch (key) {
            case 'red' :
              this.scoreColorArrow = this.data.colors.red;
              this.scoreColor = '#E83032';
              this.showScore = true;
              break;
            case 'orange' :
              this.scoreColorArrow = this.data.colors.orange;
              this.scoreColor = '#FE9001';
              this.showScore = true;
              break;
            case 'yellow' :
              this.scoreColorArrow = this.data.colors.yellow;
              this.scoreColor = '#FFC828';
              this.showScore = true;
              break;
            case 'green' :
              this.scoreColorArrow = this.data.colors.green;
              this.scoreColor = '#00BD82';
              this.showScore = true;
              break;
          }
        }
        this.getArrowColor();
      } else {
        this.scoreColor = 'control';
        this.showScore = true;
      }
    });
  }

  goToChallengesFromAnalyze() {
    this.goToChallenges(false);
  }

  // @ts-ignore
  goToChallenges(showNewChallenges) {
    this.data.showLoading();
    this.data.teamNewProblems = [];
    this.challengesService.setAdmins(this.teamAdmins);
    this.challengesService.allChallenges.forEach(newStatement => {
      this.data.teamProblems.forEach((el: any) => {
        // @ts-ignore
        if (newStatement.indicator.uuid === el.uuid) {
          // @ts-ignore
          el.status = newStatement.status;
          // @ts-ignore
          el.challengeUuid = newStatement.uuid;
          // move to first spot
        }
      });
    });

    // @ts-ignore
    const oldChallenges = [];
    // @ts-ignore
    const newChallenges = [];
    this.data.teamProblems.forEach((el: any) => {
      // get the teamProblems, if the challenge was seen change status
      // @ts-ignore
      if (el.hasBeenSeen) {
        // @ts-ignore
        el.status = 'seen';
      }
      // @ts-ignore
      if (el.status === 'seen') {
        // @ts-ignore
        el.hasBeenSeen = true;
        oldChallenges.push(el);
        // @ts-ignore
      } else if (el.status === 'active') {
        // @ts-ignore
        el.hasBeenSeen = false;
        newChallenges.push(el);
      }
    });
    this.data.teamProblems = [];
    this.data.teamNewProblems = [];

    // @ts-ignore
    this.data.teamNewProblems = newChallenges;
    // @ts-ignore
    this.data.teamProblems = newChallenges.concat(oldChallenges);
    this.data.showNewProblems = showNewChallenges;
    this.challengesService.goToImprove(showNewChallenges);
  }

  private getIndicatorColor() {

    Object.keys(this.indicatorPercentage).forEach(indicator => {
      Object.keys(this.scoreColorInterval).forEach(key => {
        // @ts-ignore
        const value = this.scoreColorInterval[key];
        // this.successScore;
        // @ts-ignore
        if (this.indicatorPercentage[indicator] > 0) {
          // @ts-ignore
          if (this.indicatorPercentage[indicator] >= value.min && this.indicatorPercentage[indicator] <= value.max) {
            switch (key) {
              case 'red' :
                // @ts-ignore
                this.indicatorBackground[indicator] = this.data.colors.redGradient;
                // @ts-ignore
                // this.worstColors[indicator] = '../../assets/indicators/' + indicator + '/red' + '.svg';
                break;
              case 'orange' :
                // @ts-ignore
                this.indicatorBackground[indicator] = this.data.colors.orangeGradient;
                // @ts-ignore
                // this.worstColors[indicator] = '../../assets/indicators/' + indicator + '/orange' + '.svg';
                break;
              case 'yellow' :
                // @ts-ignore
                this.indicatorBackground[indicator] = this.data.colors.yellowGradient;
                // @ts-ignore
                // this.worstColors[indicator] = '../../assets/indicators/' + indicator + '/yellow' + '.svg';
                break;
              case 'green' :
                // @ts-ignore
                this.indicatorBackground[indicator] = this.data.colors.greenGradient;
                // @ts-ignore
                // this.worstColors[indicator] = '../../assets/indicators/' + indicator + '/green' + '.svg';
                break;
            }
          }
        } else {
          // @ts-ignore
          this.indicatorBackground[indicator] = this.data.colors.greyGradient;
        }
      });
    });
    this.gotTeamColors = true;
  }

  private getGraphicChart() {
    // @ts-ignore
    this.api.getGraphicChartOfImprovements(this.currentTeamUuid).subscribe(
      res => {
        this.processGraph(res);
      }, err => {
        console.log(err);
      }
    );
  }

  processGraph(res: any) {
    this.colorOfArrow = this.data.colors.grey;
    // @ts-ignore
    this.chartOption.series[0].data = [];
    // @ts-ignore
    this.chartOption.series[1].data = [];
    // @ts-ignore
    this.chartOption.series[2].data = [];
    this.chartData = { successteamScore: [], motivation: [], performance: [], satisfaction: [], stress: [], responseRate: []};
    // @ts-ignore
    this.chartOption.xAxis.data = [];

    // this.data.chartData = res._embedded['chart-of-improvements'];
    const graph = res._embedded['chart-of-improvements'];
    let indicatorKeys;
    this.data.setChartSubscription(true);
    const graphData = [];
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < graph.length; i++) {
      // @ts-ignore
      this.chartOption.xAxis.data.push(formatDate(graph[i].date.date, 'dd MMM yyyy', 'en-US').slice(0, 6) + '');
      this.teamResponseRate = graph[i].responseRate;
      if (i > 0 && graph[i].successteamScore === 0) {
        graph[i].successteamScore = graph[i - 1].successteamScore;
      }
      // @ts-ignore
      this.chartData.successteamScore.push(graph[i].successteamScore);
      // @ts-ignore
      this.chartData.responseRate.push(graph[i].responseRate);
      graphData.push(graph[i].successteamScore);
      // @ts-ignore
      this.chartOption.series[0].data.push(graph[i].successteamScore);
      // @ts-ignore
      this.chartOption.series[1].data.push(null);
      // @ts-ignore
      this.chartOption.series[2].data.push(graph[i].responseRate);
      indicatorKeys = Object.keys(graph[i].indicatorScores);
      indicatorKeys.forEach((indicator, index) => {
        // @ts-ignore
        this.chartData[indicator.toLowerCase()].push(graph[i].indicatorScores[indicator]);
        // @ts-ignore
        this.chartData[indicator.toLowerCase()].forEach((indicatorData, j) => {
          if (j > 0 && indicatorData === 0) {
            // @ts-ignore
            this.chartData[indicator.toLowerCase()][j] = this.chartData[indicator.toLowerCase()][j - 1];
          }
        });

      });
    }
    if (graph[graph.length - 1].pollStatus !== null) {
      if (graph[graph.length - 1].pollStatus.toLowerCase() === 'active') {
        // this.chartOption.series[1].data;
        // @ts-ignore
        this.chartOption.series[1].data[this.chartOption.series[0].data.length - 1] = this.chartOption.series[0].data[this.chartOption.series[0].data.length - 1];
        // @ts-ignore
        this.chartOption.series[1].data[this.chartOption.series[0].data.length - 2] = this.chartOption.series[0].data[this.chartOption.series[0].data.length - 2];
        // @ts-ignore
        this.chartOption.series[0].data.pop();
        // @ts-ignore
        this.chartOption.xAxis.data[graph.length - 1] = formatDate(new Date(), 'dd MMM yyyy', 'en-US').slice(0, 6) + '';
      }
    }
    this.showChart = !this.showChart;
    this.getArrowDirection();

    // @ts-ignore
    this.chartResponseRateData = this.chartOption.series[2];
    // @ts-ignore
    this.chartResponseRateDataLine = this.chartOption.series[2].data;
    this.chartResponseRateChecked = true;
  }

  //@ts-ignore
  changeChart(type, title) {
    const lang = this.language.toLowerCase() === 'en_us' ? 'EN':'DK';
    switch(title) {
      case 'Satisfaction':
        title = lang === 'EN' ? 'Satisfaction':'Tilfredshed';
        break;
      case 'Stress Free':
        title = lang === 'EN' ? 'Stress Free':'Stressfri';
        break;
    }
    // @ts-ignore
    this.chartTitle = title;
    this.chartType = type;
    // @ts-ignore
    this.chartOption.series[0].name = title;
    // @ts-ignore
    this.chartOption.series[1].name = title;
    // @ts-ignore
    this.chartOption.series[1].data = [];
    // @ts-ignore
    this.chartOption.series[0].data = [...this.chartData[type]];
    // // @ts-ignore
    // if (this.chartOption.series[2].data[this.chartOption.series[0].data.length - 1] === 100) {
    // } else {
      
    // }
    // @ts-ignore
    this.chartOption.series[1].data[this.chartOption.series[0].data.length - 1] = this.chartOption.series[0].data[this.chartOption.series[0].data.length - 1];
    // @ts-ignore
    this.chartOption.series[1].data[this.chartOption.series[0].data.length - 2] = this.chartOption.series[0].data[this.chartOption.series[0].data.length - 2];
    // @ts-ignore
    this.chartOption.series[0].data.pop();

    this.getArrowDirection();
    this.showChart = !this.showChart;
  }

  chartResponseRate() {
    this.chartResponseRateChecked = !this.chartResponseRateChecked;
    if (this.chartResponseRateChecked) {
      // @ts-ignore
      this.chartOption.series[2] = this.chartResponseRateData;
      // @ts-ignore
      this.chartOption.series[2].data = this.chartResponseRateDataLine;
    } else {
      // @ts-ignore
      this.chartOption.series[2].data = [];
    }
    this.showChart = !this.showChart;
  }

  getArrowDirection() {
    // @ts-ignore
    const data = this.chartOption.series[0].data;
    if (data[data.length - 2] < data[data.length - 1]) {
      this.arrowPointing = 'up';
    } else if (data[data.length - 2] > data[data.length - 1]) {
      this.arrowPointing = 'down';
    } else if (data[data.length - 2] === data[data.length - 1]) {
      this.arrowPointing = 'middle';
    }
    this.getArrowColor();
  }

  getArrowColor() {
    if (this.chartTitle === 'Success Score') {
      this.colorOfArrow = this.scoreColorArrow;
    } else if (this.chartTitle === 'Stress-free') {
      this.colorOfArrow = this.indicatorBackground.stress;
    } else {
      // @ts-ignore
      this.colorOfArrow = this.indicatorBackground[this.chartTitle.toLowerCase()];
    }
  }

  //@ts-ignore
  removeStatementTask(teamTask: any, x, i: number) {
    this.data.showLoading();
    //@ts-ignore
    this.api.patchTask(this.worstStatements[x].showTasks[i].uuid, 'useless', false, this.currentTeamUuid, '').subscribe(
      res => {
        this.data.hideLoading();
        // check task array - remove the task, and if there are more tasks, replace tasks [i] from showTasks
        //@ts-ignore
        this.worstStatements[x].tasks.splice(i, 1);
        // @ts-ignore
        this.worstStatements[x].showTasks.splice(i, 1);
      }, err => {
        this.data.hideLoading();
      }
    );
  }

  assignToMe(action: any, statement: any, member: any) {
    // @ts-ignore
    let myEmail = JSON.parse(localStorage.getItem('userDetails')).email;
    this.data.teamLeaders.forEach((el: any) => {
      if (el.user.email === myEmail) {
        this.assignAction(action, statement, el);
      }
    })
  }

  assignAction(action: any, statement: any, member: any) {
    let body =
      {
        assigneeUuid: member.user.uuid,
        questionUuid: statement.question.uuid,
        taskUuid: action.task ? action.task.uuid : action.uuid
      }
    // check if member is me and reload or add task to TO_DO
    this.api.postAssignAction(body, this.currentTeamUuid).subscribe(
      (res: any) => {
        this.worstStatements.forEach((el: any) => {
          if (el.question.uuid === statement.question.uuid) {
            el.showTasks.forEach((task: any) => {
              if (task.uuid === action.uuid) {
                task.frequency = task.frequency + 1;
                task.task.assignedTasks[0] = res;
              }
            })
            el.question.tasks.forEach((task: any) => {
              if (task.task.uuid === action.uuid) {
                task.frequency = task.frequency + 1;
                task.task.assignedTasks[0] = res;
              }
            })
          }
        });
        // if the task is assigned to me refresh
        // @ts-ignore
        if (member.user.email === JSON.parse(localStorage.getItem('userDetails')).email){
          this.getTeamTasks(this.team.uuid, this.teamTasksType, 1, 'all');
        }

        document.getElementById('closeActioneModal')?.click();
        if (this.user.email === res.assignee.email){
          this.challengesService.addNewAction(res);
        }
        // this.challengesService.addNewAction(res);
        // add the task to the statement tasks
      }, error => {
        console.log(error)
      }
    );
  }

  //@ts-ignore
  goToMyActions(action) {
    this.data.showMyActionsClicked = '';
    if (action.uuid) {
      this.data.showMyActionsClicked = action.uuid;
    }
    this.data.setEditTeam(this.team);
    this.data.myActions = true;
    this.data.showMyActions = true;
    this.showDummyIndicators = true;
  }

  //@ts-ignore
  addNewAction(question: any, member: any) {
    const form = this.challengesService.createActionForm;
    let body =
      {
        title: form.value.actionTitle,
        questionUuid: question.question.uuid,
        description: form.value.actionDescription
      }
    this.data.showLoading();
    this.api.postCreateAction(body, this.currentTeamUuid).subscribe(
      (res: any) => {
        // add the task to the statement tasks
        this.worstStatements.forEach((el: any) => {
          if (el.question.uuid === question.question.uuid) {
            form.controls['actionTitle'].setValue('');
            form.controls['actionDescription'].setValue('');
            el.question.tasks.unshift({task: res});
            el.showTasks.pop();
            el.showTasks.unshift({task: res});

            // assign to the member if there is a member
            if (member !== null) {
              this.assignAction(res, el, member)
              // make the action shown that it is assigned
            }
            document.getElementById('closeActionModal')?.click()
          }

        })

        this.data.hideLoading();
      }, error => {
        console.log(error)
        this.data.hideLoading();
      }
    );
  }

  ngOnDestroy(): void {
    if (!this.router.url.includes('onboarding')) {
      if (!this.data.showDemo) {
        this.getUserSubscription.unsubscribe();
        this.routerSubscription.unsubscribe();
        this.refreshTeeamMembersSubscription.unsubscribe();
        this.refreshPollsSubscription.unsubscribe();
        this.refreshTeamPollsSubscription.unsubscribe();
        this.teamSubscription.unsubscribe();
        this.menuTeamPoll.unsubscribe();
        this.teamPollDone.unsubscribe();
        this.actionsAdded?.unsubscribe();
        // @ts-ignore
        this.addedMember.unsubscribe();
        // @ts-ignore
        this.analyzeToChallenges.unsubscribe();
        // @ts-ignore
        this.collectorChanges.unsubscribe();
        // @ts-ignore
        this.addedNewAction.unsubscribe();
      }
    }
  }

  hexToRgb(hex: string) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? 'rgb(' + parseInt(result[1], 16) + ', ' + parseInt(result[2], 16) + ', ' + parseInt(result[3], 16) + ', 0.2)' : '';
  }

  drop(event: any) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      // post API whit new status of action
      // to_do / in_progress /  done / archived
      if (event.container.id === 'inProgressList') {
        // move form ToDoList to InProgressList
        this.moveAction(event, 'in_progress');
      } else if (event.container.id === 'doneList') {
        // move form ToDoList to InProgressList
        this.moveAction(event, 'done');
      } else if (event.container.id === 'todolist') {
        // move form ToDoList to InProgressList
        this.moveAction(event, 'to_do');
      }else if (event.container.id === 'archiveList') {
        // move form ToDoList to InProgressList
        this.moveAction(event, 'archived');
      }
    }
  }

  moveAction(event: any, status: string) {
    const task = event.previousContainer.data[event.previousIndex].teamTask;
    let message = '';
    switch (status) {
      case 'to_do':
        message = 'Great. The action was set in ToDo section'
        break;
      case 'in_progress':
        message = 'Great. The action was moved to In Progress section'
        break;
      case 'done':
        message = 'Great. The action was completed'
        break;
      case 'archived':
        message = 'Great. The action was archived for later'
        break;
    }

    if (this.data.showDemo) {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    } else {
      // move form ToDoList to InProgressList
      this.data.showLoading();
      this.api.patchTask(task.uuid, status, false, this.currentTeamUuid, '').subscribe(
        (res: any) => {
          switch (event.previousContainer.id) {
            case 'todolist': 
              if(this.challengesService.myProblemsTasks[event.previousIndex]['teamTask']['uuid'] === task.uuid) {
                //@ts-ignore
                this.challengesService.myProblemsTasks[event.previousIndex]['teamTask']['status'] = res.status;
              }
              break;
            case 'inProgressList':
              if(this.challengesService.myInProgressTasks[event.previousIndex]['teamTask']['uuid'] === task.uuid) {
                //@ts-ignore
                this.challengesService.myInProgressTasks[event.previousIndex]['teamTask']['status'] = res.status;
              }
              break;
            case 'doneList':
              if(this.challengesService.myCompletedTasks[event.previousIndex]['teamTask']['uuid'] === task.uuid) {
                //@ts-ignore
                this.challengesService.myCompletedTasks[event.previousIndex]['teamTask']['status'] = res.status;
              }
              break;
            case 'archiveList':
              if(this.challengesService.myArchivedTasks[event.previousIndex]['teamTask']['uuid'] === task.uuid) {
                //@ts-ignore
                this.challengesService.myArchivedTasks[event.previousIndex]['teamTask']['status'] = res.status;
              }
              break;
          }
          
          transferArrayItem(
            event.previousContainer.data,
            event.container.data,
            event.previousIndex,
            event.currentIndex,
          );
          this.data.hideLoading();
        }, (err: any) => {
          console.log(err);
          this.data.hideLoading();
        })
    }
  }

  changeAction(status: any, selectedAction: any) {
    let previousContainer: string;
    let actionPreviousIndex: number;
    let currentContainer : string;
    // const closeModal = document.getElementById('closeActioneModal');
    switch (selectedAction.status) {
      case 'to_do':
        previousContainer = 'to_do';
        this.challengesService.myProblemsTasks.forEach((task: any, index: any) => {
          if(task.teamTask.uuid === selectedAction.uuid) {
            actionPreviousIndex = index;
          } else if (task.uuid === selectedAction.uuid) {
            actionPreviousIndex = index;
          }
        });
        break;
      case 'in_progress':
        previousContainer = 'in_progress';
        this.challengesService.myInProgressTasks.forEach((task: any, index: any) => {
          if(task.teamTask.uuid === selectedAction.uuid) {
            actionPreviousIndex = index;
          }
        });
        break;
      case 'done':
        previousContainer = 'done';
        this.challengesService.myCompletedTasks.forEach((task: any, index: any) => {
          if(task.teamTask.uuid === selectedAction.uuid) {
            actionPreviousIndex = index;
          }
        });
        break;
      case 'archived':
        previousContainer = 'archived';
        this.challengesService.myArchivedTasks.forEach((task: any, index: any) => {
          if(task.teamTask.uuid === selectedAction.uuid) {
            actionPreviousIndex = index;
          }
        });
        break;
    }

    switch (status.type) {
      case 'to_do':
        //@ts-ignore
        currentContainer = 'to_do';
        break;
      case 'in_progress':
        //@ts-ignore
        currentContainer = 'in_progress';
        break;
      case 'done':
        //@ts-ignore
        currentContainer = 'done';
        break;
      case 'archived':
        //@ts-ignore
        currentContainer = 'archived';
        break;
    }
    this.data.showLoading();
    this.api.patchTask(selectedAction.uuid, status.type, false, this.currentTeamUuid, '').subscribe(
      (res: any) => {
        switch (previousContainer) {
          case 'to_do': 
            if(this.challengesService.myProblemsTasks[actionPreviousIndex]['teamTask']['uuid'] === selectedAction.uuid) {
              //@ts-ignore
              this.challengesService.myProblemsTasks[actionPreviousIndex]['teamTask']['status'] = res.status;
            }
            break;
          case 'in_progress':
            if(this.challengesService.myInProgressTasks[actionPreviousIndex]['teamTask']['uuid'] === selectedAction.uuid) {
              //@ts-ignore
              this.challengesService.myInProgressTasks[actionPreviousIndex]['teamTask']['status'] = res.status;
            }
            break;
          case 'done':
            if(this.challengesService.myCompletedTasks[actionPreviousIndex]['teamTask']['uuid'] === selectedAction.uuid) {
              //@ts-ignore
              this.challengesService.myCompletedTasks[actionPreviousIndex]['teamTask']['status'] = res.status;
            }
            break;
          case 'archived':
            if(this.challengesService.myArchivedTasks[actionPreviousIndex]['teamTask']['uuid'] === selectedAction.uuid) {
              //@ts-ignore
              this.challengesService.myArchivedTasks[actionPreviousIndex]['teamTask']['status'] = res.status;
            }
            break;
        }
        transferArrayItem(
          previousContainer === 'to_do' ? this.challengesService.myProblemsTasks : 
          previousContainer === 'in_progress' ? this.challengesService.myInProgressTasks : 
          previousContainer === 'done' ? this.challengesService.myCompletedTasks : this.challengesService.myArchivedTasks,
          currentContainer === 'to_do' ? this.challengesService.myProblemsTasks : 
          currentContainer === 'in_progress' ? this.challengesService.myInProgressTasks : 
          currentContainer === 'done' ? this.challengesService.myCompletedTasks : this.challengesService.myArchivedTasks,
          actionPreviousIndex,
          0,
        );
        this.data.hideLoading();
        // document.getElementById('closeActioneModal')?.click();
      }, (err: any) => {
        console.log(err);
        this.data.hideLoading();
      });
  }

  setMemberActivityColor(activity: string) {
    switch (activity) {
      case this.translate.instant('team_dashboard_member_activity_high'):
        return {'background-color': '#E5F8F2', color: '#00BD82'};
      case this.translate.instant('team_dashboard_member_activity_mod'):
        return {'background-color': '#FFF6E9', color: 'rgb(249,167,41)'};
      case this.translate.instant('team_dashboard_member_activity_low'):
        return {'background-color': '#FFEBF1', color: '#FF3C78'};
      case this.translate.instant('team_dashboard_member_activity_holiday'):
        return {'background-color': '#F2F5FC', color: '#2A56C1'};
      case this.translate.instant('team_dashboard_member_activity_pending'):
        return {'z-index': '1', 'background-color': '#FFFFFF', color: '#6D7FA2'};
      default: {
        return {color: 'red'};
      }
    }
  }

  //@ts-ignore
  sendReminderMember(memberUuid) {
    this.data.showLoading();
    // @ts-ignore
    this.api.sendTeamMemberReminder(this.currentTeamUuid, memberUuid).subscribe(
      res => {
        this.data.hideLoading();
        this.data.showAlert('Reminder sent');
      }, err => {
        this.data.hideLoading();
      }
    );
  }

  getPollingTimeRemaining(endDate: any) {
    if (endDate !== null) {
      const today = new Date();
      const expireDate = new Date(endDate.slice(5, 7) + '/' + endDate.slice(8, 10) + '/' + endDate.slice(0, 4));
      return Math.floor((Date.UTC(expireDate.getFullYear(), expireDate.getMonth(), expireDate.getDate()) - Date.UTC(today.getFullYear(), today.getMonth(), today.getDate())) / (1000 * 60 * 60 * 24));
    } else {
      return 0
    }

  }

  // @ts-ignore
  getStatementIndex(statement: any, type: string) {
    if (type === 'for_counter') {
      //@ts-ignore
      return this.worstStatements.indexOf(statement, 0) + 1;
    } else if (type === 'for_task') {
      //@ts-ignore
      return this.worstStatements.indexOf(statement, 0);
    }
  }

  showCollector(start: boolean) {
    // you need at least 4 members to start a colector
    if (this.teamMembers.length - this.teamAdmins.length >= 4) {
      // start colector
      this.data.collectorTeams = [this.team];
      this.data.openFromTeam = true;
      this.data.editCollector = start;
      // add subs for loop to take the frequency for current loop
      if (start) {
        if(!this.data.editTeamCollector) {
          this.data.editTeamCollector = this.currentPolling;
        }
        this.data.setShowCollector(true);
      } else {
        this.data.setShowCollector(false);
      }
      this.modal.openCollectorModal()
    }
  }

  showAction(action: any, statement: any) {
    this.actionModal = action;
    this.statementModal = statement;
    const openModal = document.getElementById('actionModalButton');
    openModal?.click();
    this.showModal = true;
    this.showModalWithStaments = false;
  }

  showActionWithStatements(item: any) {
    this.data.showLoading();
    this.actionModal = item;
    this.selectedActionStatus = {type: item.status};
    this.api.getTaskStatements(item.uuid, this.team.uuid).subscribe(
      res => {
        this.taskStatements = res;
        this.data.hideLoading();
        const openModal = document.getElementById('actionModalButton');
        openModal?.click();
        this.showModal = true;
        this.showModalWithStaments = true;
      }, error => {
        console.log(error);
        this.data.hideLoading();
      }
    );
  }

  openOwnActionModal(statement: any) {
    this.ownActionStatement = statement;
    const openModal = document.getElementById('ownActionButton');
    openModal?.click();
    setTimeout(() => {
      document.getElementById('actionTitleModal')?.focus();
    }, 500)
  }

  pauseCollector() {
    this.data.showLoading();
    // check the polling status if active -> paused (<=>)
    let status = '';
    if (this.currentPolling.status === 'active') {
      status = 'paused'
    } else if (this.currentPolling.status === 'paused') {
      this.data.pauseCollector = true;
      this.data.hideLoading();
      this.data.editTeamCollector = this.currentPolling;
      // if the loop is paused show the create feedback but with the resume stuff
      this.showCollector(false);
      this.data.setShowCollector(true);
      return;
    }

    this.api.patchActivePolling(this.currentPolling.uuid, this.currentTeamUuid, {status}).subscribe(
      res => {
        this.getPolling(true);
        this.data.hideLoading()
      }, err => {
        console.log(err)
        this.data.hideLoading()
      }
    )
  }

  getCurrentStatements(poll: any) {
    if (poll.length > 0) {
      this.api.getQuestionsPoll(this.currentTeamUuid, poll[0].uuid).subscribe(
        res => {
          this.currentStatements = res;
        }, err => {
          console.log(err);
        }
      )
    }
  }

  openStatementsModal() {
    // @ts-ignore
    document.getElementById('statementsModal').click();
  }

  getTeamPoll() {
    this.getUserImmediateLeader();
    this.data.setMenuPoll(true);
  }

  getUserCurrentImmediateLeader() {
    this.api.getUserImmediateLeaderSuggestions(this.currentTeamUuid).subscribe(
      res => {
        this.gotImmediateLeaders = true;
        this.isOnlyOneImmLeader = (res['teamLeaders'].length <= 1 && (res['workspaceAdmins'] && res['otherTeamsLeaders']));
        this.data.immediateLeadersFromTeam = res['teamLeaders'];
        this.data.immediateLeadersOutsideTeam = res['workspaceAdmins'];
        this.data.otherImmediateLeaders = res['otherTeamsLeaders'];
      });
  }

  

  getUserImmediateLeader() {
    this.teamMembers.forEach((el: any) => {
      if(el.teamMember.user.uuid === this.user.uuid) {
        this.data.currentUserImmediateLeader = el.immediateLeader;
        this.data.currentUserTeamMemberUuid = el.teamMember.uuid;
      }
    })
  }


  showTeamInsights() {
    if (this.myTeamRole > 10) {
      if (this.data.wizardOn) {
        this.router.navigate(['/team-analyse/' + this.currentTeamUuid], {queryParams: {start_tour: environment.teamInsightWizard}});
      } else {
        this.router.navigate(['/team-analyse/' + this.currentTeamUuid]);
      }
    }
  }

  notRelevant(task: any, statement: any) {
    const closeModal = document.getElementById('closeActioneModal');
    this.data.showLoading();
    this.api.patchTask(task.uuid, 'useless', false, this.currentTeamUuid, statement.uuid).subscribe(
      (res: any) => {
        this.data.hideLoading();
        this.worstStatements.forEach((el: any, index: number) => {
          el.showTasks.forEach((notRelevantTask: any, taskIndex: number) => {
            if (task.uuid === notRelevantTask.task.uuid) {
              //@ts-ignore
              this.worstStatements[index].tasks.splice(taskIndex, 1);
              // @ts-ignore
              this.worstStatements[index].showTasks.splice(taskIndex, 1);
              closeModal?.click();
            }
          });
        });
        this.getWorstStatements(this.worstStatemstLastPoll);
      }, () => {
        this.data.hideLoading();
      }
    );
  }

  openDownloadReportModal() {
    document.getElementById('downloadReport')?.click();
    setTimeout(() => {
      document.getElementById('startDatePiker')?.click();
      document.getElementById('startDatePiker')?.focus();
      this.showCalendar = true;
    }, 1000)
  }

  notifyTeam() {
    this.data.showLoading();
    // @ts-ignore
    this.api.sendTeamReminder(this.currentTeamUuid).subscribe(
      res => {
        this.data.hideLoading();
        this.data.showAlert('Reminder sent');
      }, err => {
        this.data.hideLoading();
        this.data.showAlertError('Error on sending reminder')
      }
    );
  }

  getModalDate() {
    if (this.startDate === null) {
      this.data.showAlertError('Please select the first date')
    } else if (this.endDate === null) {
      this.data.showAlertError('Please select a second date')
    } else {
      this.reportURL = this.baseUrl + '/team-indicators-export?startDate=' + formatDate(this.startDate, 'dd-MM-yyyy', 'en-US') + '&endDate=' + formatDate(this.endDate, 'dd-MM-yyyy', 'en-US');
      this.data.showLoading();
      this.api.getTeamIndicatorsExport(formatDate(this.startDate, 'dd-MM-yyyy', 'en-US'), formatDate(this.endDate, 'dd-MM-yyyy', 'en-US'), this.currentTeamUuid).subscribe(
        res => {
          this.reportURL = res.file;
          setTimeout(() => {
            document.getElementById('exportButtonDownload')?.click()
            document.getElementById('closeDownloadReportModal')?.click()
            this.data.hideLoading();
          }, 500);
        }, err => {
          console.log(err);
          this.data.showAlertError('Error on exporting the data')
        }
      );
    }
  }

  closeCreateAction() {
    this.challengesService.createActionForm.reset();
  }

  goToTeamActions() {
    if (!this.data.showDemo) {
      this.router.navigate(['/team-actions/' + this.currentTeamUuid])
    }
  }

  getBellPulse() {
    if (!this.showPolling || !this.showReport) {
      return false;
    } else if (this.currentPolling.pollingFrequency.type === 'weekly' && this.getPollingTimeRemaining(this.currentPolling['cronNextRun']['date']) < 4 && this.team.statisticsStatus?.percentage <= 50) {
      return true
    } else if (this.currentPolling.pollingFrequency.type === '14_days' && this.getPollingTimeRemaining(this.currentPolling['cronNextRun']['date']) < 8 && this.team.statisticsStatus?.percentage <= 50) {
      return true
    } else return this.currentPolling.pollingFrequency.type === 'monthly' && this.getPollingTimeRemaining(this.currentPolling['cronNextRun']['date']) < 20 && this.team.statisticsStatus?.percentage <= 50;
  }

  bellAnimation(event: any, index: number) {
    const bellButton = document.getElementById('bell-button-' + index);
    //@ts-ignore
    bellButton?.style.transform = 'scale(.9)';

    setTimeout(() => {
      //@ts-ignore
      bellButton?.style.transform = 'scale(1)';
    }, 300)
  }

  // showLatestActionModal(item: any) {
  //   this.data.showLoading()
  //   this.actionModal = item;
  //   this.api.getTaskStatements(item.uuid, this.team.uuid).subscribe(
  //     res => {
  //       this.taskStatements = res;
  //       this.data.hideLoading();
  //       const openModal = document.getElementById('actionModalButton');
  //       openModal?.click();
  //       this.showModal = true;
  //     }, error => {
  //       console.log(error);
  //       this.data.hideLoading();
  //     }
  //   );
  // }
  clicking(){
    this.api.updateWorkspaceUser(this.data.userLink.uuid,{teamLeaderOnboardingStep: 'step_2'}).subscribe(
      res=>{
        this.data.userLink = res;
      }
    )
  }

  showWizard() {
    if (this.myTeamRole > 10 && this.team.pollsCount < 3 && !this.data.userLink.teamLeaderWizard) {
      this.api.updateWorkspaceUser(this.data.userLink.uuid,{teamLeaderWizard: !this.data.userLink.teamLeaderWizard}).subscribe(
        res=>{
          this.data.userLink = res;
        }
      )
      this.data.wizardOn = true;
      const urlAdd: string = environment.teamWizard;
      if (this.activeRoute.snapshot.queryParams['start_tour'] === undefined) {
        this.router.navigate([this.router.url], {queryParams: {start_tour: urlAdd}});
        setTimeout(() => {
          location.reload();
        }, 200)
      }
    }
  }

  changeActions(category: string) {
    this.getTeamTasks(this.currentTeamUuid, this.teamTasksType, 1, category);
  }

  sortMembersByImmediateLeader(sortByLeader: any) {
    if(sortByLeader) {
      this.sortByImmLeaders = true;
    } else {
      this.sortByImmLeaders = false;
    }
  }

  changePreviousLoopYearIndex(event: any) {
    this.previousLoopIndexPage = event.page;
  }

  changePoll(poll: any) {
    this.showPreviousLoopDd = false;
    this.selectedPreviousPoll = poll;
    this.data.showLoading();
    this.getWorstStatements(poll);
    setTimeout(() => {
      this.data.hideLoading();
    }, 1000);
  }

  showPreviousDropdown(event: any) {
    event.stopPropagation();
    this.showPreviousLoopDd = !this.showPreviousLoopDd;
  }

  testing(event: any) {
    this.worstStatementsCarouselPage = event.page;
    this.getStatementResponseDistribution(event.page).subscribe((res: any) => {
      if (res) {
        this.getResponseDistributionValues(res);
      }
    });
  }
}
