import {LabelType, Options} from '@angular-slider/ngx-slider';
import {moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import {formatDate} from '@angular/common';
import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {EChartsOption} from 'echarts';
import {Subscription} from 'rxjs';
import {ModalsComponent} from 'src/app/components/modals/modals.component';
import {ApiwrapperService} from 'src/app/services/apiwrapper.service';
import {ChallengesService} from 'src/app/services/challenges.service';
import {DataManipulationService} from 'src/app/services/data-manipulation.service';
import {Block} from 'notiflix/build/notiflix-block-aio';
import demo from "../../../assets/data/demo.json"
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-workspace-overview',
  templateUrl: './workspace-overview.component.html',
  styleUrls: ['./workspace-overview.component.scss']
})
export class WorkspaceOverviewComponent implements OnInit {
  knobProgress: number = 0;
  workspaceTeams: any = [];
  showIndicators: boolean = false;
  dropdownOptions: { name: string, value: number }[];
  teamSortType = '';
  teamScoreSelector = 0;

  optionPie: EChartsOption = {
    tooltip: {
      trigger: 'none'
    },
    series: [
      {
        name: 'Access From',
        type: 'pie',
        radius: '50%',
        avoidLabelOverlap: false,
        label: {
          show: true,
        },

        color: ['#F78C02', '#1AC487'],
        emphasis: {
          label: {
            show: true,
            fontSize: 20,
            fontFamily: 'Poppins',
            fontWeight: 'bold'
          }
        },
        labelLine: {
          show: false
        },
        data: [
          {value: 0, name: 'Pending'},
          {value: 0, name: 'Active'}
        ]
      }
    ]
  };
  chartOption: EChartsOption = {
    xAxis: {
      type: 'category',
      axisLabel: {
        color: '#000000',
        fontWeight: 400,
      },
      data: [],
    },
    yAxis: {
      type: 'value',
      max: 100,
      min: 0,
      axisLabel: {
        color: '#000000',
        fontWeight: 400,
      },
    },
    tooltip: {
      trigger: 'axis'
    },
    series: [
      {
        name: 'Success Score',
        label: {
          // @ts-ignore
          normal: {
            show: false,
            position: 'top'
          },
        },
        data: [],
        type: 'line',
        symbolSize: 10,
        smooth: true,
        color: '#19AEFF',
        // emphasis: {
        //   focus: 'series'
        // },
        tooltip: {
          trigger: 'item',
        },
      },
      {
        name: 'Response Rate',
        label: {
          // @ts-ignore
          normal: {
            show: false,
            position: 'top'
          },
        },
        data: [],
        tooltip: {
          trigger: 'axis',
        },
        type: 'line',
        symbolSize: 0,
        lineStyle: {
          color: '#FF764C',
          width: 2,
        },
        smooth: true,
        color: '#FF764C',
        // emphasis: {
        //   focus: 'series'
        // },
      },
    ],
  };
  indicator = {
    successteamScore: '../../../assets/svg/overall-white.svg',
    successteamScoreChart: '../../../assets/svg/overall-lightblue.svg',
    performance: '../../../assets/svg/performance-white.svg',
    performanceChart: '../../../assets/svg/performance-icon-lightblue.svg',
    motivation: '../../../assets/svg/motivation-white.svg',
    motivationChart: '../../../assets/svg/motivation-icon-lightblue.svg',
    satisfaction: '../../../assets/svg/satisfaction-white.svg',
    satisfactionChart: '../../../assets/svg/satisfaction-icon-lightblue.svg',
    stress: '../../../assets/svg/stress-white.svg',
    stressChart: '../../../assets/svg/stress-icon-lightblue.svg'
  };

  indicatorBackground = {
    Performance: this.data.colors.greyGradient,
    Motivation: this.data.colors.greyGradient,
    Satisfaction: this.data.colors.greyGradient,
    Stress: this.data.colors.greyGradient,
  };

  showPie: boolean = true;
  members = {
    total: 0,
    active: 0,
    pending: 0
  }
  totalTeams = 0;
  showTrends: boolean = false;
  chartType: string = '';
  showChart: boolean = true;

  scoreColorInterval = {
    "red": {"min": 0, "max": 39},
    "orange": {"min": 40, "max": 59},
    "yellow": {"min": 60, "max": 79},
    "green": {"min": 80, "max": 100}
  };
  knobColor: string = this.data.colors.grey;

  workspaceResults: any = {
    latestReport: {
      Motivation: 0,
      Performance: 0,
      Satisfaction: 0,
      Stress: 0,
      responseRate: 0,
      workspaceSuccessteamScore: 0
    },
    previousReport: {
      Motivation: 0,
      Performance: 0,
      Satisfaction: 0,
      Stress: 0,
      responseRate: 0,
      workspaceSuccessteamScore: 0
    }
  };
  private graph: any;
  public graphType = {
    type: '',
    title: '',
    showResponse: true
  };
  public gotAlert: boolean = false;
  public workspaceAlerts: any[] = [];
  gotMultipleAlerts: boolean = false;
  language = '';
  eligibleForTrends: any;
  workspaceTrends: any;
  workspaceCurrentTrendReport: any;
  workspacePreviousTrendReport: any;
  workspaceNextTrendReport: any;
  trendsProgressBarWidth: any;
  selectedTrendDate: any;
  progressBarPercentage: any;
  trendReportDaysRemaining: any;
  hideCover: boolean = false;
  startDateForNextReport: any;
  testTrend = [
    {
        "uuid": "3cc92a5c-d4c6-11ef-9180-00155d71c8f7",
        "startDate": {
            "date": "2024-10-19 00:13:36.000000",
            "timezone_type": 3,
            "timezone": "UTC"
        },
        "endDate": {
            "date": "2025-01-17 00:13:36.000000",
            "timezone_type": 3,
            "timezone": "UTC"
        },
        "topTrends": [
            {
                "uuid": "3cc96170-d4c6-11ef-8eeb-00155d71c8f7",
                "category": {
                    "uuid": "55335ef6-9934-11ee-9f34-00155d0b3f72",
                    "title": "Psychological Safety",
                    "description": "This trend includes <i>honest and open communication</i>, tolerance for mistakes, and a culture that encourages team members to be themselves without fear of judgment. As well as feeling safe to speak up without the fear of retaliation from the team or leader. It signifies a workplace where individuals feel secure to voice concerns and engage in honest discussions about, for example, workload or stress with both peers and leaders.",
                    "status": "active",
                    "type": "general",
                    "created": {
                        "date": "2023-12-12 21:20:54.000000",
                        "timezone_type": 3,
                        "timezone": "UTC"
                    }
                },
                "score": 93.17
            },
            {
                "uuid": "3cc9626a-d4c6-11ef-b6c7-00155d71c8f7",
                "category": {
                    "uuid": "54bb8d9a-9934-11ee-b1bc-00155d0b3f72",
                    "title": "Inclusion, Diversity & Equality",
                    "description": "The trend highlights your team's sentiments regarding <i>fairness, respect, and inclusivity</i>. It includes various aspects such as <i>fairness in promotions and rewards</i>, and embracing differences. It delves into team dynamics, such as everyone being included in discussions, and if your team members feel like they belong and are respected. It also includes the effect of leadership, such as efforts in promoting inclusion, fair treatment, and consideration of diverse working conditions.",
                    "status": "active",
                    "type": "general",
                    "created": {
                        "date": "2023-12-12 21:20:53.000000",
                        "timezone_type": 3,
                        "timezone": "UTC"
                    }
                },
                "score": 92.19
            },
            {
                "uuid": "3cc962f6-d4c6-11ef-a0ee-00155d71c8f7",
                "category": {
                    "uuid": "554737f0-9934-11ee-81d8-00155d0b3f72",
                    "title": "Recognition & Acknowledgement",
                    "description": "The trend captures the team's experience with <i>acknowledgment, celebration, and recognition</i>. It explores how the team marks important days, celebrates successes, and expresses gratitude. The trend delves into the team's perception of feeling valued and recognized, both by peers and leadership. Such as, how well the leader remembers and acknowledges team members, actively praises accomplishments, and appreciates individual work efforts.",
                    "status": "active",
                    "type": "general",
                    "created": {
                        "date": "2023-12-12 21:20:54.000000",
                        "timezone_type": 3,
                        "timezone": "UTC"
                    }
                },
                "score": 84.77
            }
        ],
        "botTrends": [
            {
                "uuid": "3cc96364-d4c6-11ef-90d7-00155d71c8f7",
                "category": {
                    "uuid": "5501794a-9934-11ee-a2b4-00155d0b3f72",
                    "title": "Meaning, Values & Purpose",
                    "description": "The trend focuses on your <i>team's alignment</i> with <i>individual, team, and company values and goals</i>. It furthermore sheds light on the team's feelings towards their work being meaningful and understanding of the company's vision, and mission, and how team members feel they are a part of creating <i>success</i>.",
                    "status": "active",
                    "type": "general",
                    "created": {
                        "date": "2023-12-12 21:20:53.000000",
                        "timezone_type": 3,
                        "timezone": "UTC"
                    }
                },
                "score": 76.68
            },
            {
                "uuid": "3cc963c8-d4c6-11ef-a898-00155d71c8f7",
                "category": {
                    "uuid": "55653246-9934-11ee-b5b2-00155d0b3f72",
                    "title": "Skills & Development",
                    "description": "The trend encompasses the team's evaluation of its <i>collective competencies and individual skillsets</i>. It reflects the team's awareness of each other's skills, the balance of skills within the team, and the overall competence level. Additionally, it includes individual aspects such as satisfaction with <i>training and development opportunities</i>, alignment of talents with tasks, self-confidence in performance, and satisfaction with the given tasks. It also includes the leader's role in providing necessary training, awareness of individual preferences, and opportunities for growth and mentoring.",
                    "status": "active",
                    "type": "general",
                    "created": {
                        "date": "2023-12-12 21:20:54.000000",
                        "timezone_type": 3,
                        "timezone": "UTC"
                    }
                },
                "score": 75.64
            },
            {
                "uuid": "3cc9642c-d4c6-11ef-a4a2-00155d71c8f7",
                "category": {
                    "uuid": "550b7de6-9934-11ee-ae2d-00155d0b3f72",
                    "title": "Motivation & Engagement",
                    "description": "This trend highlights factors such as <i>goals, deadlines and tasks, relationships</i> with the <i>team and the leaders</i>. It highlights people's perception of <i>investment in employee growth and development</i>. Furthermore, the trend addresses the team's active engagement and presence, both in their work and in the work environment.",
                    "status": "active",
                    "type": "general",
                    "created": {
                        "date": "2023-12-12 21:20:53.000000",
                        "timezone_type": 3,
                        "timezone": "UTC"
                    }
                },
                "score": 71.72
            }
        ],
        "_links": {
            "self": {
                "href": "https://api.successteam.io/workspace-trends"
            }
        }
    },
    {
        "uuid": "bb77c1d6-d27f-11ef-984f-00155d50d2db",
        "startDate": {
            "date": "2024-07-21 13:13:36.000000",
            "timezone_type": 3,
            "timezone": "UTC"
        },
        "endDate": {
            "date": "2024-10-19 13:13:36.000000",
            "timezone_type": 3,
            "timezone": "UTC"
        },
        "topTrends": [
            {
                "uuid": "bb77c744-d27f-11ef-8d5f-00155d50d2db",
                "category": {
                    "uuid": "549d5d34-9934-11ee-a9ad-00155d0b3f72",
                    "title": "Fun & Joyful Culture",
                    "description": "This trend highlights the part of the <i>workplace culture</i> where your team has an appropriate sense of humor and a happy vibe within their team. That they have <i>positive relationships</i>, enjoy their work, and feel <i>happy</i>. <i>Fun and joy</i> are essential human needs, as much in adult life as in childhood, and giving it space in the organization will create a more sustainable culture. It doesn't mean everything is always fun and games, but that there is an effort put into cultivating fun and joy and positive relationships. That you work towards being <i>happy at work</i>. Not just being at work.",
                    "status": "active",
                    "type": "general",
                    "created": {
                        "date": "2023-12-12 21:20:53.000000",
                        "timezone_type": 3,
                        "timezone": "UTC"
                    }
                },
                "score": 89.17
            },
            {
                "uuid": "bb77c7da-d27f-11ef-b92b-00155d50d2db",
                "category": {
                    "uuid": "55335ef6-9934-11ee-9f34-00155d0b3f72",
                    "title": "Psychological Safety",
                    "description": "This trend includes <i>honest and open communication</i>, tolerance for mistakes, and a culture that encourages team members to be themselves without fear of judgment. As well as feeling safe to speak up without the fear of retaliation from the team or leader. It signifies a workplace where individuals feel secure to voice concerns and engage in honest discussions about, for example, workload or stress with both peers and leaders.",
                    "status": "active",
                    "type": "general",
                    "created": {
                        "date": "2023-12-12 21:20:54.000000",
                        "timezone_type": 3,
                        "timezone": "UTC"
                    }
                },
                "score": 88.7
            },
            {
                "uuid": "bb77c83e-d27f-11ef-8c09-00155d50d2db",
                "category": {
                    "uuid": "55794d58-9934-11ee-a656-00155d0b3f72",
                    "title": "Team Support & Collaboration",
                    "description": "The trend evaluates how well the <i>team collaborates</i> and supports each other. It examines the <i>team's effectiveness</i> in solving problems, sharing knowledge, and being able to provide mutual support when needed. The trend also looks at how the team handles challenges and shares responsibilities when working together.",
                    "status": "active",
                    "type": "general",
                    "created": {
                        "date": "2023-12-12 21:20:54.000000",
                        "timezone_type": 3,
                        "timezone": "UTC"
                    }
                },
                "score": 88.48
            }
        ],
        "botTrends": [
            {
                "uuid": "bb77c898-d27f-11ef-b1e1-00155d50d2db",
                "category": {
                    "uuid": "54cf9718-9934-11ee-b9c9-00155d0b3f72",
                    "title": "Leadership Communication",
                    "description": "The trend provides a view of the team's perception of their <i>leader's communication style and effectiveness</i>. It incorporates various dimensions, including the leader's <i>motivational talks, transparent communication, timely feedback</i>, and <i>communication of change</i>. It also highlights the quality of check-ins and 1-on-1 meetings, along with the leader's respectful communication, receptiveness to feedback, and clarity in expressing goals, values, and important information.",
                    "status": "active",
                    "type": "general",
                    "created": {
                        "date": "2023-12-12 21:20:53.000000",
                        "timezone_type": 3,
                        "timezone": "UTC"
                    }
                },
                "score": 76.03
            },
            {
                "uuid": "bb77c8e8-d27f-11ef-9b51-00155d50d2db",
                "category": {
                    "uuid": "55295f3c-9934-11ee-a0d2-00155d0b3f72",
                    "title": "Processes",
                    "description": "This trend revolves around the effectiveness of the <i>team's processes</i> and overall <i>work structure.</i> It highlights among others the ability to maintain <i>clear and relevant meetings</i> and agendas, as well as <i>establishing routines and communication channels</i> that support progress and performance. It also highlights definitions of how you work as a team, which is especially important in remote or hybrid team settings.",
                    "status": "active",
                    "type": "general",
                    "created": {
                        "date": "2023-12-12 21:20:54.000000",
                        "timezone_type": 3,
                        "timezone": "UTC"
                    }
                },
                "score": 75.69
            },
            {
                "uuid": "bb77c938-d27f-11ef-89c4-00155d50d2db",
                "category": {
                    "uuid": "55653246-9934-11ee-b5b2-00155d0b3f72",
                    "title": "Skills & Development",
                    "description": "The trend encompasses the team's evaluation of its <i>collective competencies and individual skillsets</i>. It reflects the team's awareness of each other's skills, the balance of skills within the team, and the overall competence level. Additionally, it includes individual aspects such as satisfaction with <i>training and development opportunities</i>, alignment of talents with tasks, self-confidence in performance, and satisfaction with the given tasks. It also includes the leader's role in providing necessary training, awareness of individual preferences, and opportunities for growth and mentoring.",
                    "status": "active",
                    "type": "general",
                    "created": {
                        "date": "2023-12-12 21:20:54.000000",
                        "timezone_type": 3,
                        "timezone": "UTC"
                    }
                },
                "score": 70.16
            }
        ],
        "_links": {
            "self": {
                "href": "https://api.successteam.io/workspace-trends"
            }
        }
    },
    {
        "uuid": "a80721fc-d27d-11ef-a27e-00155d50d2db",
        "startDate": {
            "date": "2024-04-22 13:13:36.000000",
            "timezone_type": 3,
            "timezone": "UTC"
        },
        "endDate": {
            "date": "2024-07-21 13:13:36.000000",
            "timezone_type": 3,
            "timezone": "UTC"
        },
        "topTrends": [
            {
                "uuid": "a80762c0-d27d-11ef-ac3c-00155d50d2db",
                "category": {
                    "uuid": "54c5a4b0-9934-11ee-9d44-00155d0b3f72",
                    "title": "Influence, Accountability & Ownership",
                    "description": "The trend captures the team's views on <i>responsibility, autonomy, and influence</i>. It reflects a sense of <i>individual and collective accountability</i>, emphasizing task ownership and learning from mistakes. The trend also gauges feelings about influence, trust, and freedom granted by the leader. It encompasses idea contribution, responsiveness to suggestions, and the overall <i>creative atmosphere</i>.",
                    "status": "active",
                    "type": "general",
                    "created": {
                        "date": "2023-12-12 21:20:53.000000",
                        "timezone_type": 3,
                        "timezone": "UTC"
                    }
                },
                "score": 93.24
            },
            {
                "uuid": "a80763ba-d27d-11ef-8a1b-00155d50d2db",
                "category": {
                    "uuid": "558d43c6-9934-11ee-9437-00155d0b3f72",
                    "title": "Work Efficiency & delivery",
                    "description": "The trend focuses on the team's ability and conditions to efficiently accomplish their tasks and deliveries. It describes aspects such as <i>effective collaboration and goal-setting, clear and relevant meetings,</i> the ability to <i>act on agreed-upon issues,</i> and the right balance between working individually and collaborating as a team. It also includes <i>technologies and communication channels</i> that help your team work effectively.",
                    "status": "active",
                    "type": "general",
                    "created": {
                        "date": "2023-12-12 21:20:54.000000",
                        "timezone_type": 3,
                        "timezone": "UTC"
                    }
                },
                "score": 80.76
            },
            {
                "uuid": "a807643c-d27d-11ef-8f75-00155d50d2db",
                "category": {
                    "uuid": "550b7de6-9934-11ee-ae2d-00155d0b3f72",
                    "title": "Motivation & Engagement",
                    "description": "This trend highlights factors such as <i>goals, deadlines and tasks, relationships</i> with the <i>team and the leaders</i>. It highlights people's perception of <i>investment in employee growth and development</i>. Furthermore, the trend addresses the team's active engagement and presence, both in their work and in the work environment.",
                    "status": "active",
                    "type": "general",
                    "created": {
                        "date": "2023-12-12 21:20:53.000000",
                        "timezone_type": 3,
                        "timezone": "UTC"
                    }
                },
                "score": 75.95
            }
        ],
        "botTrends": [
            {
                "uuid": "a807654a-d27d-11ef-b9aa-00155d50d2db",
                "category": {
                    "uuid": "555b3566-9934-11ee-a30c-00155d0b3f72",
                    "title": "Salary, Compensation & Benefits",
                    "description": "The trend encompasses satisfaction with <i>salary, compensation for overtime</i>, and overall benefits. The trend highlights the leader's communication about <i>salary</i>, assurance that employees get benefits, and equality in benefits across different roles or working settings, for example, equality in remote and on-site benefits.",
                    "status": "active",
                    "type": "general",
                    "created": {
                        "date": "2023-12-12 21:20:54.000000",
                        "timezone_type": 3,
                        "timezone": "UTC"
                    }
                },
                "score": 57.13
            }
        ],
        "_links": {
            "self": {
                "href": "https://api.successteam.io/workspace-trends"
            }
        }
    }
];

  constructor(private activeRoute: ActivatedRoute, public data: DataManipulationService, public router: Router,
              public challengesService: ChallengesService, public modal: ModalsComponent,
              public api: ApiwrapperService, private translate: TranslateService) {
                this.dropdownOptions = [
                  {name: this.translate.instant('wo_sort_today'), value: 0},
                  {name: this.translate.instant('wo_sort_30_ago'), value: 30},
                  {name: this.translate.instant('wo_sort_90_ago'), value: 90}
                ];
  }

  ngOnInit() {
    //@ts-ignore
    this.language = JSON.parse(localStorage.getItem('userDetails')).detail.preferredLanguage.tag;
    //@ts-ignore
    this.workspaceNextTrendReport = JSON.parse(localStorage.getItem('WORKSPACE')).nextTrendReportDate;
    this.getColors();
    this.teamSort('score');
    this.getWorkspaceMembers();
    this.getWorkspaceIndicators();
    this.getWorkspaceGraph();
    this.getWorkspaceTrends();
    this.getWorkspaceAlters();
    this.data.showLoading();
  }

  getColors() {
    this.api.getIndicatorInterval().subscribe(
      res => {
        //@ts-ignore
        res.forEach(el => {
          // @ts-ignore
          this.scoreColorInterval[el.indicatorColor.color].min = el.min;
          // @ts-ignore
          this.scoreColorInterval[el.indicatorColor.color].max = el.max;
        });
      }
    );
  }

  setKnobProgressBar() {
    if (document.getElementById("svgProgress")) {
      this.knobProgress = Math.round((184 - (184 * this.workspaceResults.latestReport.workspaceSuccessteamScore) / 100));
      //@ts-ignore
      document.getElementById("svgProgress").style.strokeDashoffset = this.knobProgress.toString();
    } else {
      setTimeout(() => {
        this.setKnobProgressBar()
      }, 200);
    }
  }

  getWorkspaceIndicators() {
    this.api.getWorkspaceIndicators().subscribe(
      res => {
        // get the workspace indicators data
        this.workspaceResults = res;
        this.sortColors();
      }
    )
  }

  sortColors() {
    Object.keys(this.workspaceResults.latestReport).forEach((el: any, index) => {
      Object.keys(this.scoreColorInterval).forEach((color: any) => {
        // @ts-ignore
        const interval = this.scoreColorInterval[color];
        const value = this.workspaceResults.latestReport[el];
        if (value >= interval.min && value <= interval.max) {
          if (el === 'workspaceSuccessteamScore') {
            // @ts-ignore
            this.knobColor = this.data.colors['' + color];
          } else {
            // @ts-ignore
            this.indicatorBackground['' + el] = this.data.colors['' + color + 'Gradient'].toString();
          }
        } else if (value === 0) {
          // @ts-ignore
          this.indicatorBackground['' + el] = this.data.colors['greyGradient'].toString();
          // @ts-ignore
        }
        
      })
    });
    this.showIndicators = true;
    this.data.hideLoading();
  }

  getWorkspaceGraph() {
    this.api.getWorkspaceGraph().subscribe(
      res => {
        this.graph = res;
        this.changeChart('score', 'Success Score', true);
      }
    )
  }

  getWorkspaceTrends() {
    this.api.getWorkspaceTrends().subscribe(
      (res: any) => {
        // this.workspaceTrends = res._embedded["workspace-trend-reports"];
        this.workspaceTrends = this.testTrend;
        this.workspaceTrends.dates = [];
        if (this.workspaceTrends.length) {
          this.workspaceCurrentTrendReport = this.workspaceTrends[0];
          this.workspaceCurrentTrendReport.botTrends = this.workspaceCurrentTrendReport?.botTrends.sort((a: any, b: any) => {
            return a.score < b.score ? -1:1;
          });
          if (this.workspaceTrends.length >= 2) {
            this.workspacePreviousTrendReport = this.workspaceTrends[1];
            this.getTrendsStatusAndScore();
          }
          this.formatTrendReportsDates();
          this.hideCover = true;
          this.showTrends = true;
        } else if (this.workspaceTrends.length === 0 && this.workspaceNextTrendReport) {
          this.getTrendsProgressAndDates(this.workspaceNextTrendReport.date);
          this.workspaceCurrentTrendReport = demo.trends;
        //this.eligibleForTrends = workspaceCurrentTrendReport.eligibleForNextReport;
          this.hideCover = false;
          this.showTrends = true;      
        }
      }
    );
  }

  getTrendsProgressAndDates(endDate: Date) {
    const today = new Date().getTime();
    const end = new Date(endDate).getTime();
    this.trendReportDaysRemaining = Math.floor((end - today) / 1000 / 60 / 60 / 24);
    this.progressBarPercentage = Math.floor(((90 - this.trendReportDaysRemaining) / 90) * 100) + '%';
    this.startDateForNextReport = new Date(new Date().setDate(new Date().getDate() - (90 - this.trendReportDaysRemaining)));
  }

  formatTrendReportsDates() {
    this.workspaceTrends.forEach((report: any, index: number) => {
      this.workspaceTrends.dates.push({date: formatDate(report.startDate.date, 'dd.MM.yyyy', 'en-US') + ' - ' + formatDate(report.endDate.date, 'dd.MM.yyyy', 'en-US'), index: index});
    });
    this.hideCover = true;
    this.showTrends = true;
  }

  getTrendsStatusAndScore() {
    const currentTrends = [...this.workspaceCurrentTrendReport.topTrends, ...this.workspaceCurrentTrendReport.botTrends];
    currentTrends.forEach((currentReport: any) => {
      let topTrend = this.searchInTopTrends(currentReport);
      let botTrend = this.searchInBotTrends(currentReport);
      if (topTrend.scorePreview !== '') {
        currentReport.scorePreview = topTrend.scorePreview;
      } else if (botTrend.scorePreview !== '') {
        currentReport.scorePreview = botTrend.scorePreview;
      } else {
        currentReport.isNew = true;
      }
    });
  }

  searchInTopTrends(currentReport: any) {
    let trendStats = {scorePreview: ''};
    this.workspacePreviousTrendReport.topTrends.forEach((trend: any) => {
      if (currentReport.category.uuid === trend.category.uuid) {
        trendStats = {scorePreview: currentReport.score > trend.score ? 'up' : currentReport.score < trend.score ? 'down' : 'even'};
      }
    });
    return trendStats;
  }
  searchInBotTrends(currentReport: any): any {
    let trendStats = {scorePreview: ''};
    this.workspacePreviousTrendReport.botTrends.forEach((trend: any) => {
      if (currentReport.category.uuid === trend.category.uuid) {
        trendStats = {scorePreview: currentReport.score > trend.score ? 'up' : currentReport.score < trend.score ? 'down' : 'even'};
      }
    });
    return trendStats;
  }

  changeTrendDate(event: any) {
    this.workspaceCurrentTrendReport = this.workspaceTrends[event.value.index];
    if (event.value.index + 1 < this.workspaceTrends.length) {
      this.workspacePreviousTrendReport = this.workspaceTrends[event.value.index + 1];
      this.getTrendsStatusAndScore();
    }
  }

  changeChart(type: string, title: string, showResponse: boolean) {
    const lang = this.language.toLowerCase() === 'en_us' ? 'EN':'DK';
    switch(title) {
      case 'Satisfaction':
        title = lang === 'EN' ? 'Satisfaction':'Tilfredshed';
        break;
      case 'Stress Free':
        title = lang === 'EN' ? 'Stress Free':'Stressfri';
        break;
    }
    this.graphType = {type: type, title: title, showResponse: showResponse}
    // @ts-ignore
    this.chartOption.series[0].name = title;
    // @ts-ignore
    this.chartOption.series[1].name = lang === 'EN' ? 'Response Rate':'Svarprocent';
    // @ts-ignore
    this.chartOption.series[0].data = [];
    // @ts-ignore
    this.chartOption.series[1].data = [];
    // @ts-ignore
    this.chartOption.xAxis.data = [];
    this.graph.forEach((el: any) => {
      // @ts-ignore
      this.chartOption.xAxis.data.push(formatDate(el.created.date, 'dd MMM yyyy', 'en-US').slice(0, 6) + '');

      // @ts-ignore
      this.chartOption.series[0].data.push({value: el[type], name: title});
      if (showResponse) {
        // @ts-ignore
        this.chartOption.series[1].data.push({value: el.responseRate, name: lang === 'EN' ? 'Response Rate':'Svarprocent'});
      }
    })
    this.showChart = !this.showChart;
  }

  getWorkspaceMembers() {
    this.api.getWorkspaceUsersStatistic().subscribe(
      res => {
        this.members.total = res.totalMembers;
        this.members.active = res.activeMembers;
        this.members.pending = res.inactiveMembers;
        // @ts-ignore
        this.optionPie.series[0].data[0].value = this.members.pending;
        // @ts-ignore
        this.optionPie.series[0].data[1].value = this.members.active;
        this.showPie = !this.showPie;
      }
    )
  }

  teamSort(type: string) {
    Block.circle('#teamsRanking', {
      svgColor: 'rgba(0,189,130)'
    });
    // this.startDate, 'dd-MM-yyyy', 'en-US'), formatDate(this.endDate, 'dd-MM-yyyy', 'en-US')
    this.teamSortType = type;
    let endDate;
    if(this.teamScoreSelector !== 0) {
      let now = new Date();
      endDate = formatDate(new Date(now.setDate(now.getDate() - this.teamScoreSelector)), 'dd-MM-yyyy', 'en-US');
    } else {
      endDate = formatDate(new Date(), 'dd-MM-yyyy', 'en-US');
    }
    
    this.api.getTeamsRanking(type, endDate).subscribe(
      res => {
        this.totalTeams = res.totalTeams;
        // this.workspaceTeams = res;
        if (res.botTeam !== null) {
          let teams = res.topTeams.concat(res.botTeam)
          // this.workspaceTeams.sort((a: any, b: any) => (a.teamReport.responseRate > b.teamReport.responseRate ? -1 : 1));
          teams.forEach((el: any) => {
            switch (type) {
              case  'score':
                el.reporting = el.teamReport.teamSuccessteamScore
                break;
              case  'responseRate':
                el.reporting = el.teamReport.responseRate
                break
              case  'LeadershipScore':
                el.reporting = el.teamReport.LeadershipScore
                break
              case  'TeamScore':
                el.reporting = el.teamReport.TeamScore
                break
            }
          })
          this.workspaceTeams = teams;
        }
        Block.remove('#teamsRanking');
      }, err => {
        console.log(err)
      })
  }

  private getWorkspaceAlters() {
    this.api.getWorkspaceAlerts().subscribe((res: any) => {
      this.workspaceAlerts = res._embedded["workspace-alerts"];
        if (this.workspaceAlerts.length) {
          if (this.workspaceAlerts.length > 1) {
            this.gotMultipleAlerts = true;
          }
          this.gotAlert = true;
        }
      }
    )
  }

  goToWorkspaceMembers() {
    this.data.settingsWorkspaceMembers = true;
    this.router.navigate(['/settings/workspace']);
  }

  getStatementsSelector(value: any) {
    this.teamScoreSelector = value.value;
    this.teamSort(this.teamSortType);
  }
}
